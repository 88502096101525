import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonConfigurationService } from '@ngxhq/config';
import { ThemeHqCommonUiSupportModule, ThemeHqCustomComponentModule, ThemeHqPrimeNgA11yModule } from '@ngxhq/theme-ngxhq';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { HQFormLayoutModule } from './components/layout/hq-form-layout-module';
import { InfoCardComponent } from './components/info-card/info-card.component';
import { AppConfiguration } from './config/app-config.model';
import { WaitingComponent } from './components/waiting/waiting.component';
import { CanDeactivateGuard } from './guards/can-deactivate.guard';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { sharedPipes } from './pipes';
import { SharedPrimeNgModule } from './shared-primeng.module';
import { PhotoContainerComponent } from './components/photo-container/photo-container.component';
import { GpsModule } from './gps/gps.module';
import { PhotosModule } from './photos/photos.module';
import { MainTableMobileDialogComponent } from './components/main-table-mobile-dialog/main-table-mobile-dialog.component';


@NgModule({
    declarations: [
        LoadingSpinnerComponent,
        WaitingComponent,
        InfoCardComponent,
        PhotoContainerComponent,
        MainTableMobileDialogComponent,
        ...sharedPipes,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ThemeHqCommonUiSupportModule,
        ThemeHqCustomComponentModule,
        ThemeHqPrimeNgA11yModule,
        HQFormLayoutModule,
        SharedPrimeNgModule,
        ProgressSpinnerModule,
        GpsModule,
        PhotosModule,
    ],
    exports: [
        CommonModule,
        ThemeHqCommonUiSupportModule,
        ThemeHqCustomComponentModule,
        ThemeHqPrimeNgA11yModule,
        FormsModule,
        ReactiveFormsModule,
        SharedPrimeNgModule,
        LoadingSpinnerComponent,
        HQFormLayoutModule,
        WaitingComponent,
        InfoCardComponent,
        PhotoContainerComponent,
        MainTableMobileDialogComponent,
        ...sharedPipes,
        GpsModule,
        PhotosModule
    ],
    providers: [
        CanDeactivateGuard,
        {
            provide: AppConfiguration,
            useFactory: (commonConfigurationService: CommonConfigurationService<AppConfiguration>) => commonConfigurationService.getConfiguration(AppConfiguration),
            deps: [CommonConfigurationService]
        }
    ],
})
export class SharedModule {
}
