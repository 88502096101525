/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MessageService } from 'primeng/api';
import { asyncScheduler, interval, of, throwError, timer } from 'rxjs';
import { catchError, map, mergeMap, skipWhile, startWith, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import { ProjetsService } from '../services/projets.service';
import * as ProjetsActions from './projets.actions';
import { Store } from '@ngrx/store';
import { State } from '../../../state/app.state';
import { selectCanOpenProjetAuditList, selectCanOpenProjetsList } from '../../../state/shared/shared.selectors';
import { /*DialogService,*/ DynamicDialogRef } from 'primeng/dynamicdialog';

@Injectable()
export class ProjetsEffects {

    loadProjetsList$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ProjetsActions.loadProjetsList),
            withLatestFrom(this.store.select(selectCanOpenProjetsList)),
            mergeMap(([action, canOpenProjetsList]) => {
                if (!canOpenProjetsList) {
                    return of(null);
                } else {
                    const props: any = { pageSize: 5000 };
                    if (action.inclureProjetAudit) {
                        props.inclureProjetAudit = action.inclureProjetAudit;
                    }
                    return this.projetsService.getProjets(props)
                        .pipe(
                            map(projetsList => ProjetsActions.loadProjetsListSuccess({ projetsList: projetsList })),
                            catchError((error: any) => {
                                if (error.status !== 404) {
                                    this.messageService.add(
                                        {
                                            severity: 'error',
                                            summary: `Liste des projets`,
                                            detail: `Une erreur est survenue lors de la récupération de la liste des projets.`
                                        }
                                    );
                                }

                                return of(ProjetsActions.loadProjetsListFailure({ error }));
                            })
                        );
                }
            })
        );
    });

    loadValidationProjetById$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ProjetsActions.loadValidationProjetById),
            mergeMap(action => {
                return this.projetsService.getProjetInspection({ id: action.projetId })
                    .pipe(
                        map(projet => ProjetsActions.loadValidationProjetByIdSuccess({ projet })),
                        catchError((error: any) => {
                            if (error.status !== 404) {
                                this.messageService.add(
                                    {
                                        severity: 'error',
                                        summary: `Projets`,
                                        detail: `Une erreur est survenue lors de la récupération du projet.`
                                    }
                                );
                            }
                            return of(ProjetsActions.loadValidationProjetByIdFailure({ error, projetId: action.projetId }));
                        })
                    );
            }));
    });

    loadProjetInspectionHistory$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ProjetsActions.loadProjetInspectionHistory),
            mergeMap(action =>
                this.projetsService.getProjetInspectionHistory(action.projetInspection.id)
                    .pipe(
                        map(projetHistory => ProjetsActions.loadProjetInspectionHistorySuccess({
                            projetInspectionHistory: projetHistory, nomProjet: action.projetInspection.nom
                        })),
                        catchError((error: any) => {
                            if (error.status !== 404) {
                                this.messageService.add(
                                    {
                                        severity: 'error',
                                        summary: `Extraction historique`,
                                        detail: `Une erreur est survenue lors de l'extraction de l'historique.`,
                                    }
                                );
                            }

                            return of(ProjetsActions.loadProjetInspectionHistoryFailure({ error }));
                        })
                    )));
    });

    startCreateProjetInspection$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ProjetsActions.startCreateProjetInspection),
            mergeMap(action => this.projetsService.createProjetInspection(action.projetInspection)
                .pipe(
                    map(createdProjetInspection => ProjetsActions.startCreateProjetInspectionSuccess({ createdProjetInspection })),
                    catchError((error: any) => {
                        if (error.status !== 404) {
                            this.messageService.add(
                                {
                                    severity: 'error',
                                    summary: `Création de projet`,
                                    detail: `Une erreur est survenue lors de la création du projet.`,
                                }
                            );
                        }

                        return of(ProjetsActions.startCreateProjetInspectionFailure({ error }));
                    })
                )));
    });

    checkCreateProjetInspectionCompleted$ = createEffect(() => ({ scheduler = asyncScheduler } = {}) => {
        return this.actions$.pipe(
            ofType(ProjetsActions.startCreateProjetInspectionSuccess),
            // Timeout 15 min.
            mergeMap(({ createdProjetInspection }) => timer(900000, scheduler).pipe(
                switchMap(() => throwError(() => ({ error: { erreurs: [`Le délais d'attente maximal est dépassé.`] } }))),
                startWith(null),
                switchMap(() => interval(5000, scheduler)),
                mergeMap(() => {
                    return this.projetsService.getCreateProjetInspectionStatus(createdProjetInspection.projetDto.id);
                }),
                skipWhile(status => {
                    return status === createdProjetInspection.checksum;
                }),
                switchMap(() => {
                    return this.projetsService.getProjetInspection({ id: createdProjetInspection.projetDto.id });
                }),
                map(projetInspection => {
                    return ProjetsActions.createProjetInspectionSuccess({ createProjetInspection: projetInspection });
                })
            )),
            catchError((error: any) => {
                if (error.status !== 404) {
                    this.messageService.add(
                        {
                            severity: 'error',
                            summary: `Création de projet`,
                            detail: `Une erreur est survenue lors de la création du projet.`,
                        }
                    );
                }

                return of(ProjetsActions.createProjetInspectionFailure({ error }));
            }),
            takeUntil(this.actions$.pipe(ofType(ProjetsActions.createProjetInspectionSuccess)))
        );
    });

    assignProjetInspection$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ProjetsActions.assignProjetInspection),
            mergeMap(action =>
                this.projetsService.patchProjetInspection({ id: action.projetId, body: action.assignData })
                    .pipe(
                        map(projetInspection => ProjetsActions.assignProjetInspectionSuccess({ projetInspection })),
                        catchError((error: any) => {
                            if (error.status !== 404) {
                                this.messageService.add(
                                    {
                                        severity: 'error',
                                        summary: `Assigner un projet`,
                                        detail: `Une erreur est survenue lors de l'assignation du projet.`
                                    }
                                );
                            }

                            return of(ProjetsActions.assignProjetInspectionFailure({ error }));
                        })
                    )));
    });

    validateProjetInspection$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ProjetsActions.validateProjetInspection),
            mergeMap(action =>
                this.projetsService.patchProjetInspection({ id: action.projetInspectionId, body: action.validateData })
                    .pipe(
                        map(projetInspection => ProjetsActions.validateProjetInspectionSuccess({ projetInspection })),
                        catchError((error: any) => {
                            if (error.status === 409) {
                                this.messageService.add(
                                    {
                                        severity: 'error',
                                        summary: `Valider un projet`,
                                        detail: `La validation du projet a échoué.
                                        Pour plus de détails veuillez consulter le rapport d'erreur de validation `
                                    }
                                );

                                return of(
                                    ProjetsActions.loadValidationProjetById({ projetId: action.projetInspectionId }),
                                    ProjetsActions.validateProjetInspectionFailure({ error })
                                );
                            } else {
                                if (error.status !== 404 && error.status !== 409) {
                                    this.messageService.add(
                                        {
                                            severity: 'error',
                                            summary: `Valider un projet`,
                                            detail: `Une erreur est survenue lors de la validation du projet.`
                                        }
                                    );
                                }

                                return of(ProjetsActions.validateProjetInspectionFailure({ error }));
                            }
                        })
                    )));
    });

    cancelProjetInspection$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ProjetsActions.cancelProjetInspection),
            mergeMap(action =>
                this.projetsService.patchProjetInspection({ id: action.projetInspectionId, body: action.cancelData })
                    .pipe(
                        map(projetInspection => ProjetsActions.cancelProjetInspectionSuccess({ projetInspection })),
                        catchError((error: any) => {
                            if (error.status !== 404) {
                                this.messageService.add(
                                    {
                                        severity: 'error',
                                        summary: `Annuler un projet`,
                                        detail: `Une erreur est survenue lors de l'annulation du projet.`
                                    }
                                );
                            }

                            return of(ProjetsActions.cancelProjetInspectionFailure({ error }));
                        })
                    )));
    });

    deleteProjetInspection$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ProjetsActions.deleteProjetInspection),
            mergeMap(action =>
                this.projetsService.deleteProjetInspection(action.projetInspectionId)
                    .pipe(
                        map(() => ProjetsActions.deleteProjetInspectionSuccess({ projetInspectionId: action.projetInspectionId })),
                        catchError((error: any) => {
                            if (error.status !== 404) {
                                this.messageService.add(
                                    {
                                        severity: 'error',
                                        summary: `Annuler un projet`,
                                        detail: `Une erreur est survenue lors de la suppression du projet.`
                                    }
                                );
                            }

                            return of(ProjetsActions.deleteProjetInspectionFailure({ error }));
                        })
                    )));
    });

    approveProjetInspection$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ProjetsActions.approveProjetInspection),
            mergeMap(action =>
                this.projetsService.patchProjetInspection({ id: action.projetInspectionId, body: action.approveData })
                    .pipe(
                        map(projetInspection => ProjetsActions.approveProjetInspectionSuccess({ projetInspection })),
                        catchError((error: any) => {
                            if (error.status !== 404) {
                                this.messageService.add(
                                    {
                                        severity: 'error',
                                        summary: `Approuver un projet`,
                                        detail: `Une erreur est survenue lors de l'approbation du projet.`
                                    }
                                );
                            }

                            return of(ProjetsActions.approveProjetInspectionFailure({ error }));
                        })
                    )));
    });

    rejectProjetInspection$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ProjetsActions.rejectProjetInspection),
            mergeMap(action =>
                this.projetsService.patchProjetInspection({ id: action.projetInspectionId, body: action.rejectData })
                    .pipe(
                        map(projetInspection => ProjetsActions.rejectProjetInspectionSuccess({ projetInspection })),
                        catchError((error: any) => {
                            if (error.status !== 404) {
                                this.messageService.add(
                                    {
                                        severity: 'error',
                                        summary: `Rejeter un projet`,
                                        detail: `Une erreur est survenue lors du rejet du projet.`
                                    }
                                );
                            }

                            return of(ProjetsActions.rejectProjetInspectionFailure({ error }));
                        })
                    )));
    });

    updateProjetInspection$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ProjetsActions.updateProjetInspection),
            mergeMap(action =>
                this.projetsService.updateProjetInspection({ id: action.projetInspectionId, body: action.updateData })
                    .pipe(
                        map(projetInspection => ProjetsActions.updateProjetInspectionSuccess({ projetInspection })),
                        catchError((error: any) => {
                            if (error.status !== 404) {
                                this.messageService.add(
                                    {
                                        severity: 'error',
                                        summary: `Modifier un projet`,
                                        detail: `Une erreur est survenue lors de la modification du projet.`
                                    }
                                );
                            }

                            return of(ProjetsActions.updateProjetInspectionFailure({ error }));
                        })
                    )));
    });

    createAvisSap$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ProjetsActions.createAvisSap),
            mergeMap(action =>
                this.projetsService.createAvisSap(action.projetId)
                    .pipe(
                        map((createAvisSap) => ProjetsActions.createAvisSapSuccess({ createAvisSap })),
                        catchError((error: any) => {
                            if (error.status !== 404) {
                                this.messageService.add(
                                    {
                                        severity: 'error',
                                        summary: `Création d'avis`,
                                        detail: `Une erreur est survenue lors de la création d'avis.`
                                    }
                                );
                            }

                            return of(ProjetsActions.createAvisSapFailure({ error }));
                        })
                    )));
    });

    ///////////////////////////////////////////////////
    //          Action pour la partie audit          //
    ///////////////////////////////////////////////////

    loadProjetAuditList$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ProjetsActions.loadProjetAuditList),
            withLatestFrom(this.store.select(selectCanOpenProjetAuditList)),
            mergeMap(([_, canOpenProjetAuditList]) => {
                if (!canOpenProjetAuditList) {
                    return of(null);
                } else {
                    return this.projetsService.getProjetsAudit({ pageSize: 5000 }).pipe(
                        map((projetAuditList) => ProjetsActions.loadProjetAuditListSuccess({ projetAuditList })),
                        catchError((error: any) => {
                            if (error.status !== 404) {
                                this.messageService.add(
                                    {
                                        severity: 'error',
                                        summary: `Liste des projets d'audit`,
                                        detail: `Une erreur est survenue lors de la récupération de la liste des projets d'audit.`
                                    }
                                );
                            }

                            return of(ProjetsActions.loadProjetAuditListFailure({ error }));
                        })
                    );
                }
            })
        );
    });

    approveProjetAudit$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ProjetsActions.approveProjetAudit),
            mergeMap(action =>
                this.projetsService.updateProjetAudit({ id: action.projetAuditId, body: action.approveData })
                    .pipe(
                        map(projetAudit => ProjetsActions.approveProjetAuditSuccess({ projetAudit })),
                        catchError((error: any) => {
                            if (error.status !== 404) {
                                this.messageService.add(
                                    {
                                        severity: 'error',
                                        summary: `Approbation d'un projet d'audit`,
                                        detail: `Une erreur est survenue lors de l'approbation du projet d'audit.`
                                    }
                                );
                            }

                            return of(ProjetsActions.approveProjetAuditFailure({ error }));
                        })
                    )));
    });

    assignProjetAudit$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ProjetsActions.assignProjetAudit),
            mergeMap(action =>
                this.projetsService.updateProjetAudit({ id: action.projetAuditId, body: action.assignData })
                    .pipe(
                        map(projetAudit => ProjetsActions.assignProjetAuditSuccess({ projetAudit })),
                        catchError((error: any) => {
                            if (error.status !== 404) {
                                this.messageService.add(
                                    {
                                        severity: 'error',
                                        summary: `Assignation d'un projet d'audit`,
                                        detail: `Une erreur est survenue lors de l'assignation du projet d'audit.`
                                    }
                                );
                            }

                            return of(ProjetsActions.assignProjetAuditFailure({ error }));
                        })
                    )));
    });

    cancelProjetAudit$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ProjetsActions.cancelProjetAudit),
            mergeMap(action =>
                this.projetsService.updateProjetAudit({ id: action.projetAuditId, body: action.cancelData })
                    .pipe(
                        map(projetAudit => ProjetsActions.cancelProjetAuditSuccess({ projetAudit })),
                        catchError((error: any) => {
                            if (error.status !== 404) {
                                this.messageService.add(
                                    {
                                        severity: 'error',
                                        summary: `Annulation d'un projet d'audit`,
                                        detail: `Une erreur est survenue lors de l'annulation du projet d'audit.`
                                    }
                                );
                            }

                            return of(ProjetsActions.cancelProjetAuditFailure({ error }));
                        })
                    )));
    });

    rejectProjetAudit$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ProjetsActions.rejectProjetAudit),
            mergeMap(action =>
                this.projetsService.updateProjetAudit({ id: action.projetAuditId, body: action.rejectData })
                    .pipe(
                        map(projetAudit => ProjetsActions.rejectProjetAuditSuccess({ projetAudit })),
                        catchError((error: any) => {
                            if (error.status !== 404) {
                                this.messageService.add(
                                    {
                                        severity: 'error',
                                        summary: `Rejeter un projet d'audit`,
                                        detail: `Une erreur est survenue lors du rejet du projet d'audit.`
                                    }
                                );
                            }

                            return of(ProjetsActions.rejectProjetAuditFailure({ error }));
                        })
                    )));
    });

    // TODO : Besoin du nom de projet comme inspection ?
    loadProjetAuditHistory$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ProjetsActions.loadProjetAuditHistory),
            mergeMap(action =>
                this.projetsService.getProjetAuditHistory({}, action.projetAuditId)
                    .pipe(
                        map(projetAuditHistory => ProjetsActions.loadProjetAuditHistorySuccess({ projetAuditHistory })),
                        catchError((error: any) => {
                            if (error.status !== 404) {
                                this.messageService.add(
                                    {
                                        severity: 'error',
                                        summary: `Extraction historique`,
                                        detail: `Une erreur est survenue lors de l'extraction de l'historique.`,
                                    }
                                );
                            }

                            return of(ProjetsActions.loadProjetAuditHistoryFailure({ error }));
                        })
                    )));
    });

    startCreateProjetAudit$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ProjetsActions.startCreateProjetAudit),
            mergeMap(action => this.projetsService.createProjetAudit({ id: action.projetId, body: action.projetAudit })
                .pipe(
                    map(createdProjetAudit => ProjetsActions.startCreateProjetAuditSuccess({ createdProjetAudit })),
                    catchError((error: any) => {
                        if (error.status !== 404) {
                            this.messageService.add(
                                {
                                    severity: 'error',
                                    summary: `Création de projet d'audit`,
                                    detail: `Une erreur est survenue lors de la création du projet d'audit.`,
                                }
                            );
                        }

                        return of(ProjetsActions.startCreateProjetAuditFailure({ error }));
                    })
                )));
    });

    checkCreateProjetAuditCompleted$ = createEffect(() => ({ scheduler = asyncScheduler } = {}) => {
        return this.actions$.pipe(
            ofType(ProjetsActions.startCreateProjetAuditSuccess),
            // Timeout 15 min.
            mergeMap(({ createdProjetAudit }) => timer(900000, scheduler).pipe(
                switchMap(() => throwError(() => ({ error: { erreurs: [`Le délais d'attente maximal est dépassé.`] } }))),
                startWith(null),
                switchMap(() => interval(5000, scheduler)),
                mergeMap(() => {
                    return this.projetsService.getCreateProjetAuditStatus(createdProjetAudit.projetAudit.id);
                }),
                skipWhile(status => {
                    return status === createdProjetAudit.checksum;
                }),
                switchMap(() => {
                    return this.projetsService.getProjetAudit(createdProjetAudit.projetAudit.id);
                }),
                map(createProjetAudit => {
                    return ProjetsActions.createProjetAuditSuccess({ createProjetAudit });
                })
            )),
            catchError((error: any) => {
                if (error.status !== 404) {
                    this.messageService.add(
                        {
                            severity: 'error',
                            summary: `Création de projet d'audit`,
                            detail: `Une erreur est survenue lors de la création du projet d'audit.`,
                        }
                    );
                }

                return of(ProjetsActions.createProjetAuditFailure({ error }));
            }),
            takeUntil(this.actions$.pipe(ofType(ProjetsActions.createProjetAuditSuccess)))
        );
    });

    constructor(
        private actions$: Actions,
        private store: Store<State>,
        private projetsService: ProjetsService,
        private messageService: MessageService,
        public ref: DynamicDialogRef,
        // private readonly dialogService: DialogService,
    ) { }


}
