import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseComponent } from '../../shared/components/abstract-base-component';
import { State } from './state/audit.state';
import * as AuditActions from './state/audit.actions';
import { getProjetAuditList, getProjetAuditListLoading } from './state/audit.selectors';
import { projetAuditListMobileDialogColumn } from './models/projet-audit-list-dialog.column';
import { selectCanCompleteProjetAudit, selectIsAppOnline } from '../../state/shared/shared.selectors';
import { takeUntil } from 'rxjs';

@Component({
    selector: 'app-audit',
    templateUrl: './audit.component.html',
    styleUrls: ['./audit.component.scss']
})
export class AuditComponent extends BaseComponent implements OnInit, OnDestroy {

    public projetAuditList$ = this.store.select(getProjetAuditList);
    public projetAuditListLoading$ = this.store.select(getProjetAuditListLoading);

    mobileColumns = projetAuditListMobileDialogColumn;

    public isAppOffline = true;
    public canCompleteProjetAudit = false;

    constructor(private store: Store<State>
    ) {
        super();
    }

    ngOnInit(): void {
        this.store.dispatch(AuditActions.loadProjetAuditList());
        this.subscribeToIsAppOffline();
        this.subscribeToCanCompleteProjetAudit();
    }

    private subscribeToIsAppOffline() {
        this.store.select(selectIsAppOnline).pipe(
            takeUntil(this.destroyed)
        ).subscribe(_isAppOnline => {
            this.isAppOffline = !_isAppOnline;
        });
    }

    private subscribeToCanCompleteProjetAudit() {
        this.store.select(selectCanCompleteProjetAudit).pipe(
            takeUntil(this.destroyed)
        ).subscribe(_canCompleteProjetAudit => {
            this.canCompleteProjetAudit = _canCompleteProjetAudit;
        });
    }
}
