import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SharedModule } from '../../shared/shared.module';
import { AuditEffects } from './state/audit.effects';
import { auditReducer } from './state/audit.reducer';
import { AuditComponent } from './audit.component';
import { ProjetAuditListMobileDialogComponent } from './components/projet-audit-list-mobile-dialog/projet-audit-list-mobile-dialog.component';
import { CompleteProjetAuditDialogComponent } from './components/complete-projet-audit-dialog/complete-projet-audit-dialog.component';
import { PointAuditNonConformeDialogComponent } from './components/point-audit-non-conforme-dialog/point-audit-non-conforme-dialog.component';
import { AnomalieAuditNonConformeDialogComponent } from './components/anomalie-audit-non-conforme-dialog/anomalie-audit-non-conforme-dialog.component';

@NgModule({
    declarations: [
        AuditComponent,
        AnomalieAuditNonConformeDialogComponent,
        CompleteProjetAuditDialogComponent,
        PointAuditNonConformeDialogComponent,
        ProjetAuditListMobileDialogComponent
    ],
    imports: [
        SharedModule,
        StoreModule.forFeature('audit', auditReducer),
        EffectsModule.forFeature([AuditEffects])
    ],
    exports: [
        PointAuditNonConformeDialogComponent,
        AnomalieAuditNonConformeDialogComponent,
    ],
})
export class AuditModule { }
