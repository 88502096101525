import { Component, OnInit } from '@angular/core';
import { OpenIdService, OpenIdUserInfo, AuthentificationState } from '@ngxhq/security';
import { combineLatest, from, Observable } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { State } from '../../../state/app.state';
import { UiService } from 'src/app/services/ui.service';
import { UserViewModel } from '../../models/user-view-model';
import { environment } from '@app/environment';
import * as sharedActions from '../../../state/shared/shared.actions';
import {
    selectUtilisateurActiveRoles,
    selectUtilisateurIsMobile,
} from '../../../state/shared/shared.selectors';
import { NavigationListItem } from '../header-navigation-list/models/navigation-list-item.model';
import { HeaderLinkMenu } from '../menus/header-link-menu';
import { BaseComponent } from '../../../shared/components/abstract-base-component';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    providers: [
        HeaderLinkMenu
    ]
})
export class HeaderComponent extends BaseComponent implements OnInit {
    public shouldResetRole = false;
    public utilisateurIsMobile: boolean;
    public user: UserViewModel | undefined;
    public imagePath: string = './assets/images/';
    public isPoteauDetailsDialogOpened = false; // TODO: vérifier si encore utilie

    public selectUtilisateurActiveRoles$: Observable<string[]> = this.store.select(selectUtilisateurActiveRoles);
    public selectUtilisateurIsMobile$: Observable<boolean> = this.store.select(selectUtilisateurIsMobile);
    public menu: NavigationListItem[] = [];

    constructor(
        private openIdService: OpenIdService,
        private uiService: UiService,
        private store: Store<State>,
        private headerLinkMenu: HeaderLinkMenu
    ) {
        super();
        if (environment && environment.imagePath) {
            this.imagePath = environment.imagePath.assets;
        }
    }

    public openedMenu() {
        this.shouldResetRole = true;
    }

    public closedMenu() {
        this.shouldResetRole = false;
    }

    ngOnInit() {
        this.subscribeMenuRoles();
        this.openIdService.status
            .pipe(
                switchMap(x => from(this.mapStatusToViewModel(x))),
                takeUntil(this.destroyed),
            ).subscribe({
                next: (user: UserViewModel) => {
                    this.user = user;
                    if (user) {
                        this.store.dispatch(sharedActions.selectIdentiteUtilisateur());
                        this.uiService.getBackendVersion();
                    }
                },
                error: (err: any) => {
                    // eslint-disable-next-line no-restricted-syntax
                    console.log('HeaderComponent - openIdService status subsription in error.', err);
                    if ((typeof err !== 'object') || err.message.indexOf('Missing OpenID configuration') === -1) {
                        throw err;
                    }
                }
            });

        this.subscribeToIsPoteauDetailsDialogOpened();
        this.subscribeToSelectUtilisateurIsMobile();
    }

    private subscribeMenuRoles() {
        combineLatest([
            this.headerLinkMenu.menuIsReady$,
            this.selectUtilisateurIsMobile$
        ]).pipe(
            takeUntil(this.destroyed)
        ).subscribe(([menuIsReady, utilisateurIsMobile]) => {
            if (menuIsReady) {
                this.menu = utilisateurIsMobile ? this.headerLinkMenu.getMenuMobile() : this.headerLinkMenu.getMenuWeb();
            }
        });
    }

    private subscribeToIsPoteauDetailsDialogOpened() {
        this.uiService.isPoteauDetailsDialogOpened$
            .pipe(
                takeUntil(this.destroyed),
            ).subscribe(isOpen => {
                this.isPoteauDetailsDialogOpened = isOpen;
            });
    }

    private subscribeToSelectUtilisateurIsMobile() {
        this.selectUtilisateurIsMobile$
            .pipe(
                takeUntil(this.destroyed)
            ).subscribe(utilisateurIsMobile => {
                this.utilisateurIsMobile = utilisateurIsMobile;
            });
    }

    private async mapStatusToViewModel(x: AuthentificationState): Promise<UserViewModel | undefined> {
        return x.isAuthenticated
            ? this.mapToViewModel(await this.openIdService.getUserInfo())
            : undefined;
    }

    private mapToViewModel(userInfo: OpenIdUserInfo | undefined): UserViewModel | undefined {
        if (!userInfo) {
            return undefined;
        }
        return {
            fullname: `${userInfo.firstName} ${userInfo.lastName}`.trim(),
            initials: `${userInfo.firstName[0] || ''}${userInfo.lastName[0] || ''}`,
        };
    }
}
