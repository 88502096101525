import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, forkJoin, Observable, of, Subscription } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { Dialog } from 'primeng/dialog';
import { TypeReseau } from '../../../../projet/models/type-reseau.enum';
import { Severite } from '../../../../../enums/severite';
import { UiService } from '../../../../../services/ui.service';
import { territoireList } from '../../../../../enums/territoire.enum';
import { modeCreationList, ModeCreationProjet } from '../../../../../enums/modeCreationProjet';
import { MapService } from '../../../../../map/services/map.service';
import { FeatureCollection, Feature } from 'geojson';
import { geojsonToArcGIS } from '@terraformer/arcgis';
import { GeometryType, IQueryResponse } from '@esri/arcgis-rest-feature-layer';
import { EsriRequeteService } from '../../../../../services/esri-requete.service';
import { MapLayersSources } from '../../../../../map/models/map-layers-sources.enum';
import { GeoJSONSource, LngLatBoundsLike } from 'mapbox-gl';
import { polygonsToMultipolygon } from '../../../../../shared/utils';
import { select, Store } from '@ngrx/store';
import { Statut } from '../../../../../enums/statut';
import { bbox, BBox, buffer, dissolve, Polygon, truncate, simplify } from '@turf/turf';
import { UserInformation } from '../../../../../shared/models/user-informations.model'; // TODO: Changer UserInformation pour IdentiteUtilisateur
import { QueryLigne } from '../../../../../shared/models/query-ligne.model';
import { ProjetTypes, projetTypesListe } from '../../../../projet/models/projet-type.enum';
import { getTaxonomieGroupeOptions } from '../../../../../core/store/selectors/taxonomie.selectors';
import { UsageValue } from '../../../../../map/models/usage.enum';
import { ProprietaireValue } from '../../../../../map/models/proprietaire.enum';
import { TaxonomieGroupeValue } from '../../../../pilotage/models/taxonomie-groupe.enum';
import { CreateProjetMessage } from '../../../models/create-projet-error-message.enum';
import * as ProjetsActions from '../../../state/projets.actions';
import { CreateProjetDto } from '../../../../../core/api/client/models';
import {
    selectCanChooseFirmeOption,
    selectCanCreerProjetSansFirme,
    selectIdentiteUtilisateur,
    selectUtilisateurFirmeName,
    selectUtilisateurIsAdminExterne
} from '../../../../../state/shared/shared.selectors';
import shp from 'shpjs';
import { serviceUrl } from '../../../../../map/models/service-rest-url';

@Component({
    selector: 'app-create-projet-inspection-dialog',
    templateUrl: './create-projet-inspection-dialog.component.html',
    styleUrls: ['./create-projet-inspection-dialog.component.scss']
})
export class CreateProjetInspectionDialogComponent implements OnInit, OnDestroy {
    public newPlaceHolder: string = 'ABC-ABC-999_ABCDEF';
    public territoireList: string[] = territoireList;
    public fournisseurList$: Observable<SelectItem[]>;
    public projetTypesListe: Array<{ champ: string, valeur: string }> = projetTypesListe;
    public placeholder: string = 'ABC';
    public estPilote: boolean = false;
    public form: FormGroup;
    public modeCreationList: Array<{ mode: string, label: string, inactive: boolean }> = modeCreationList;
    public disabledBoutonVisualiserPoteaux: boolean = true;
    public disabledBoutonComptePoteaux: boolean = false;
    public totalPoteaux: number = 0;
    public nombreDePoteaux: number = 0;
    public nombrePoteauxExclusManuellement: number = 0;
    public nombrePoteauxExclus: number = 0;
    public firmeIsDeactivated = false;

    private _projectName: string = '';
    public set projectName(nom: string) {
        this._projectName = nom;
    }
    public get projectName(): string {
        if ((this.form.value).nom) {
            return (this.form.value).nom;
        } else {
            const formValue = this.form.value;
            return `${formValue.territory}_${String(formValue.poste).toUpperCase()}_${this.form.controls.ligne.value}_${this.form.controls.annee.value}`;
        }
    }

    public get listeExclu(): Array<number> {
        return this.mapService.listeExclu;
    }

    public minimize = false;
    private fournisseur$: Observable<SelectItem[]> = this.store.pipe(select(getTaxonomieGroupeOptions(TaxonomieGroupeValue.FIRME)));
    private selectCanChooseFirmeOption$: Observable<boolean> = this.store.pipe(select(selectCanChooseFirmeOption));
    private selectCanCreerProjetSansFirme$: Observable<boolean> = this.store.pipe(select(selectCanCreerProjetSansFirme));
    private identiteUtilisateur$: Observable<UserInformation> = this.store.pipe(select(selectIdentiteUtilisateur));
    private anneeCourante: number = new Date().getFullYear();
    private projet: CreateProjetDto;
    private featuresResultats: FeatureCollection[] = [];
    private featuresResultatsExclus: FeatureCollection;
    private ligneFeature: FeatureCollection = { type: 'FeatureCollection', features: [] };
    private subscriptions: Subscription[] = [];
    private ligneSubscriptions: Subscription[] = [];
    private firmeList: BehaviorSubject<SelectItem[]> = new BehaviorSubject([{ label: '', value: '' } as SelectItem]);
    private canChoose: boolean = false;
    private visualisePressed: boolean = false;
    private utilisateurFirmeName: string;
    private isAdminExterne = false;
    // PDL-3954 : On conserve. Ils vont encore changer d'idée. Ils ont demandé, la première fois, d'exclure les poteaux privé.
    //  là ils demande de remettre à nouveau les poteaux privé dans la structure.
    //  Donc, non "this.exclurePrive" n'est jamais modifié.
    private exclurePrive = false;

    private _showCreateProjet?: boolean = false;
    public get showCreateProjet() {
        return this._showCreateProjet || false;
    }

    @Input() public set showCreateProjet(value: boolean) {
        this._showCreateProjet = value;

        if (!value) {
            this.resetNombreDePoteaux();
        } else {
            this.initForm();
            this.initFirme();
        }

        this.setDynamicValidators();
    }

    @ViewChild('dialogue', { static: true }) dialogue: Dialog;

    constructor(
        private messageService: MessageService,
        private readonly fb: FormBuilder,
        private mapService: MapService,
        public readonly uiService: UiService,
        private esriRequeteService: EsriRequeteService,
        private readonly store: Store,
        private confirmationService: ConfirmationService,
    ) {
        this.subscribeToUtilisateur();
        this.clearFeaturesResultats();
    }

    public ngOnInit(): void {
        this.initForm();
        this.initFirme();

    }

    private subscribeToUtilisateur() {
        this.subscriptions.push(
            this.store.select(selectUtilisateurFirmeName).pipe(
                tap(utilisateurFirmeName => this.utilisateurFirmeName = utilisateurFirmeName)
            ).subscribe(),

            this.store.select(selectUtilisateurIsAdminExterne).pipe(
                tap(isAdminExterne => this.isAdminExterne = isAdminExterne)
            ).subscribe()
        );
    }

    private setDynamicValidators() {
        if (this.form) {
            if (this.canChoose) {
                this.form.controls.firme.setValidators(Validators.required);
                this.form.controls.firme.updateValueAndValidity();
            } else {
                this.form.controls.firme.setValidators(null);
                this.form.controls.firme.updateValueAndValidity();
            }

            if (this.firmeIsDeactivated) {
                this.form.controls.firme.enable();
            } else {
                this.form.controls.firme.disable();
            }

            this.form.updateValueAndValidity();

            this.initSubscribe();
        }
    }

    private setModeCreationValidators() {
        if (this.form) {
            if (this.form.controls.modeCreation.value === ModeCreationProjet.shapefile) {
                this.form.controls.ligne.removeValidators([Validators.required, Validators.maxLength(4), Validators.minLength(3)]);
                this.form.controls.poste.removeValidators(Validators.required);
                this.form.controls.ligne.updateValueAndValidity();
                this.form.controls.poste.updateValueAndValidity();
            } else {
                this.form.controls.ligne.setValidators([Validators.required, Validators.maxLength(4), Validators.minLength(3)]);
                this.form.controls.poste.setValidators(Validators.required);
                this.form.controls.ligne.updateValueAndValidity();
                this.form.controls.poste.updateValueAndValidity();
            }

            this.form.updateValueAndValidity();
        }
    }

    private initForm(): void {
        this.clearForm();

        if (this.isAdminExterne) {
            this.getNomFirme(this.utilisateurFirmeName);
        }

        this.subscriptions.push(
            this.form.statusChanges.pipe(
                tap((statut) => this.getProjetParametre(statut))
            ).subscribe()
        );
    }

    private initSubscribe() {
        this.subscriptions.push(
            this.mapService.updateFeature$.pipe(
                tap(() => this.visualise())
            ).subscribe(),

            this.uiService.numberOfExcludePole$.pipe(
                tap((nbExclus) => this.nombrePoteauxExclusManuellement = nbExclus)
            ).subscribe(),

            this.form.controls.modeCreation.valueChanges.pipe(
                tap(() => {
                    this.initLigneSubscribe();
                    this.setModeCreationValidators();
                    this.resetShapefile();
                })
            ).subscribe(),

            this.form.controls.exclureBT.valueChanges.pipe(
                tap(() => {
                    this.clearResult();
                }),
            ).subscribe(),
        );
    }

    private clearResult() {
        if (this.form.controls.modeCreation.value === ModeCreationProjet.ligne) {
            this.resetResult();
        } else if (this.form.controls.modeCreation.value === ModeCreationProjet.polygone) {
            this.clearResultPolygone();
        }
    }

    private initLigneSubscribe() {
        if (this.form.controls.modeCreation.value === ModeCreationProjet.ligne) {
            this.ligneSubscriptions.push(
                this.form.controls.projectType.valueChanges.pipe(tap(() => this.resetNombreDePoteaux())).subscribe(),
                this.form.controls.territory.valueChanges.pipe(tap(() => this.resetNombreDePoteaux())).subscribe(),
                this.form.controls.poste.valueChanges.pipe(tap(() => this.resetNombreDePoteaux())).subscribe(),
                this.form.controls.ligne.valueChanges.pipe(tap(() => this.resetNombreDePoteaux())).subscribe(),
            );
        } else {
            this.ligneSubscriptions.forEach(subscription => subscription.unsubscribe());
        }
    }

    private clearFeaturesResultats() {
        this.featuresResultats[0] = {
            type: 'FeatureCollection',
            features: [] as Feature[],
        } as FeatureCollection;
    }

    private clearFeaturesResultatsExclus() {
        this.featuresResultatsExclus = {
            type: 'FeatureCollection',
            features: [] as Feature[],
        } as FeatureCollection;

        (this.mapService.map.getSource(MapLayersSources.POTEAU_CREATION_EXCLUS) as GeoJSONSource).setData(this.featuresResultatsExclus as FeatureCollection);
    }

    private clearForm() {
        this.form = this.fb.group({
            modeCreation: [null, Validators.required],
            projectType: [null, Validators.required],
            territory: [null, Validators.required],
            poste: [null, Validators.required],
            ligne: [null, [Validators.required, Validators.maxLength(4), Validators.minLength(3)]],
            numeroOrdreDeTravail: [null, [Validators.maxLength(8), Validators.minLength(8)]],
            firme: [null],
            triphase: false,
            exclureBT: false,
            annee: [
                { value: this.anneeCourante, disabled: true },
                [
                    Validators.maxLength(4),
                    Validators.minLength(4),
                    Validators.min(this.anneeCourante),
                    Validators.max(this.anneeCourante + 1)
                ]
            ],
            buffer: [5, [Validators.min(0), Validators.max(100)]],
            nom: [null]
        });
    }

    private initFirme() {
        this.subscriptions.push(this.selectCanChooseFirmeOption$.pipe(tap((canChoose) => {
            this.canChoose = canChoose;
            this.subscriptions.push(this.selectCanCreerProjetSansFirme$.pipe(tap((canCreerSansFirme) => {
                this.subscriptions.push(this.identiteUtilisateur$.pipe(tap((identiteUtilisateur) => {
                    if (canChoose) {
                        this.fournisseurList$ = this.fournisseur$.pipe(
                            map((fournisseurs: SelectItem[]) => {
                                return fournisseurs.map((fournisseur: SelectItem) =>
                                    ({ label: fournisseur.label, value: fournisseur.value } as SelectItem));
                            })
                        );

                        this.form.controls.firme.setValue(null);
                        this.activeControle('firme');
                        this.firmeIsDeactivated = true;
                    } else if (canCreerSansFirme) {
                        this.getNomFirme(null, 'À déterminer');
                    } else {
                        if (identiteUtilisateur && identiteUtilisateur.firme) {
                            this.fournisseur$.pipe(
                                map((fournisseurs: SelectItem[]) => {
                                    return fournisseurs.map((fournisseur: SelectItem) =>
                                        ({ label: fournisseur.label, value: fournisseur.value } as SelectItem));
                                }),
                                tap((firmes: SelectItem[]) => {
                                    const foundFirme = firmes.filter((cie: SelectItem) => cie.value === identiteUtilisateur.firme);
                                    if (foundFirme && foundFirme.length === 1) {
                                        this.getNomFirme(foundFirme[0].value, foundFirme[0].label);
                                    }
                                })
                            ).subscribe();
                        }
                    }
                })).subscribe());
            })).subscribe());
        })).subscribe());
    }

    private getNomFirme(value: string | null, label: string | null = value): void {
        this.firmeList.next([{ label, value } as SelectItem]);
        this.fournisseurList$ = this.firmeList.asObservable();
        this.form.controls.firme.setValue(value);
        this.desactiveControle('firme');
        this.firmeIsDeactivated = false;
    }

    public close(): void {
        this.activeControle('ligne');
        this.clearForm();
        this.resetResult();
        this.uiService.openCreateProjetModal(false);
        this.minimize = false;
        this.ngOnDestroy();
    }

    public onIncrementAnnee() {
        this.form.controls.annee.setValue(this.anneeCourante + 1);
    }

    public onDecrementAnnee() {
        this.form.controls.annee.setValue(this.anneeCourante);
    }

    public updateNomProjet() {
        if (this.form.controls.modeCreation.value !== ModeCreationProjet.shapefile) {
            const territoire = this.form.controls.territory.value !== null ? this.form.controls.territory.value + '_' : '';
            const poste = this.form.controls.poste && this.form.controls.poste.value !== null ? String(this.form.controls.poste.value).toUpperCase() + '_' : '';
            const ligne = this.form.controls.ligne && this.form.controls.ligne.value !== null ? this.form.controls.ligne.value + '_' : '';
            const nom = territoire + poste + ligne + this.form.controls.annee.value;
            this.form.controls.nom.setValue(nom);
        }
    }

    public onSelectTriphase() {
        this.resetResult();
        if (this.form.value.triphase) {
            this.form.controls.exclureBT.setValue(false);
            this.desactiveControle('exclureBT');
        } else {
            this.activeControle('exclureBT');
        }
    }

    private activeControle(nomcontrol: string) {
        this.form.controls[nomcontrol].enable();
    }

    private desactiveControle(nomcontrol: string) {
        this.form.controls[nomcontrol].disable();
    }

    public dessinerPolygone(): void {
        if (this.projet && this.projet.nom) {
            this.mapService.draw.changeMode('draw_polygon');
            this.disabledBoutonVisualiserPoteaux = false;
        } else {
            this.disabledBoutonVisualiserPoteaux = true;
            this.disabledBoutonComptePoteaux = this.nombreDePoteaux < 1000;
            this.popupError(CreateProjetMessage.FILL_FIELD);
        }
    }

    public creerPolygone(): void {
        if (this.mapService.selectedPolygone) {
            this.mapService.draw.deleteAll();
            this.mapService.draw.add(this.mapService.selectedPolygone.geometry);

            this.projectName = this.mapService.selectedPolygone.id.toString();
            this.form.controls.nom.setValue(this._projectName);

            const arr: Feature[] = [];
            if (this.mapService.selectedPolygone) {
                arr.push(this.mapService.selectedPolygone as Feature);
            }

            this.projet.geometrie = polygonsToMultipolygon(arr as any);
            this.projet.nom = this.projectName;

            this.disabledBoutonVisualiserPoteaux = false;
        } else {
            this.disabledBoutonVisualiserPoteaux = true;
            this.popupError(CreateProjetMessage.FILL_FIELD);
        }
    }

    public importerFichier(event: Event): void {
        const file = (event.target as HTMLInputElement).files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                shp(e.target.result).then((geojson) => {
                    const geojsonData = Array.isArray(geojson) ? geojson[0] : geojson;

                    if (!this.mapService.map.getSource('shapefile')) {
                        this.mapService.map.addSource('shapefile', {
                            type: 'geojson',
                            data: geojsonData,
                            generateId: true
                        });

                        this.mapService.map.addLayer({
                            id: 'shapefile-layer',
                            type: 'fill',
                            source: 'shapefile',
                            paint: {
                                /* eslint-disable @typescript-eslint/naming-convention */
                                'fill-color': '#5757FF',
                                'fill-opacity': 0.5
                                /* eslint-enable @typescript-eslint/naming-convention */
                            }
                        });

                        this.mapService.map.addLayer({
                            id: 'shapefile-layer-etiquette',
                            type: 'symbol',
                            source: 'shapefile',
                            layout: {
                                /* eslint-disable @typescript-eslint/naming-convention */
                                'text-field': ['get', 'ETIQUETTE'],
                                'text-font': ['Arial Bold'],
                                'text-size': 12
                                /* eslint-enable @typescript-eslint/naming-convention */
                            },
                            paint: {
                                /* eslint-disable @typescript-eslint/naming-convention */
                                'text-color': '#1b1b52',
                                'text-halo-color': '#fff',
                                'text-halo-width': 1
                                /* eslint-enable @typescript-eslint/naming-convention */
                            }
                        });
                    }

                    const bboxCenter: [number, number] = [
                        (geojsonData.features[0].geometry.bbox[0] + geojsonData.features[0].geometry.bbox[2]) / 2,
                        (geojsonData.features[0].geometry.bbox[1] + geojsonData.features[0].geometry.bbox[3]) / 2
                    ];
                    this.mapService.map.setCenter(bboxCenter);
                    this.mapService.map.setZoom(12);
                })
                    .catch(() => {
                        this.popupError(CreateProjetMessage.IMPORT_FILE);
                    });
            };
            reader.readAsArrayBuffer(file);
        }
    }

    private popupError(message: string, life?: number) {
        const settings = {
            severity: Severite.info,
            closable: true,
            summary: 'Création de projet',
            detail: message
        };

        if (life) {
            settings['life'] = life;
        }

        this.messageService.add(settings);
    }

    private getSendErrorMessage() {
        if (this.disabledBoutonVisualiserPoteaux) {
            this.disabledBoutonComptePoteaux = this.nombreDePoteaux < 1000;
            if (this.form.value.modeCreation === ModeCreationProjet.ligne) {
                return CreateProjetMessage.SEARCH_LINE_FIRST;
            } else {
                return CreateProjetMessage.DRAW_POLYGONE;
            }
        } else {
            return CreateProjetMessage.VISUALISE;
        }
    }

    private getProjetParametre(statut: string) {
        if (statut === Statut.VALID) {
            const formValue = this.form.value;
            const type: ProjetTypes = formValue.projectType;
            const typeReseau: TypeReseau = formValue.triphase ? TypeReseau.triphase : formValue.exclureBT ? TypeReseau.basseTensionExclue : TypeReseau.tous;
            const modeCreationProjet: string = formValue.modeCreation;

            this.projet = {
                nom: formValue.nom,
                type,
                territoire: formValue.territory,
                firme: this.form.controls.firme.value,
                numeroOrdreDeTravail: formValue.numeroOrdreDeTravail,
                typeReseau,
                modeCreation: modeCreationProjet
            };
        } else {
            this.projet = {};
        }
    }

    private getPointExclusionAndExclusion(features: Feature[], poteauxBasseTensionExclue: boolean) {
        const pointInspection: Feature[] = [];
        const pointInspectionExclu: Feature[] = [];

        features.forEach((feature: Feature) => {
            // PDL-3954 : On conserve la variable "this.exclurePrive". Ils vont encore changer d'idée. Ils ont demandé, la première fois, d'exclure les poteaux privé.
            //  Là ils demandent de remettre à nouveau les poteaux privés dans la sélection des poteaux.
            //  Donc, non "this.exclurePrive" n'est jamais modifié.
            if (feature.properties?.Proprietaire, feature.properties?.Proprietaire === ProprietaireValue.prive && this.exclurePrive) {
                pointInspectionExclu.push(feature);
            } else if (poteauxBasseTensionExclue && feature.properties?.Usage && feature.properties.Usage === UsageValue.poteauBasseTension) {
                pointInspectionExclu.push(feature);
            } else {
                pointInspection.push(feature);
            }
        });

        return { pi: pointInspection, piExclu: pointInspectionExclu };
    }

    public async visualise(): Promise<void> {
        this.nombreDePoteaux = 0;
        this.clearFeaturesResultats();
        this.clearFeaturesResultatsExclus();

        const formValue = this.form.value;

        if (!this.visualisePressed) {
            if ((this.mapService.draw.getAll().features.length > 0 && this.projet.nom && this.mapService.draw.getMode() !== 'draw_polygon') ||
                (this.projet.geometrie)) {
                this.visualisePressed = true;
                const arr: Feature[] = this.mapService.draw.getAll().features;

                let nbReception = 0;
                let isIntersect = false;
                let isIntersectNations = false;

                for (const feature of arr) {
                    const geometry: any = geojsonToArcGIS(feature.geometry);
                    let geometryType: GeometryType = ('esriGeometry' + feature.geometry.type) as GeometryType;
                    if (feature.geometry.type === 'LineString') {
                        geometryType = 'esriGeometryPolyline';
                    }
                    const poteauxBasseTensionExclue: boolean = this.projet.typeReseau === TypeReseau.basseTensionExclue;

                    this.esriRequeteService.executeQueryspatial(geometry, geometryType).then((res: any) => {
                        nbReception++;
                        const piAndExclu = this.getPointExclusionAndExclusion(res['features'], poteauxBasseTensionExclue);
                        this.addFeaturePointInspectionToMap(piAndExclu.pi, nbReception, arr.length);
                        this.excludePi(piAndExclu.piExclu);
                    }, () => { });

                    await this.esriRequeteService.verifierIntersect(geometry, 'esriGeometryPolygon',
                        serviceUrl.RAINETTES, `hq_etiquette LIKE '%Rainette%'`).then((res: IQueryResponse) => {
                            if ((res as any)['features'].length) {
                                isIntersect = true;
                            }
                        }, () => { })
                        .catch(error => console.error(error));

                    await this.esriRequeteService.verifierIntersect(geometry, 'esriGeometryPolygon',
                        serviceUrl.NATIONS, `1=1`).then((res: IQueryResponse) => {
                            if ((res as any)['features'].length) {
                                isIntersectNations = true;
                            }
                        }, () => { })
                        .catch(error => console.error(error));
                };

                this.projet.geometrie = polygonsToMultipolygon(arr as any);
                this.projet.idPoteauxAExclure = this.listeExclu;
                if (formValue.modeCreation === ModeCreationProjet.ligne) {
                    this.projet.ligne = { nom: this.form.controls.poste.value };
                };

                if (isIntersect) {
                    this.messageService.add(
                        {
                            severity: Severite.avertissement,
                            closable: true,
                            summary: 'Environnement',
                            life: 10000,
                            detail: CreateProjetMessage.RAINETTE_FAUX_GRILLON
                        });
                };

                if (isIntersectNations) {
                    this.messageService.add(
                        {
                            severity: Severite.avertissement,
                            closable: true,
                            summary: 'Territoire autochtone',
                            life: 20000,
                            detail: CreateProjetMessage.NATIONS
                        });
                };
            } else {
                this.popupError(CreateProjetMessage.CREATE_PROJET_FIRST);
            }
        }
        this.disabledBoutonComptePoteaux = this.nombreDePoteaux < 1000;
    }

    /**************compte total Poteaux */

    public compterPoteaux(): void {
        this.clearFeaturesResultats();
        this.clearFeaturesResultatsExclus();

        const formValue = this.form.value;

        if (!this.visualisePressed) {
            if (this.mapService.draw.getAll().features.length > 0 && this.projet.nom && this.mapService.draw.getMode() !== 'draw_polygon') {
                this.visualisePressed = true;
                const arr: Feature[] = this.mapService.draw.getAll().features;
                const isIntersect = false;
                const isIntersectNations = false;

                const queryObservables: Observable<any>[] = arr.map((feature) => {
                    const geometry: any = geojsonToArcGIS(feature.geometry);
                    let geometryType: GeometryType = ('esriGeometry' + feature.geometry.type) as GeometryType;
                    if (feature.geometry.type === 'LineString') {
                        geometryType = 'esriGeometryPolyline';
                    }

                    return this.esriRequeteService.executeQueryCountOnly(geometry, geometryType).pipe(
                        map((res: any) => res.properties.count || 0),
                        catchError((error: any) => {
                            console.error('Erreur dans la requête spatial:', error);
                            return of(0);
                        })
                    );
                });

                // Combiner toutes les variables observables
                forkJoin(queryObservables).pipe(
                    map((counts: number[]) => counts.reduce((total, count) => total + count, 0))
                ).subscribe({
                    next: (totalCount: number) => {
                        this.totalPoteaux = totalCount;

                        this.projet.geometrie = polygonsToMultipolygon(arr as any);
                        this.projet.idPoteauxAExclure = this.listeExclu;

                        if (formValue.modeCreation === ModeCreationProjet.ligne) {
                            this.projet.ligne = { nom: this.form.controls.poste.value };
                        }

                        if (isIntersect) {
                            this.messageService.add({
                                severity: Severite.avertissement,
                                closable: true,
                                summary: 'Environnement',
                                life: 15000,
                                detail: CreateProjetMessage.RAINETTE_FAUX_GRILLON
                            });
                        }

                        if (isIntersectNations) {
                            this.messageService.add({
                                severity: Severite.avertissement,
                                closable: true,
                                summary: 'Territoire autochtone',
                                life: 15000,
                                detail: CreateProjetMessage.NATIONS
                            });
                        }
                    },
                    error: () => {
                        this.popupError(CreateProjetMessage.CREATE_PROJET_FIRST);
                    }
                });
            } else {
                this.popupError(CreateProjetMessage.CREATE_PROJET_FIRST);
            }
        }
    }

    private excludePi(features: any) {
        this.clearFeaturesResultatsExclus();
        this.featuresResultatsExclus.features = features as Feature[];
        (this.mapService.map.getSource(MapLayersSources.POTEAU_CREATION_EXCLUS) as GeoJSONSource).setData(this.featuresResultatsExclus as FeatureCollection);
    }

    private addFeaturePointInspectionToMap(res: any, nbReception: number, arrLength: number) {
        res.forEach((fea: Feature) => {
            this.featuresResultats[0].features.push(fea);
        });

        if (nbReception === arrLength) {
            this.nombreDePoteaux = this.featuresResultats[0].features.length;
            this.visualisePressed = false;
        }

        (this.mapService.map.getSource(MapLayersSources.POTEAU_HIGHLIGHTED) as GeoJSONSource).setData(this.featuresResultats[0] as FeatureCollection);
    }

    private clearResultPolygone() {
        this.nombreDePoteaux = 0;
        this.mapService.resetFeatureExclure();
        this.clearFeaturesResultats();

        const featuresreset: GeoJSON.FeatureCollection<GeoJSON.Geometry> = { features: [], type: 'FeatureCollection' };
        (this.mapService.map.getSource(MapLayersSources.POTEAU_HIGHLIGHTED) as GeoJSONSource).setData(featuresreset as FeatureCollection);
        (this.mapService.map.getSource(MapLayersSources.POTEAU_CREATION_EXCLUS) as GeoJSONSource).setData(featuresreset as FeatureCollection);
    }

    private resetNombreDePoteaux() {
        this.nombreDePoteaux = 0;
        this.disabledBoutonVisualiserPoteaux = true;
        this.disabledBoutonComptePoteaux = this.nombreDePoteaux < 1000;
    }

    private resetShapefile() {
        const formValue = this.form.value;
        if (formValue.modeCreation !== ModeCreationProjet.shapefile && this.mapService.map.getSource('shapefile')) {
            this.mapService.map.removeSource('shapefile');
            this.mapService.map.removeLayer('shapefile-layer');
            this.mapService.map.removeLayer('shapefile-layer-etiquette');
            this.clearFeaturesResultatsExclus();
            this.resetResult();
            this.visualisePressed = false;
            this.mapService.selectedPolygone = undefined;
        }
    }

    public resetResult(): void {
        this.resetNombreDePoteaux();
        this.mapService.draw.deleteAll();
        this.mapService.resetFeatureExclure();
        this.projet.geometrie = {};
        this.clearFeaturesResultats();

        this.disabledBoutonVisualiserPoteaux = true;
        this.disabledBoutonComptePoteaux = this.nombreDePoteaux < 1000;
        if (this.projet.ligne) {
            this.projet.ligne = undefined;
        }

        if (this.form.controls.modeCreation.value === ModeCreationProjet.shapefile) {
            this.form.controls.nom.setValue(undefined);
        } else {
            this.updateNomProjet();
        }

        const featuresreset: GeoJSON.FeatureCollection<GeoJSON.Geometry> = { features: [], type: 'FeatureCollection' };
        (this.mapService.map.getSource(MapLayersSources.POTEAU_HIGHLIGHTED) as GeoJSONSource).setData(featuresreset as FeatureCollection);
        (this.mapService.map.getSource(MapLayersSources.POLYGONE_PROJET) as GeoJSONSource).setData(featuresreset as FeatureCollection);
        (this.mapService.map.getSource(MapLayersSources.LIGNE_PROJET) as GeoJSONSource).setData(featuresreset as FeatureCollection);
        (this.mapService.map.getSource(MapLayersSources.POTEAU_CREATION_EXCLUS) as GeoJSONSource).setData(featuresreset as FeatureCollection);
    }

    private featuresResultatsContaintValue() {
        return this.featuresResultats
            && this.featuresResultats.length > 0
            && this.featuresResultats[0].features
            && this.featuresResultats[0].features.length > 0;
    }

    public save(): void {
        if (this.projectName && this.projet.geometrie && this.featuresResultatsContaintValue()) {
            this.store.dispatch(ProjetsActions.startCreateProjetInspection({ projetInspection: this.projet }));
            this.popupError(CreateProjetMessage.PROJET_X_SAVED.replace('{0}', this.projectName), 15000);
        } else {
            this.popupError(this.getSendErrorMessage());
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        this.ligneSubscriptions.forEach(subscription => subscription.unsubscribe());
    }

    public getLigneFeature(): void {
        this.resetResult();
        const nomLigne = this.form.controls.poste.value + ' ' + this.form.controls.ligne.value;
        const triphaseSeulement = this.projet.typeReseau === TypeReseau.triphase;
        const territoire = this.form.value.territory;
        const query: QueryLigne = { nomLigne: nomLigne, territoire: territoire };
        this.esriRequeteService.rechercheNumeroLigne(query, triphaseSeulement).then((ligneResults) => {
            this.ligneFeature = ligneResults as FeatureCollection;
            if (this.ligneFeature.features.length > 0) {
                const bounds: BBox = bbox(this.ligneFeature);
                (this.mapService.map.getSource(MapLayersSources.LIGNE_PROJET) as GeoJSONSource).setData(this.ligneFeature);
                const buffered: FeatureCollection = buffer(this.ligneFeature, this.form.controls.buffer.value, { units: 'meters', steps: 2, });
                const truncatedFeature: FeatureCollection = truncate(buffered as FeatureCollection<Polygon>, { precision: 10 });
                const dissolved: FeatureCollection = dissolve(truncatedFeature as FeatureCollection<Polygon>, { propertyName: 'HQ_OBJET', });
                this.mapService.map.fitBounds(bounds as LngLatBoundsLike, { padding: 3 });
                const geometrieSimplifier = simplify(dissolved as any, { tolerance: 0.00001, highQuality: true });
                this.mapService.draw.add(geometrieSimplifier as FeatureCollection);
                this.disabledBoutonVisualiserPoteaux = false;
            } else {
                this.disabledBoutonVisualiserPoteaux = true;
                this.disabledBoutonComptePoteaux = this.nombreDePoteaux < 1000;
                this.popupError(CreateProjetMessage.LINE_NO_RESULT);
            }
        }, () => { }
        );
    }

    public onChangeBuffer(): void {
        if (this.mapService.draw.getAll().features.length > 0) {
            this.confirmationService.confirm({
                message: 'Voulez-vous vraiment réinitialiser le buffer ?',
                key: 'resetBufferConfirm',
                accept: () => {
                    this.mapService.draw.deleteAll();
                    this.getLigneFeature();
                }
            });
        };
    }

    public onMinimiseDialogue(): void {
        const content: HTMLCollectionOf<Element> = this.dialogue!.container.getElementsByClassName('p-dialog-content');
        content[0].className = 'p-dialog-contentinvisible';
        this.minimize = true;
    }

    public onAgrandirDialogue(): void {
        const content: HTMLCollectionOf<Element> = this.dialogue!.container.getElementsByClassName('p-dialog-contentinvisible');
        content[0].className = 'p-dialog-content';
        this.minimize = false;
    }

    public onChangeMode(): void {
        this.resetResult();
    }
}
