
import { UserRole } from '../../../shared/models/user-roles.model';
import { UserInformation } from '../../../shared/models/user-informations.model';   // TODO: Changer UserInformation pour IdentiteUtilisateur
import { ProjetCompletDto } from '../../../core/api/client/models';
import { StatutProjetsInspection } from '../../projets/models/statut-projets-inspection.enum';

export function getAssigneProjetPermission(projet: ProjetCompletDto, currentUserRole: UserRole, currentIdentiteUtilisateur: UserInformation): boolean {
    const canAssigneProjetStatutList: string[] = [
        StatutProjetsInspection.aqAssigne,
        StatutProjetsInspection.aqEnCours,
        StatutProjetsInspection.aqCompletee,
        StatutProjetsInspection.inspectionAssigne,
        StatutProjetsInspection.inspectionEnCours,
        StatutProjetsInspection.inspectionCompletee,
        StatutProjetsInspection.nouveau
    ];

    switch (currentUserRole) {
        case UserRole.ADMIN_EXTERNE:
        case UserRole.ADMIN_TIC:
        case UserRole.ADMIN_GCSP:
        case UserRole.PILOTE:
            return canAssigneProjetStatutList.includes(projet.statut!)
                && projet.firme === currentIdentiteUtilisateur.firme;
        case UserRole.TECH_INGENIEURS_RESEAU:
            return canAssigneProjetStatutList.includes(projet.statut!)
                && projet.firme === currentIdentiteUtilisateur.firme
                && projet.creePar?.toLowerCase() === currentIdentiteUtilisateur.courriel!.toLowerCase();
        default:
            return false;
    }
}

export function getAnnuleProjetPermission(projet: ProjetCompletDto, currentUserRole: UserRole, currentIdentiteUtilisateur: UserInformation): boolean {
    const canAnnuleProjetStatutList: string[] = [
        StatutProjetsInspection.inspectionAssigne,
        StatutProjetsInspection.nouveau,
        StatutProjetsInspection.enCreation,
        StatutProjetsInspection.erreur
    ];

    switch (currentUserRole) {
        case UserRole.ADMIN_EXTERNE:
            return canAnnuleProjetStatutList.includes(projet.statut!)
                && projet.firme === currentIdentiteUtilisateur.firme;
        case UserRole.TECH_INGENIEURS_RESEAU:
            return canAnnuleProjetStatutList.includes(projet.statut!) &&
                projet.creePar?.toLowerCase() === currentIdentiteUtilisateur.courriel!.toLowerCase();
        case UserRole.ADMIN_TIC:
        case UserRole.PILOTE:
        case UserRole.ADMIN_GCSP:
            return canAnnuleProjetStatutList.includes(projet.statut!);
        default:
            return false;
    }
}

export function getModifierProjetPermission(projet: ProjetCompletDto, currentUserRole: UserRole, currentIdentiteUtilisateur: UserInformation): boolean {
    switch (currentUserRole) {
        case UserRole.ADMIN_EXTERNE:
            return projet.firme === currentIdentiteUtilisateur.firme;
        case UserRole.TECH_INGENIEURS_RESEAU:
            return projet.creePar?.toLowerCase() === currentIdentiteUtilisateur.courriel!.toLowerCase();
        case UserRole.ADMIN_TIC:
        case UserRole.PILOTE:
        case UserRole.ADMIN_GCSP:
            return true;
        default:
            return false;
    }
}

export function getValiderProjetPermission(projet: ProjetCompletDto, currentUserRole: UserRole, currentIdentiteUtilisateur: UserInformation): boolean {
    const canValiderProjetStatutList: string[] = [
        StatutProjetsInspection.inspectionCompletee,
        StatutProjetsInspection.aqCompletee,
        StatutProjetsInspection.correctionCompletee
    ];

    if (UserRole.ADMIN_EXTERNE.includes(currentUserRole)) {
        return canValiderProjetStatutList.includes(projet.statut!)
            && projet.firme === currentIdentiteUtilisateur.firme;
    } else {
        return canValiderProjetStatutList.includes(projet.statut!);
    }
}

export function getApprouveProjetPermission(projet: ProjetCompletDto): boolean {
    const canApprouveProjetStatutList: string[] = [StatutProjetsInspection.attenteApprobationHQ, StatutProjetsInspection.conforme, StatutProjetsInspection.audite];
    return canApprouveProjetStatutList.includes(projet.statut);
}

export function getRejeterProjetPermission(projet: ProjetCompletDto): boolean {
    const canRejeterProjetStatutList: string[] = [StatutProjetsInspection.attenteApprobationHQ, StatutProjetsInspection.audite];
    return canRejeterProjetStatutList.includes(projet.statut);
}

export function getEnvoyerAvisPermission(projet: ProjetCompletDto, currentUserRole: UserRole, currentIdentiteUtilisateur: UserInformation): boolean {
    const canEnvoyerAvisStatutList: string[] = [
        StatutProjetsInspection.inspectionAssigne,
        StatutProjetsInspection.inspectionEnCours,
        StatutProjetsInspection.inspectionCompletee,
        StatutProjetsInspection.aqAssigne,
        StatutProjetsInspection.aqEnCours,
        StatutProjetsInspection.aqCompletee
    ];
    if (UserRole.ADMIN_EXTERNE.includes(currentUserRole)) {
        return canEnvoyerAvisStatutList.includes(projet.statut!)
            && projet.firme === currentIdentiteUtilisateur.firme;
    } else {
        return projet.statut !== StatutProjetsInspection.approbationFinaleHQ;
    }
}

export function getCreateProjetAuditPermission(projet: ProjetCompletDto): boolean {
    return projet.statut === StatutProjetsInspection.attenteApprobationHQ;
}

export function getAssigneProjetAQPermission(projet: ProjetCompletDto): boolean {
    return projet.statut === StatutProjetsInspection.inspectionCompletee ||
        projet.statut === StatutProjetsInspection.aqAssigne ||
        projet.statut === StatutProjetsInspection.aqEnCours ||
        projet.statut === StatutProjetsInspection.aqCompletee;
}

export function getRapportCreationAvisPermission(projet: ProjetCompletDto): boolean {
    return (projet.resultatTransferAvis || []).length > 0;
}

export function getAssigneFirmeProjetPermission(projet: ProjetCompletDto): boolean {
    return projet.firme === undefined || projet.firme === '';
}

export function getAssigneProjetPourCorrectionPermission(projet: ProjetCompletDto): boolean {
    return projet.statut === StatutProjetsInspection.nonConforme ||
        projet.statut === StatutProjetsInspection.correctionAssignee ||
        projet.statut === StatutProjetsInspection.correctionEnCours ||
        projet.statut === StatutProjetsInspection.correctionCompletee;
}

export function getProjetHistoriquePermission(projet: ProjetCompletDto): boolean {
    return projet.statut !== StatutProjetsInspection.erreur;
}











