<p-contextMenu #menu [model]="menuItems" triggerEvent="click" [target]="actionsButton"></p-contextMenu>

<app-main-table-mobile-dialog [isLoading]="projetsAuditDownloadedLoading" [numberOfItems]="projetAuditList.length"
    [selectedProjet]="selectedProjetAudit" [name]="selectedProjetAudit?.nom"
    [statut]="statutProjetAuditValue[selectedProjetAudit?.statut]"
    [assignDate]="selectedProjetAudit?.assigneLe ? (selectedProjetAudit?.assigneLe | date: 'dd/MM/YYYY') : null"
    [quantity]="selectedProjetAudit?.nombreTotalDePoteaux"
    [propertyQuantityMessage]="'Nombre total de points d\'inspection à auditer:'">
    <ng-container table>
        <p-table *ngIf="!projetsAuditDownloadedLoading" [value]="projetAuditList" selectionMode="single"
            [(selection)]="selectedProjetAudit" dataKey="id" (onRowSelect)="onRowSelect(selectedProjetAudit, menu)"
            [scrollable]="true" scrollHeight="flex" (onRowUnselect)="onRowUnselect(menu)" responsiveLayout="scroll">
            <ng-template pTemplate="header">
                <tr>
                    <th scope="col" *ngFor="let col of columns" [style.width]="col.width">
                        {{col.header}}
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData">
                    <td *ngFor="let col of columns">
                        <ng-container [ngSwitch]="col.field">
                            <ng-container *ngSwitchCase="'statut'">
                                <span *ngIf="statutProjetAuditValue[rowData.statut] === statutProjetAuditValue.enCours">
                                    <i class="pi pi-check"></i>
                                </span>
                            </ng-container>
                            <ng-container *ngSwitchCase="'downloaded'">
                                <span *ngIf="isProjetAuditDownloaded(rowData)">
                                    <i class="pi pi-check"></i>
                                </span>
                            </ng-container>
                            <ng-container *ngSwitchDefault>
                                {{rowData[col.field]}}
                            </ng-container>
                        </ng-container>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </ng-container>
    <ng-container action>
        <button #actionsButton pButton pRipple icon="pi pi-ellipsis-v"
            class="p-button-rounded p-button-secondary actions-button"
            (click)="onActionsButtonClick($event, menu, selectedProjetAudit)"></button>
    </ng-container>
    <ng-container emptyMessage>Aucun projet d'audit activé ou téléchargé.</ng-container>
</app-main-table-mobile-dialog>
