<p-menu #menuExtract [popup]="true" [model]="extractMenuItems" appendTo="body"></p-menu>
<p-contextMenu #menu [model]="items" appendTo="body"></p-contextMenu>

<p-table #projetsTable [value]="projets" [columns]="selectedColumns" styleClass="p-datatable-striped"
    [resizableColumns]="true" columnResizeMode="expand" [scrollable]="true" [reorderableColumns]="true"
    [scrollable]="true" scrollHeight="flex" [globalFilterFields]="globalFilterFields" stateStorage="local"
    [stateKey]="localStorageTableKey" (onStateRestore)="onRestoreTableState(projetsTable)" [rowTrackBy]="trackRows"
    (onStateSave)="onSaveTableState(projetsTable)" (onFilter)="onFilter(projetsTable)">
    <ng-template pTemplate="caption">
        <div class="table__header">
            <div class="header__section">
                <div class="section__recherche">
                    <i class="fas fa-search search__icon"></i>
                    <input type="text" class="search__input local__input" pInputText size="30" placeholder="Rechercher"
                        aria-placeholder="Rechercher" [value]="$any(projetsTable.filters['global'])?.value"
                        [pTooltip]="projetTooltips.filtreGlobal" tooltipPosition="top"
                        (input)="globalFilter($any($event.target).value)">
                </div>
                <div class="section__filtres">
                    <button type="button" pButton pRipple icon="fa-solid fa-filter"
                        [pTooltip]="projetTooltips.resetFilter" tooltipPosition="top" (click)="clearTable(projetsTable)"
                        [label]="resetFiltersLabel" [disabled]="!filterIsApplied"></button>
                </div>
            </div>
            <div class="header__section">
                <div class="section__exports">
                    <div class="checkbox">
                        <div class="checkbox__item" [pTooltip]="projetTooltips.rowsOnly" tooltipPosition="top">
                            <p-checkbox [(ngModel)]="extractFilteredRowsOnly"
                                (onChange)="extractOnlyRowsToggled = $event.checked" [binary]="true"
                                inputId="rowsOnly"></p-checkbox>
                            <label for="rowsOnly">{{extraireRowsLabel}}</label>
                        </div>
                        <div class="checkbox__item" [pTooltip]="projetTooltips.columnsOnly" tooltipPosition="top">
                            <p-checkbox [(ngModel)]="extractFilteredColumnsOnly"
                                (onChange)="extractOnlyColumnsToggled = $event.checked" [binary]="true"
                                inputId="columnsOnly"></p-checkbox>
                            <label for="columnsOnly">{{extraireColumnsLabel}}</label>
                        </div>
                    </div>
                    <div class="export__button">
                        <button type="button" pButton pRipple icon="far fa-file-archive" pTooltip="Extraire les données"
                            (click)="menuExtract.toggle($event)" tooltipPosition="bottom" class="extraction"
                            [loading]="dataExtractionLoading | async"
                            [disabled]="!haveDataForExtraction || extractOnlyRowsToggled || extractOnlyColumnsToggled"></button>
                    </div>
                    <div class="export__button">
                        <button type="button" pButton pRipple icon="pi pi-file-o" [pTooltip]="projetTooltips.csv"
                            (click)="exportCsvFormat(projetsTable)" tooltipPosition="bottom"
                            [disabled]="!haveDataForExtraction"></button>
                    </div>
                    <div class="export__button">
                        <button type="button" pButton pRipple icon="pi pi-file-excel" class="p-button-success"
                            [pTooltip]="projetTooltips.xls" (click)="exportExcelFormat(projetsTable)"
                            tooltipPosition="bottom" [disabled]="!haveDataForExtraction"></button>
                    </div>
                </div>
                <div class="section__colonnes">
                    <p-multiSelect [options]="colonnes" [(ngModel)]="selectedColumns" optionLabel="header"
                        selectedItemsLabel="{0} colonne(s) sélectionné(es)" [group]="haveGroupsData"
                        [maxSelectedLabels]="2" [style]="{'max-width': '200px', 'min-width': '200px'}"
                        [pTooltip]="projetTooltips.multiSelectColumns" tooltipPosition="bottom" appendTo="body"
                        [showToggleAll]="false" placeholder="Choisir les colonnes" (onChange)="onColumnChange($event)">
                        <ng-template pTemplate="group" let-data>
                            <div class="multi-selectcolumn-header">
                                <img *ngIf="data.value === 'inspection'"
                                    src="assets/images/general/points-inspections/nonInspecte.png" alt="">
                                <img *ngIf="data.value === 'audit'" src="assets/images/general/audit/a-auditer.png"
                                    alt="">
                                <div>
                                    {{data.label}}
                                </div>
                            </div>
                        </ng-template>
                    </p-multiSelect>
                    <button type="button" pButton pRipple icon="fa-solid fa-display"
                        [pTooltip]="projetTooltips.resetInterface" tooltipPosition="top"
                        (click)="resetInterface(projetsTable)"></button>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
        <tr>
            <ng-container *ngFor="let col of columns">
                <ng-container *ngIf="col.field !== 'actions'">
                    <ng-container *ngIf="col.sortable !== false">
                        <th [pSortableColumn]="col.field" pResizableColumn pReorderableColumn class="column-resizer">
                            {{ col.header }} <p-sortIcon *ngIf="col.sortable !== false"
                                [field]="col.field"></p-sortIcon>
                        </th>
                    </ng-container>
                    <ng-container *ngIf="col.sortable === false">
                        <th pResizableColumn pReorderableColumn>
                            {{ col.header }}
                        </th>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="col.field === 'actions'">
                    <th alignFrozen="right" pFrozenColumn [frozen]="true" class="actions"
                        [style]="{'width': col.width + '!important', 'max-width': col.width + '!important'}">
                        {{ col.header }}
                    </th>
                </ng-container>
            </ng-container>
        </tr>
        <tr>
            <ng-container *ngFor="let col of columns">
                <ng-container *ngIf="col.field !== 'actions'">
                    <th *ngIf="col.filterable !== false" [class.filtered]="projetsTable.filters[col.field]">
                        <ng-container [ngSwitch]="col.filterType">
                            <ng-container *ngSwitchCase="'dropdown'">
                                <p-dropdown [(ngModel)]="dynamicFiltersModel[col.field]" appendTo="body"
                                    [options]="dynamicFilters['dropdown'][col.field]" optionLabel="label"
                                    optionValue="value" placeholder="Sélectionner" [showClear]="true"
                                    class="dropdown-filter" (onChange)="onColumnFilter($event.value, col)"
                                    [pTooltip]="projetTooltips.dropdown" tooltipPosition="top">
                                </p-dropdown>
                            </ng-container>
                            <ng-container *ngSwitchCase="'multiSelect'">
                                <p-multiSelect [appendTo]="'body'" defaultLabel="Sélectionner"
                                    [(ngModel)]="dynamicFiltersModel[col.field]" [ngModelOptions]="{standalone: true}"
                                    [options]="dynamicFilters['multiSelect'][col.field]" optionLabel="label"
                                    optionValue="value" [maxSelectedLabels]="1" [showHeader]="true" [filterBy]="'label'"
                                    [filterPlaceHolder]="'Rechercher'" [selectedItemsLabel]="'{0} éléments'"
                                    class="multiSelect-filter" [pTooltip]="projetTooltips.multiSelect"
                                    tooltipPosition="top" (onChange)="onColumnFilter($event.value, col)">
                                </p-multiSelect>
                            </ng-container>
                            <ng-container *ngSwitchDefault>
                                <input type="text" class="input-filter"
                                    [pTooltip]="col.inputFilterDataType === 'number' ? projetTooltips.number : projetTooltips.text"
                                    tooltipPosition="top" [placeholder]="col.isDate ? 'AAAA-MM-JJ' : 'filtre'"
                                    (input)="onColumnFilter($any($event.target).value, col)"
                                    [value]="$any(projetsTable.filters[col.field])?.value">
                            </ng-container>
                        </ng-container>
                    </th>
                    <th *ngIf="col.filterable === false"
                        [style]="{'width': col.width + '!important', 'max-width': col.width + '!important'}"></th>
                </ng-container>
                <ng-container *ngIf="col.field === 'actions'">
                    <th alignFrozen="right" pFrozenColumn [frozen]="true" class="actions"></th>
                </ng-container>
            </ng-container>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-projet let-columns="columns">
        <tr [pContextMenuRow]="projet" (dblclick)="onDoubleClickRow(projet)">
            <ng-container *ngFor="let col of columns">
                <td *ngIf="col.field !== 'actions'"
                    [ngClass]="{'prioritaire': col.field === 'type' && projet[col.field] === 'prioritaire',
                    'text-erreur' : col.field === 'statutProjetName' && projet['statut'] === statutProjetEnum.nonConforme}">
                    <ng-template #projectField>
                        <ng-container #projectField *ngIf="col.isDate !== undefined else standardField">
                            <ng-container *ngIf="projet[col.field] > 0">
                                <div [pTooltip]="projet[col.field] | date: 'yyyy-MM-dd'" tooltipPosition="top">
                                    {{ projet[col.field] | date: 'yyyy-MM-dd' }}
                                </div>
                            </ng-container>
                        </ng-container>
                        <ng-template #standardField>
                            <ng-container
                                *ngIf="col.field === 'justificatif' || col.field === 'remarque' else standardFieldText">
                                <div *ngIf="projet[col.field] !== undefined && projet[col.field] !== ''"
                                    [pTooltip]="projet[col.field]" tooltipPosition="top" class="remarque">
                                    <i class="fa-solid fa-comment-dots"></i>
                                </div>
                            </ng-container>
                            <ng-template #standardFieldText>
                                <div [pTooltip]="projet[col.field]" tooltipPosition="top">
                                    {{ projet[col.field] }}
                                </div>
                            </ng-template>
                        </ng-template>
                    </ng-template>

                    <ng-container *ngIf="col.fieldObj !== undefined && col.fieldObj === 'audit' else projectField">
                        <ng-template #emptyCell></ng-template>
                        <ng-container *ngIf="projet[col.fieldObj] else emptyCell">
                            <ng-container *ngIf="col.isDate else basicAuditField">
                                <ng-container
                                    *ngIf="col.fieldProp && projet[col.fieldObj][col.fieldProp] else emptyCell">
                                    <div [pTooltip]="projet[col.fieldObj][col.fieldProp] | date: 'yyyy-MM-dd'"
                                        tooltipPosition="top">
                                        {{projet[col.fieldObj][col.fieldProp] | date: 'yyyy-MM-dd'}}
                                    </div>
                                </ng-container>
                            </ng-container>
                            <ng-template #basicAuditField>
                                <ng-template #standardAuditField>
                                    <ng-container *ngIf="col.fieldProp === 'remarques' else standardAuditFieldText">
                                        <div *ngIf="projet[col.fieldObj][col.fieldProp] !== undefined && projet[col.fieldObj][col.fieldProp] !== ''"
                                            [pTooltip]="projet[col.fieldObj][col.fieldProp]" tooltipPosition="top"
                                            class="remarque">
                                            <i class="fa-solid fa-comment-dots"></i>
                                        </div>
                                    </ng-container>
                                    <ng-template #standardAuditFieldText>
                                        <ng-container
                                            *ngIf="col.fieldProp && projet[col.fieldObj][col.fieldProp] else emptyCell">
                                            <div [pTooltip]="projet[col.fieldObj][col.fieldProp]" tooltipPosition="top">
                                                {{projet[col.fieldObj][col.fieldProp]}}
                                            </div>
                                        </ng-container>
                                    </ng-template>
                                </ng-template>
                                <ng-container
                                    *ngIf="col.fieldProp === 'firme' || col.fieldProp === 'statut' else standardAuditField">
                                    <div [pTooltip]="projet[col.field]" tooltipPosition="top">
                                        {{projet[col.field]}}
                                    </div>
                                </ng-container>
                            </ng-template>
                        </ng-container>
                    </ng-container>
                </td>
                <td *ngIf="col.field === 'actions'" alignFrozen="right" pFrozenColumn [frozen]="true" class="actions"
                    [style]="{'width': col.width + '!important', 'max-width': col.width + '!important'}">
                    <div class="actions_container">
                        <button #ellipsisv pButton pRipple icon="pi pi-ellipsis-v"
                            class="p-button-rounded p-button-secondary p-p-0 assigne-button sticky-column-transparente"
                            (click)="onActionsButtonClick($event, menu, projet)"></button>
                    </div>
                </td>
            </ng-container>
        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-columns>
        <ng-container *ngIf="columns.length > 0">
            <tr>
                <td [attr.colspan]="columns.length+1">
                    <h3>
                        Aucun résultat trouvé
                    </h3>
                </td>
            </tr>
        </ng-container>
    </ng-template>
    <ng-template pTemplate="footer" let-columns>
        <ng-container *ngIf="columns.length > 0 && totalDeProjet > 0">
            <tr class="footer-row">
                <ng-template #projectTemplate>
                    <ng-container #projectTemplate *ngIf="filterIsApplied else allProjects">
                        <ng-container *ngIf="totalFilteredValue !== 0 else allProjects">
                            {{totalFilteredValue}} / {{totalDeProjet}} projets
                        </ng-container>
                    </ng-container>
                    <ng-template #allProjects>
                        {{totalDeProjet}} projets
                    </ng-template>
                </ng-template>
                <ng-container *ngIf="columns.length >= 2">
                    <td [attr.colspan]="columns.length === 2 ? 1 : 2" alignFrozen="left" pFrozenColumn [frozen]="true"
                        class="footer-cell">
                        <ng-container *ngTemplateOutlet="projectTemplate"></ng-container>
                    </td>
                    <td [attr.colspan]="columns.length - (columns.length === 2 ? 1 : 2)" class="footer-cell">
                    </td>
                </ng-container>
            </tr>
        </ng-container>
    </ng-template>
</p-table>
