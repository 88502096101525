
import { UserRole } from '../../../shared/models/user-roles.model';
import { ProjetCompletDto } from '../../../core/api/client/models';
import { StatutProjetsInspection } from '../../projets/models/statut-projets-inspection.enum';

export function getCompleterProjetPermission(projet: ProjetCompletDto): boolean {
    const validStatus: string[] = [StatutProjetsInspection.inspectionEnCours, StatutProjetsInspection.correctionEnCours];
    return validStatus.includes(projet.statut);
}

export function getCompleterProjetAQPermission(projet: ProjetCompletDto): boolean {
    return projet.statut === StatutProjetsInspection.aqEnCours;
}

export function getRapportValidationPermission(projet: ProjetCompletDto): boolean {
    return projet.statut === StatutProjetsInspection.inspectionEnCours ||
        projet.statut === StatutProjetsInspection.aqEnCours;
}

export function getActiverProjetPermission(
    currentActiveProjet: ProjetCompletDto,
    currentActiveProjetAQ: ProjetCompletDto,
    projet: ProjetCompletDto,
    currentUserRole: UserRole
): boolean {
    const canActiverProjetStatutList: string[] = [
        StatutProjetsInspection.inspectionAssigne,
        StatutProjetsInspection.correctionAssignee
    ];
    if (UserRole.CONTROLEUR_QUALITE_EXTERNE.includes(currentUserRole)) {
        return !currentActiveProjetAQ && projet.statut === StatutProjetsInspection.correctionAssignee;
    } else {
        return !currentActiveProjet && canActiverProjetStatutList.includes(projet.statut);
    }
}

export function getActiverProjetPermissionAQ(currentActiveProjetAQ: ProjetCompletDto, projet: ProjetCompletDto): boolean {
    return !currentActiveProjetAQ && (projet.statut === StatutProjetsInspection.aqAssigne || currentActiveProjetAQ?.id === projet.id);
}
