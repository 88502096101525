import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { PointInspection } from 'src/app/features/projet/models/point-inspection.model';
import {
    setPointsInspectionSuccess,
} from '../actions/pointInspection.action';

export interface PointInspectionState extends EntityState<PointInspection> { }

export const pointInspectionAdapter: EntityAdapter<PointInspection> = createEntityAdapter<PointInspection>({
    selectId: (pointInspection: PointInspection) => pointInspection.id
});

export const pointInspectionSelector = pointInspectionAdapter.getSelectors();

export const initialpointInspectionAdapter = pointInspectionAdapter.getInitialState();

export const pointInspectionReducer = createReducer(initialpointInspectionAdapter,
    on(setPointsInspectionSuccess, (state, action) => pointInspectionAdapter.setAll(action.pointsInspection, state)),
);
