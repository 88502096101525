import { ProjetAuditDto } from '../../../core/api/client/models';
import { StatutProjetsAudit } from '../../projets/models/statut-projets-audit.enum';


export function getAssignProjetAuditPermission(projetAudit: ProjetAuditDto): boolean {
    return projetAudit.statut === StatutProjetsAudit.cree ||
        projetAudit.statut === StatutProjetsAudit.assigne ||
        projetAudit.statut === StatutProjetsAudit.enCours ||
        projetAudit.statut === StatutProjetsAudit.nonApprouve;
}

export function getCancelProjetAuditPermission(projetAudit: ProjetAuditDto): boolean {
    return projetAudit.statut === StatutProjetsAudit.cree ||
        projetAudit.statut === StatutProjetsAudit.assigne;
}

export function getRejectProjetAuditPermission(projetAudit: ProjetAuditDto): boolean {
    return projetAudit.statut === StatutProjetsAudit.auditComplete;
}

export function getApproveProjetAuditPermission(projetAudit: ProjetAuditDto, isOffline: boolean): boolean {
    return projetAudit.statut === StatutProjetsAudit.auditComplete && !isOffline;
}

export function getProjetHistoriqueAuditPermission(projetAudit: ProjetAuditDto): boolean {
    return projetAudit.statut !== StatutProjetsAudit.enErreur;
}
