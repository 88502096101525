import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { BaseComponent } from '../../../../shared/components/abstract-base-component';
import { Guid } from 'guid-typescript';
import { orderBy, uniq } from 'lodash';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subscription, from } from 'rxjs';
import { concatMap, filter, finalize, map, takeUntil, tap, toArray } from 'rxjs/operators';
import {
    AnomalieBaseDto,
    AnomaliePilotageDto,
    PhotoEntity,
    PointAuditDto,
    PointInspectionDto,
    ProjetAuditDto,
    ProjetCompletDto
} from '../../../../core/api/client/models';
import {
    selectAnomaliesPilotage,
    selectUtilisateurCanEditPointAudit,
    selectUtilisateurCanEditPointInspection
} from '../../../../state/shared/shared.selectors';
import { Severite } from '../../../../enums/severite';
import * as AuditActions from '../../../../features/audit/state/audit.actions';
import * as InspectionActions from '../../../../features/inspection/state/inspection.actions';
import { PhotoService } from '../../../../services/photo.service';
import { PhotoBackend } from '../../../../shared/models/photo-backend.model';
import { UserInformation } from '../../../../shared/models/user-informations.model';
import * as PhotosActions from '../../../../shared/photos/state/photos.actions';
import { arePhotosUploading, getAnomaliesPhotos, getAnomaliesPhotosLoading } from '../../../../shared/photos/state/photos.selectors';
import { exportPhotosInErrorList, getAddedPhotos } from '../../../../shared/utils/photo.utils';
import { State } from '../../../../state/app.state';
import { StatutPointAudit } from '../../../audit/models/statut-point-audit.enum';
import { getProjetAuditById, getSelectedPointAudit } from '../../../audit/state/audit.selectors';
import { getStatutGlobal } from '../../../audit/utils/audit.utils';
import { MAX_PHOTO_ANOMALIE } from '../../../inspection/models/inspection.const';
import {
    getAnomaliesByInspectionId,
    getCreateAnomalieInspectionSuccess,
    getProjetInspection,
    getSelectedPointInspection,
    getUpdateAnomalieInspectionSuccess
} from '../../../inspection/state/inspection.selectors';
import { StoreName } from '../../../offline/models/indexed-db-store-name.enum';
import { IndexedDbService } from '../../../offline/services/indexed-db.service';
import { OfflineService } from '../../../offline/services/offline.service';
import { AnomalieAuditData } from '../../models/anomalie-audit-data.model';
import { AnomalieTab, AnomalieValue } from '../../models/anomalie-tab.enum';
import { EquipementMineur } from '../../models/equipement-mineur.model';
import { StatutProjetsInspection } from '../../../projets/models/statut-projets-inspection.enum';
import { StatutProjetsAudit } from '../../../projets/models/statut-projets-audit.enum';

@Component({
    selector: 'app-anomalie-dialog',
    templateUrl: './anomalie-dialog.component.html',
    styleUrls: ['./anomalie-dialog.component.scss']
})
export class AnomalieDialogComponent extends BaseComponent implements OnInit, OnDestroy {
    public selectedAnomalie: AnomalieBaseDto | null = null;
    public selectedPointAudit: PointAuditDto | null = null;
    public selectedPointInspection: PointInspectionDto | null = null;
    private currentAnomalie: AnomalieBaseDto | null = null;

    public anomalies$ = this.store.select(getAnomaliesByInspectionId(this.config.data.selectedPointInspection?.id));
    private anomaliePilotage: AnomaliePilotageDto[] = [];

    private projetDownloaded: ProjetCompletDto | null = null;

    public form: FormGroup;
    public equipementForm: FormGroup;

    public modeCreation = false;
    public modeEdition = false;
    public tabs = AnomalieTab;
    public tabValues = AnomalieValue;
    public currentTab = AnomalieTab.equipements;

    public elementList: string[] = [];
    public typeList: string[] = [];
    public causeList: string[] = [];

    private initialEquipmentMineur: EquipementMineur;
    public showActiveAnomaliePilotage = true;
    public isAnomalieButtonPanelDisabled = true;
    public isElementTypeCauseDisabled = false;
    public showAnomaliesAuditData = false;
    public statutPointAudit = StatutPointAudit;
    public anomalieAuditNonConformeDialogVisible: boolean;
    public isAuditeur = false;
    public currentActiveProjetAudit: ProjetAuditDto | undefined;
    public currentActiveProjetInspection: ProjetCompletDto | undefined;
    public projetsInspectionDownloaded: ProjetCompletDto[] | undefined;
    public currentIdentiteUtilisateur: UserInformation | undefined;
    public canEdit = false;

    private adminIsEditingPointAudit = false;
    private isAdminCanEditPointInspection = false;
    private subscriptions: Subscription[] = [];
    private generateAnomalieId: string = '';

    // Photos
    private addedPhotos: PhotoEntity[] = [];
    public photosWithData: PhotoEntity[] = [];
    public originalPhotos: PhotoEntity[] = [];
    public uploadedPhotos: PhotoBackend[] = [];
    public uploadedPhotoLoading = false;
    public photosLoading = false;
    public photosUploading$ = this.store.select(arePhotosUploading);
    public readonly maxPhotoAnomalie = MAX_PHOTO_ANOMALIE;

    private canEditPointAudit: boolean;

    public get detail(): string {
        let element: string;
        let type: string;
        let cause: string;

        if (this.selectedAnomalie && !this.modeCreation) {
            element = this.selectedAnomalie.element as string;
            type = this.selectedAnomalie.type as string;
            cause = this.selectedAnomalie.cause as string;
        } else {
            const formValue = this.form.value;
            element = formValue.element as string;
            type = formValue.type as string;
            cause = formValue.cause as string;
        }
        if (!type) {
            return this.anomaliePilotage?.find(ap => ap.element === element && ap.cause === cause)?.detail as string;
        }
        return this.anomaliePilotage?.find(ap => ap.element === element && ap.cause === cause && ap.type === type)?.detail as string;
    }

    public selectionButtonOptions: SelectItem[] = [
        { label: 'Oui', value: true },
        { label: 'Non', value: false },
    ];

    public get equipementMineurs(): EquipementMineur {
        const equipement: EquipementMineur = {
            luminaire: this.getEquipementsParametres(this.selectedPointInspection?.poteau.luminaire),
            malt: this.getEquipementsParametres(this.selectedPointInspection?.poteau.malt),
            jambeDeForce: this.getEquipementsParametres(this.selectedPointInspection?.poteau.jambeDeForce),
            panneau: this.getEquipementsParametres(this.selectedPointInspection?.poteau.panneau),
            tuteur: this.getEquipementsParametres(this.selectedPointInspection?.poteau.tuteur),
            liaisonAerosouterraine: this.getEquipementsParametres(this.selectedPointInspection?.poteau.liaisonAerosouterraine),
        };
        return equipement;
    }

    public get isOffline(): boolean {
        return !navigator.onLine;
    }

    constructor(
        private readonly fb: FormBuilder,
        private store: Store<State>,
        private config: DynamicDialogConfig,
        private ref: DynamicDialogRef,
        private readonly messageService: MessageService,
        private readonly offlineModeService: OfflineService,
        private photoService: PhotoService,
        private readonly confirmationService: ConfirmationService,
        private dbService: IndexedDbService
    ) {
        super();
    };

    ngOnInit() {
        this.initForm();
        this.initEquipementForm();
        this.subscribeToUtilisateurCanEditPoint();
        this.subscribeToSelectedPointInspection();
        this.subscribeToSelectedPointAudit();
        this.initData();
        this.initAnomaliePilotageLists();
    }

    private initData() { // TODO: Refactor
        this.projetsInspectionDownloaded = this.config.data.projetsInspectionDownloaded;
        this.currentIdentiteUtilisateur = this.config.data.currentIdentiteUtilisateur;
        this.adminIsEditingPointAudit = this.config.data.adminIsEditingPointAudit;
        this.isAuditeur = this.config.data.isAuditeur;

        if (this.isAdminCanEditPointInspection) {
            this.store.dispatch(InspectionActions.getProjetInspectionById({ projetId: this.selectedPointInspection?.projetId }));
        }

        this.setCurrentActiveProjetAudit();
        this.setCurrentActiveProjetInspection();

        this.initEquipementMineur();

        this.canEdit = !this.isAuditeur;

        if (this.canEdit) {
            this.form.enable();
            this.equipementForm.enable();
        } else {
            this.form.disable();
            this.equipementForm.disable();
        }

        this.isAnomalieButtonPanelDisabled = this.disableAnomalieButtonPanel();

        this.initAnomaliesAuditData();
    }

    private initAnomaliesAuditData() {
        if (this.isAuditeur) {
            if (this.selectedPointAudit) {
                this.showAnomaliesAuditData = this.showAuditDataForAuditeur(this.selectedPointAudit);
            } else if (this.adminIsEditingPointAudit) {
                this.showAnomaliesAuditData = this.hasAnomaliesAuditData();
            }
        } else if (!this.isAdminCanEditPointInspection) {
            this.showAnomaliesAuditData = this.showAuditDataForInspecteur();
        }
    }

    private initEquipementMineur() { // TODO: Refactor
        this.initialEquipmentMineur = this.createEquipementMineur(this.selectedPointInspection);
        this.setEquipementValue(this.equipementMineurs);
    }

    private subscribeToUtilisateurCanEditPoint() {
        this.store.select(selectUtilisateurCanEditPointInspection)
            .pipe(
                takeUntil(this.destroyed)
            ).subscribe(canEditPointInspection =>
                this.isAdminCanEditPointInspection = canEditPointInspection
            );

        this.store.select(selectUtilisateurCanEditPointAudit)
            .pipe(
                takeUntil(this.destroyed)
            ).subscribe(canEditPointAudit =>
                this.canEditPointAudit = canEditPointAudit
            );
    }

    private subscribeToSelectedPointInspection() {
        this.store.select(getSelectedPointInspection)
            .pipe(
                filter(pointInspection => !!pointInspection),
                takeUntil(this.destroyed)
            ).subscribe(pointInspection =>
                this.selectedPointInspection = pointInspection
            );
    }

    private subscribeToSelectedPointAudit() {
        this.store.select(getSelectedPointAudit)
            .pipe(
                filter(pointAudit => !!pointAudit),
                takeUntil(this.destroyed)
            ).subscribe(pointAudit =>
                this.selectedPointAudit = pointAudit

            );
    }

    private setCurrentActiveProjetAudit() {
        this.currentActiveProjetAudit = this.config.data.currentActiveProjetAudit;
        if (this.selectedPointAudit && this.selectedPointAudit.projetAuditId) {
            this.subscribeToProjetAudit();
        }
    }

    private setCurrentActiveProjetInspection() {
        this.currentActiveProjetInspection = this.config.data.currentActiveProjetInspection;
        if (this.currentActiveProjetInspection) {
            this.projetDownloaded = this.getProjetInspectionDownloaded(this.currentActiveProjetInspection);
        } else {
            this.subscribeToProjetInspection();
        }
    }

    private subscribeToProjetAudit() {
        this.store.select(getProjetAuditById(this.selectedPointAudit.projetAuditId))
            .pipe(
                filter(projetAudit => !!projetAudit),
                takeUntil(this.destroyed)
            ).subscribe(projetAudit => {
                this.currentActiveProjetAudit = projetAudit;
            });
    }

    private subscribeToProjetInspection() {
        this.store.select(getProjetInspection)
            .pipe(
                filter(projet => !!projet),
                takeUntil(this.destroyed)
            ).subscribe(projet => {
                if (this.isAdminCanEditPointInspection) {
                    this.currentActiveProjetInspection = projet;
                    this.projetDownloaded = projet;
                }
            });
    }

    private initForm() {
        this.form = this.fb.group({
            element: [null, Validators.required],
            type: [null, Validators.required],
            cause: [null, Validators.required],
            remarque: null,
            urgent: false
        });

    }

    private initEquipementForm() {
        this.equipementForm = this.fb.group({
            luminaire: [null, Validators.required],
            presenceMalt: [null, Validators.required],
            presenceJambeDeForce: [null, Validators.required],
            presencePanneau: [null, Validators.required],
            presenceTuteur: [null, Validators.required],
            presenceLAS: [null, Validators.required],
        });

    }

    private initAnomaliePilotageLists(filterActive?: boolean) {
        let anomaliePilotageList: AnomaliePilotageDto[];
        this.subscriptions.push(
            this.store.select(selectAnomaliesPilotage)
                .pipe(
                    tap(ap => {
                        this.anomaliePilotage = ap;
                        anomaliePilotageList = filterActive ? ap.filter(item => item.actif === true) : ap;
                        this.elementList = orderBy(uniq(anomaliePilotageList.map(a => a.element)));
                    })).subscribe(),

            this.form.controls.element.valueChanges.pipe(tap(element => {
                this.form.controls.type.reset();
                this.form.controls.cause.reset();
                this.typeList = orderBy(uniq(anomaliePilotageList.filter(ap => ap.element === element).filter(ap => ap.type)
                    .map(ap => ap.type)));
                if (this.typeList.length === 1) {
                    this.form.controls.type.setValue(this.typeList[0]);
                }
            })).subscribe(),

            this.form.controls.type.valueChanges.pipe(tap(type => {
                this.form.controls.cause.reset();
                const selectedElement = this.form.value.element;
                this.causeList = uniq(anomaliePilotageList.filter(ap => ap.element === selectedElement && ap.type === type)
                    .map(ap => ap.cause));
                if (this.causeList.length === 1) {
                    this.form.controls.cause.setValue(this.causeList[0]);
                }
            })).subscribe()
        );
    }

    private createEquipementMineur(pointInspection: PointInspectionDto): EquipementMineur {
        const equipement: EquipementMineur = {
            luminaire: this.getEquipementsParametres(pointInspection?.poteau.luminaire),
            malt: this.getEquipementsParametres(pointInspection?.poteau.malt),
            jambeDeForce: this.getEquipementsParametres(pointInspection?.poteau.jambeDeForce),
            panneau: this.getEquipementsParametres(pointInspection?.poteau.panneau),
            tuteur: this.getEquipementsParametres(pointInspection?.poteau.tuteur),
            liaisonAerosouterraine: this.getEquipementsParametres(pointInspection?.poteau.liaisonAerosouterraine),
        };

        return equipement;
    }

    public initCreateAnomalie() {
        this.generateAnomalieId = Guid.create().toString();
        this.form.reset();
        this.modeCreation = true;
        this.modeEdition = false;
        this.selectedAnomalie = {};
        this.isElementTypeCauseDisabled = false;
        this.initSelectedAnomaliePhotos();
        this.initAnomaliePilotageLists(this.showActiveAnomaliePilotage);
    }

    private initSelectedAnomaliePhotos() {
        this.photosWithData = [];
        this.originalPhotos = [];
        this.uploadedPhotos = [];
    }

    private getProjetInspectionDownloaded(projetInspection: ProjetCompletDto): ProjetCompletDto {
        return this.projetsInspectionDownloaded?.find(projet => projet.id === projetInspection.id);
    }

    public saveAnomalie() {
        this.form.markAllAsTouched();
        if (this.form.invalid) {
            return this.popupError(`Création d'anomalie`, `Le formulaire n'est pas valide`);
        };

        const formValue = this.form.value;

        this.currentAnomalie = {
            id: this.modeEdition ? this.selectedAnomalie.id : this.generateAnomalieId,
            element: formValue.element,
            type: formValue.type,
            cause: formValue.cause,
            remarque: formValue.remarque,
            urgence: formValue.urgent || false,
            photos: this.modeEdition ? [...this.uploadedPhotos, ...this.photosWithData] : this.uploadedPhotos,
            geometrie: formValue.geometrie
        };

        const anomaliesPhotos: (PhotoBackend | PhotoEntity)[] = [
            ...this.photosWithData,
            ...this.uploadedPhotos
        ];

        this.addedPhotos = getAddedPhotos(anomaliesPhotos, this.originalPhotos, []);

        this.currentAnomalie = {
            ...this.currentAnomalie,
            photos: this.photoService.removeDataFromPhotoArray(anomaliesPhotos)
        };

        // On fait un clone ici car dans le accept de la confirmation, le this.currentAnomalie est undefined
        const clonedAnomalie = { ...this.currentAnomalie };

        if (this.modeEdition) {
            if (this.selectedAnomalie.dateTransfertSap > 0) {
                this.confirmationService.confirm({
                    message: `Un avis a déjà été créé dans SAP pour cette anomalie. \r\n
                    Les modifications effectuées sur l'anomalie ne seront pas envoyées vers SAP. \r\n
                    Si vous poursuivez la modification, veuillez informer votre administrateur afin que l'avis soit mis à jour manuellement dans SAP.`,
                    accept: () => {
                        this.store.dispatch(InspectionActions.updateAnomalieInspection({
                            pointInspection: this.selectedPointInspection,
                            anomalie: clonedAnomalie
                        }));
                    },
                    key: 'modificationAnomalie',
                });
            } else {
                this.store.dispatch(InspectionActions.updateAnomalieInspection({ pointInspection: this.selectedPointInspection, anomalie: this.currentAnomalie }));
            }
        } else if (!this.modeEdition) {
            this.store.dispatch(InspectionActions.createAnomalieInspection({ pointInspection: this.selectedPointInspection, anomalie: this.currentAnomalie }));
        }

        if (this.addedPhotos.length > 0) {
            this.modeEdition ? this.subscribeToUpdateAnomalieInspectionSuccess() : this.subscribeToCreateAnomalieInspectionSuccess();
        } else {
            this.closeSelectedAnomalie();
        }
    }

    private subscribeToUpdateAnomalieInspectionSuccess() {
        this.store.select(getUpdateAnomalieInspectionSuccess)
            .pipe(
                filter(success => !!success),
                takeUntil(this.destroyed),
            ).subscribe(success => {
                if (success && this.addedPhotos.length > 0) {
                    this.saveAnomalieInspectionPhoto();
                    this.closeSelectedAnomalie();
                }
            });
    }

    private subscribeToCreateAnomalieInspectionSuccess() {
        this.store.select(getCreateAnomalieInspectionSuccess)
            .pipe(
                filter(success => !!success),
                takeUntil(this.destroyed),
            ).subscribe(success => {
                if (success && this.addedPhotos.length > 0) {
                    this.saveAnomalieInspectionPhoto();
                    this.closeSelectedAnomalie();
                }
            });
    }

    public saveAnomalieInspectionPhoto() {
        this.addedPhotos.forEach(addedPhoto => {
            this.store.dispatch(InspectionActions.addAnomalieInspectionPhoto({
                pointInspection: this.selectedPointInspection,
                anomalieId: this.currentAnomalie.id,
                photo: addedPhoto
            }));
        });
    }

    public updateSelectedAnomalie(anomalie: AnomalieAuditData) {
        this.selectedAnomalie = anomalie;
    }

    public onAnomalieAuditNonConforme() {
        this.anomalieAuditNonConformeDialogVisible = true;
    }

    public onAnomalieAuditConforme() {
        const pointAudit: PointAuditDto = {
            ...this.selectedPointAudit,
            anomaliesAudit: this.selectedPointAudit.anomaliesAudit.map(anomalie => anomalie.anomalieInspectionId === this.selectedAnomalie.id ? {
                ...anomalie,
                statut: StatutPointAudit.conforme,
                auditeLe: new Date().getTime(),
                auditePar: this.currentIdentiteUtilisateur.courriel
            } : anomalie)
        };

        const updatedPointAudit = this.updatePointAudit(pointAudit);

        this.closeSelectedAnomalie();

        if (updatedPointAudit?.anomaliesAudit.every(anomalie => anomalie.statut !== StatutPointAudit.aAuditer)) {
            this.ref.close();
        }
    }

    public deleteAnomalieAuditNonConforme() {
        const pointAudit: PointAuditDto = {
            ...this.selectedPointAudit,
            anomaliesAudit: this.selectedPointAudit.anomaliesAudit.map(anomalie => anomalie.anomalieInspectionId === this.selectedAnomalie.id ? {
                ...anomalie,
                statut: StatutPointAudit.aAuditer,
                photos: [],
                remarque: null,
                auditeLe: 0,
                auditePar: null
            } : anomalie)
        };

        const updatedPointAudit = this.updatePointAudit(pointAudit);

        if (updatedPointAudit?.anomaliesAudit.every(anomalie => anomalie.statut === StatutPointAudit.aAuditer)) {
            this.ref.close();
        }
    }

    private updatePointAudit(pointAudit: PointAuditDto): PointAuditDto {
        const updatedPointAudit: PointAuditDto = {
            ...pointAudit,
            statutGlobal: getStatutGlobal(pointAudit)
        };

        this.store.dispatch(AuditActions.updatePointAudit({ pointAudit: updatedPointAudit }));

        return updatedPointAudit;
    }

    public deleteAnomalieAuditNonConformeConfirm() {
        this.confirmationService.confirm({
            message: `<strong>Vous allez supprimer la non-conformité signalée pour cet élément.</strong><br><br>
                Le changement que vous êtes en train d'effectuer entraînera la suppression des
                informations de non-conformité déjà enregistrées pour cet élément.<br><br>
                Êtes-vous certain de vouloir supprimer cette non-conformité ?`,
            accept: () => {
                this.deleteAnomalieAuditNonConforme();
            },
            key: 'deleteAnomalieAuditNonConforme'
        });
    }

    private isPointAuditContainsAnomalieAudit(pointAudit: PointAuditDto): boolean {
        return !!pointAudit?.anomaliesAudit.find(anomalie => anomalie.statut === StatutPointAudit.nonConforme);
    }

    private isProjetAuditAllowAuditorToSeeAuditData(): boolean {
        return this.currentActiveProjetAudit && this.currentActiveProjetAudit.statut === StatutProjetsAudit.enCours;
    }

    private isProjetAuditAllowAdminGCSPToSeeAuditData(): boolean {
        return this.currentActiveProjetAudit && this.currentActiveProjetAudit.statut === StatutProjetsAudit.auditComplete;
    }

    private showAuditDataForAuditeur(pointAudit: PointAuditDto): boolean {
        return this.isPointAuditContainsAnomalieAudit(pointAudit) && (this.isProjetAuditAllowAuditorToSeeAuditData() ||
            (this.isProjetAuditAllowAdminGCSPToSeeAuditData && this.adminIsEditingPointAudit));
    }

    private pointInspectionContainsAnomaliesAudit(): boolean {
        return this.selectedPointInspection.pointsAudit && this.selectedPointInspection.pointsAudit[0]?.anomaliesAudit?.length > 0;
    }

    private showAuditDataForInspecteur(): boolean {
        return (this.pointInspectionContainsAnomaliesAudit() && this.projetDownloaded?.statut === StatutProjetsInspection.correctionEnCours);
    }

    private hasAnomaliesAuditData() {
        if (this.canEditPointAudit) {
            if (this.isProjetAuditAllowAdminGCSPToSeeAuditData() && this.adminIsEditingPointAudit && this.selectedPointAudit.anomaliesAudit?.length > 0) {
                return this.selectedPointAudit.anomaliesAudit.some(anomalie => anomalie.statut === StatutPointAudit.nonConforme);
            }
        }

        return false;
    }

    public isConformeButtonDisable(): boolean {
        return !!this.selectedPointAudit.anomaliesAudit
            .filter(anomalie => anomalie.anomalieInspectionId === this.selectedAnomalie?.id
                && (anomalie.statut === StatutPointAudit.conforme ||
                    anomalie.statut === StatutPointAudit.nonConforme)).length;
    }

    public isNonConformeButtonDisable(): boolean {
        return !!this.selectedPointAudit.anomaliesAudit
            .filter(anomalie => anomalie.anomalieInspectionId === this.selectedAnomalie?.id
                && anomalie.statut === StatutPointAudit.nonConforme).length;
    }

    public closeSelectedAnomalie() {
        this.modeCreation = false;
        this.modeEdition = false;
        this.selectedAnomalie = {};
        this.generateAnomalieId = '';
        this.addedPhotos = [];
        this.currentAnomalie = undefined;
        this.initSelectedAnomaliePhotos();
    }

    public closeAnomalieDialog() {
        this.closeSelectedAnomalie();
        this.ref.close();
    }

    public cancelCreateAnomalie() {
        this.closeSelectedAnomalie();
    }

    public openTab(tabViewName: AnomalieTab) {
        this.currentTab = tabViewName;
    }

    private disableAnomalieButtonPanel(): boolean {
        return Object.values(this.initialEquipmentMineur).some((value: any | null) => value === null);
    }

    public removeImage(file: any) {
        this.uploadedPhotos = this.uploadedPhotos.filter(ui => ui.nomOriginal !== file.nomOriginal);
    }

    public getAnomalieInfo(anomalie: AnomalieBaseDto) {
        this.initSelectedAnomaliePhotos();
        this.initAnomaliePilotageLists(this.showActiveAnomaliePilotage);
        if (!this.isAuditeur) {
            this.setElementTypeCauseState(anomalie);
        }


        if (!this.selectedAnomalie) {
            return this.closeSelectedAnomalie();
        }

        this.modeCreation = true;
        this.modeEdition = true;
        this.form.controls.element.setValue(anomalie.element);
        this.form.controls.type.setValue(anomalie.type);
        this.form.controls.cause.setValue(anomalie.cause);
        this.form.controls.remarque.setValue(anomalie.remarque);
        this.form.controls.urgent.setValue(anomalie.urgence);
        this.originalPhotos = anomalie.photos;
        this.loadPhotos(this.originalPhotos);
    }

    public setElementTypeCauseState(anomalie: AnomalieBaseDto) {
        this.isElementTypeCauseDisabled = false;
        this.anomaliePilotage.filter(item => item.actif === false).forEach((ap: AnomaliePilotageDto) => {
            if (ap.element === anomalie.element &&
                ap.type === anomalie.type &&
                ap.cause === anomalie.cause) {
                this.isElementTypeCauseDisabled = true;
                this.initAnomaliePilotageLists();
            }
        });
    }

    private setEquipementValue(equipement: EquipementMineur) {
        this.equipementForm.controls.luminaire.setValue(equipement.luminaire);
        this.equipementForm.controls.presenceMalt.setValue(equipement.malt);
        this.equipementForm.controls.presenceJambeDeForce.setValue(equipement.jambeDeForce);
        this.equipementForm.controls.presencePanneau.setValue(equipement.panneau);
        this.equipementForm.controls.presenceTuteur.setValue(equipement.tuteur);
        this.equipementForm.controls.presenceLAS.setValue(equipement.liaisonAerosouterraine);
    }

    public saveEquipement() {
        const formValue = this.equipementForm.value;

        const updatedPointInspection: PointInspectionDto = {
            ...this.selectedPointInspection,
            inspecteLe: new Date().getTime(),
            poteau: {
                ...this.selectedPointInspection.poteau,
                luminaire: formValue.luminaire,
                malt: formValue.presenceMalt,
                tuteur: formValue.presenceTuteur,
                panneau: formValue.presencePanneau,
                jambeDeForce: formValue.presenceJambeDeForce,
                liaisonAerosouterraine: formValue.presenceLAS
            }
        };

        if (updatedPointInspection.dateTransfertSap > 0) {
            this.confirmationService.confirm({
                header: 'Avertissement',
                message: `Un avis a déjà été créé dans SAP pour au moins une des anomalies du point d'inspection.\r\n
                    Les modifications effectuées sur le point d'inspection, ne seront pas envoyées vers SAP.\r\n
                    Voulez-vous vraiment enregistrer les modifications ?`,
                accept: () => {
                    this.store.dispatch(InspectionActions.updatePointInspection({ pointInspection: updatedPointInspection }));
                    this.openTab(this.tabs.anomalie);
                },
                reject: () => {
                    return;
                }
            });
        } else {
            this.store.dispatch(InspectionActions.updatePointInspection({ pointInspection: updatedPointInspection }));
            this.openTab(this.tabs.anomalie);
        }

    }

    public cancelEquipement() {
        if (this.equipementForm.dirty) {
            this.setEquipementValue(this.initialEquipmentMineur);
            this.equipementForm.markAsPristine();

            Object.keys(this.equipementForm.controls).forEach(key => {
                const abstractControl = this.equipementForm.get(key);
                if (abstractControl !== null) {
                    abstractControl.setErrors(null);
                }
            });
        }
    }

    public deleteAnomalie(anomalie: AnomalieBaseDto) {
        if (!this.projetDownloaded || (!this.offlineModeService.isOfflineMode && !navigator.onLine)) {
            return this.popupError('Erreur', `Le projet ` + this.currentActiveProjetInspection?.nom + ` doit être téléchargé`);
        }
        if (anomalie.dateTransfertSap > 0) {
            this.confirmationService.confirm({
                message: `Un avis a déjà été créé dans SAP pour cette anomalie. \r\n
                Si vous poursuivez la suppression, veuillez informer votre administrateur afin que l'avis soit mis à jour manuellement dans SAP. `,
                accept: () => {
                    this.closeSelectedAnomalie();
                    this.store.dispatch(InspectionActions.deleteAnomalieInspection({ pointInspection: this.selectedPointInspection, anomalieId: anomalie.id }));
                },
                key: 'deleteAnomalieSap'
            });
        } else {
            this.confirmationService.confirm({
                message: `Voulez-vous supprimer l'anomalie ainsi que les photos associées?`,
                accept: () => {
                    this.closeSelectedAnomalie();
                    this.store.dispatch(InspectionActions.deleteAnomalieInspection({ pointInspection: this.selectedPointInspection, anomalieId: anomalie.id }));
                },
                key: 'deleteAnomalie'
            });
        }
    }

    private getEquipementsParametres(equipementParametre: boolean | undefined): boolean | null {
        if (equipementParametre === undefined) {
            return null;
        } else {
            return equipementParametre as boolean;
        };
    }

    private popupError(summary: string, detail: string): void {
        this.messageService.add(
            {
                severity: Severite.erreur,
                closable: true,
                summary: summary,
                detail: detail
            });
    }

    // Photos
    public removePhoto(photoId: string) {
        this.photosWithData = this.photoService.removePhoto(this.photosWithData, photoId);
    }

    public onUploadPhotos(photos: File[]) {
        this.uploadedPhotoLoading = true;
        from(photos).pipe(
            concatMap(photo => from(this.photoService.cleanImage(photo))
                .pipe(
                    map((imageBase64: string): PhotoEntity => {
                        return {
                            id: Guid.create().toString(),
                            nomOriginal: photo.name,
                            nom: '',
                            anomalieInspectionId: this.modeEdition ? this.selectedAnomalie.id : this.generateAnomalieId,
                            data: this.photoService.base64ToFile(imageBase64, photo.name)
                        };
                    }),
                )
            ),
            toArray(),
            finalize(() => this.uploadedPhotoLoading = false)
        ).subscribe((cleanedPhotos) => {
            this.uploadedPhotos = cleanedPhotos;

        });
    }

    private loadPhotos(photosWithoutData: PhotoEntity[]) {
        // Ici, s'il est admin, on charge les photos de l'anomalie actuelle
        if (this.isAdminCanEditPointInspection) {
            this.store.dispatch(PhotosActions.loadAnomaliesPhotos({ photos: photosWithoutData }));

            this.store.select(getAnomaliesPhotos)
                .pipe(
                    takeUntil(this.destroyed)
                ).subscribe(photosWithData => this.photosWithData = [...photosWithData]);

            this.store.select(getAnomaliesPhotosLoading).pipe(
                takeUntil(this.destroyed)
            ).subscribe(loading => this.photosLoading = loading);
            // Sinon, on récupère les photos de indexdb
        } else {
            this.photosLoading = true;
            this.dbService.getAll<PhotoEntity>(StoreName.PHOTOS)
                .pipe(
                    takeUntil(this.destroyed)
                ).subscribe(photos => {
                    this.photosLoading = false;
                    const photoIds = photosWithoutData.map(photo => photo.id);
                    this.photosWithData = photos.filter(photo => photoIds.includes(photo.id));

                    if (this.photosWithData.length !== photosWithoutData.length) {
                        const photosInError = photosWithoutData.filter(photo => this.photosWithData.map(displayedPhoto => displayedPhoto.id).includes(photo.id));
                        exportPhotosInErrorList(photosInError);

                        this.messageService.add({
                            severity: 'warn',
                            summary: 'Photos manquantes',
                            detail: `Certaines photos n'ont pas pu être chargées. La liste des photos manquantes a été téléchargée.`,
                            life: 5000
                        });
                    }
                });
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
}
