import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, takeUntil } from 'rxjs';
import { Store } from '@ngrx/store';
import { BaseComponent } from '../../../shared/components/abstract-base-component';
import {
    selectCanOpenCreatePoteauSansAnomalie,
    selectCanOpenCreateProjet,
    selectCanOpenProjetAuditList,
    selectCanOpenProjetInspectionList,
    selectCanOpenProjetsList,
    selectUtilisateurIsPilotage,
} from '../../../state/shared/shared.selectors';
import { UiService } from '../../../services/ui.service';

@Injectable()
export class HeaderLinkMenu extends BaseComponent {
    private canOpenPilotage = false;
    private canOpenProjetsList = false;
    private canOpenProjetInspectionList = false;
    private canOpenProjetAuditList = false;
    private canOpenCreateProjet = false;
    private canOpenCreatePoteauSansAnomalie = false;

    private menuIsReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public menuIsReady$: Observable<boolean> = this.menuIsReady.asObservable();

    constructor(private store: Store, private uiService: UiService) {
        super();
        this.subscribeToMenuItems();
    }

    private subscribeToMenuItems() {
        combineLatest([
            this.store.select(selectUtilisateurIsPilotage),
            this.store.select(selectCanOpenCreateProjet),
            this.store.select(selectCanOpenProjetsList),
            this.store.select(selectCanOpenProjetInspectionList),
            this.store.select(selectCanOpenProjetAuditList),
            this.store.select(selectCanOpenCreatePoteauSansAnomalie)
        ]).pipe(
            takeUntil(this.destroyed)
        ).subscribe(([
            openPilotage,
            openCreateProjet,
            openProjetsList,
            openProjetInspectionList,
            openProjetAuditList,
            openCreatePoteauSansAnomalie
        ]) => {
            this.canOpenPilotage = openPilotage;
            this.canOpenCreateProjet = openCreateProjet;
            this.canOpenProjetsList = openProjetsList;
            this.canOpenProjetInspectionList = openProjetInspectionList;
            this.canOpenProjetAuditList = openProjetAuditList;
            this.canOpenCreatePoteauSansAnomalie = openCreatePoteauSansAnomalie;
            this.menuIsReady.next(true);
        });
    }

    public getCreateProjetMenuItem() {
        return {
            label: 'Création de projet',
            icon: 'fas fa-plus-square',
            visible: this.canOpenCreateProjet,
            value: 'createProjet',
            link: () => {
                this.uiService.closeActionSheet(true);
                this.uiService.openCreateProjetModal(true);
            }
        };
    }

    public getTableauProjetMenuItem() {
        return {
            label: `Tableau des projets`,
            icon: 'fas fa-table',
            visible: this.canOpenProjetsList,
            value: 'projetsList',
            link: () => {
                this.uiService.closeActionSheet(true);
                this.uiService.openProjetsListModal(true);
            }
        };
    }

    public getTableauProjetInspectionMenuItem() {
        return {
            label: `Tableau des projets d'inspection`,
            icon: 'fas fa-table',
            visible: this.canOpenProjetInspectionList,
            value: 'projetInspectionList',
            link: () => {
                this.uiService.closeActionSheet(true);
                this.uiService.openProjetInspectionListModal(true);
            }
        };
    }

    public getTableauProjetAuditMenuItem() {
        return {
            label: `Tableau des projets d'audit`,
            icon: 'fas fa-clipboard-list',
            visible: this.canOpenProjetAuditList,
            value: 'projetAuditList',
            link: () => {
                this.uiService.closeActionSheet(true);
                this.uiService.openProjetAuditListModal(true);
            }
        };
    }

    public getAjouterPoteauMenuItem() {
        return {
            label: 'Ajouter poteau',
            icon: 'fas fa-plus-square',
            value: 'createPoteau',
            link: () => {
                this.uiService.closeActionSheet(true);
                this.uiService.openCreatePoteauModal(true);
            }
        };
    }

    public getAjouterPoteauSansAnomalieMenuItem() {
        return {
            label: 'Ajouter poteau sans anomalie',
            icon: 'fas fa-plus-square',
            visible: this.canOpenCreatePoteauSansAnomalie,
            value: 'createPoteauSansAnomalie',
            link: () => {
                this.uiService.closeActionSheet(true);
                this.uiService.openCreatePoteauSansAnomalieModal(true);
            }
        };
    }

    public getRechercherMenuItem() {
        return {
            label: 'Recherche',
            icon: 'fas fa-search',
            value: 'recherche',
            link: () => {
                this.uiService.closeActionSheet(true);
                this.uiService.openRechercheModal(true);
            }
        };
    }

    public getPilotageMenuItem() {
        return {
            label: 'Pilotage',
            icon: 'fas fa-cogs',
            visible: this.canOpenPilotage,
            value: 'pilotage',
            link: () => {
                this.uiService.closeActionSheet(true);
                this.uiService.openPilotageModal(true);
            }
        };
    }

    public getRefreshMenuItem() {
        return {
            label: 'Actualiser la page',
            icon: 'fas fa-sync',
            value: 'refresh',
            link: () => {
                document.location.reload();
            }
        };
    }

    public getAproposMenuItem() {
        return {
            label: 'À propos',
            icon: 'fas fa-question',
            value: 'aPropos',
            link: () => {
                this.uiService.closeActionSheet(true);
                this.uiService.openVersionModal(true);
            }
        };
    }

    public getMenuMobile() {
        return [
            this.getTableauProjetInspectionMenuItem(),
            this.getTableauProjetAuditMenuItem(),
            this.getAjouterPoteauMenuItem(),
            this.getAjouterPoteauSansAnomalieMenuItem(),
            this.getRechercherMenuItem(),
            this.getAproposMenuItem(),
        ];
    }

    public getMenuWeb() {
        return [
            this.getCreateProjetMenuItem(),
            this.getTableauProjetMenuItem(),
            this.getTableauProjetInspectionMenuItem(),
            this.getTableauProjetAuditMenuItem(),
            this.getRefreshMenuItem(),
            this.getRechercherMenuItem(),
            this.getPilotageMenuItem(),
            this.getAproposMenuItem(),
        ];
    }
}
