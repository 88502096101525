import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import { userInformationReducer, UserInformationState } from './userInformation.reducer';
import { userReducer, UserState } from './user.reducer';
import { anomalieReducer, AnomalieState } from '../../../features/anomalie/state/anomalie/anomalie.reducer';
import { anomaliePilotageReducer, AnomaliePilotageState } from './anomaliesPilotage.reducer';
import { TaxonomieState, taxonomieReducer } from './taxonomie.reducer';

export interface CoreState {
    user: UserState;
    userInformation: UserInformationState;
    anomalie: AnomalieState;
    anomaliesPilotage: AnomaliePilotageState;
    taxonomie: TaxonomieState;
}

export const coreReducers: ActionReducerMap<CoreState> = {
    user: userReducer,
    userInformation: userInformationReducer,
    anomalie: anomalieReducer,
    anomaliesPilotage: anomaliePilotageReducer,
    taxonomie: taxonomieReducer,
};

export const getCoreState = createFeatureSelector<CoreState>('core');

