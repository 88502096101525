import { Component, ViewChild, OnDestroy } from '@angular/core';
import { ComponentBase, ComponentFeatures, InheritsBaseLifecycleHooks } from '@ngxhq/common-ui';
import { Table } from 'primeng/table';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Taxonomie, TaxonomieRaw } from '../models/taxonomie.model';
import { ConfirmationService, FilterService, SelectItem } from 'primeng/api';
import { FormBuilder, FormGroup } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { TableColumns } from '../models/table-columns.model';
import { UiService } from '../../../services/ui.service';
import { TaxonomieGroupeValue } from '../models/taxonomie-groupe.enum';
import { select, Store } from '@ngrx/store';
import {
    getAllTaxonomieState,
    getTaxonomieGroupeOptions,
    getTaxonomieGroupeSpecifiqueOptions,
    getToutesTaxonomieGroupesOptions
} from '../../../core/store/selectors/taxonomie.selectors';
import { createOneTaxonomie, updateOneTaxonomie, deleteOneTaxonomie } from '../../../core/store/actions/taxonomie.action';
import { customGroupesFilter } from '../../../shared/filters/custom.filter';

@Component({
    styleUrls: ['./pilotage-dialog.component.scss'],
    templateUrl: './pilotage-dialog.component.html',
})
@ComponentFeatures([
    InheritsBaseLifecycleHooks()
])
export class PilotageDialogComponent extends ComponentBase implements OnDestroy {
    public createMode = false;
    public groupsIncludeFirme = false;
    public groupsIncludeAuditeur = false;
    public groupsAreOtherThenFirmeAndAuditeur = true;
    private groupeMultiSelectOptions: SelectItem<string>[] = [];
    private groupeOptions: BehaviorSubject<SelectItem<string>[]> = new BehaviorSubject<SelectItem<string>[]>([]);
    public groupeOptions$: Observable<SelectItem<string>[]> = this.groupeOptions.asObservable();

    private customGroupesFilter = 'custom-groupes-filters';
    public taxonomiesGroupesGlobal$: Observable<SelectItem<string>[]>;
    public taxonomiesGroupes$: Observable<SelectItem<string>[]>;
    public fournisseurOptions$: Observable<SelectItem<string>[]>;
    public fournisseurAuditOptions$: Observable<SelectItem<string>[]>;

    public specificFournisseurHqd$: Observable<SelectItem<string>[]>;
    private specificFournisseurHqd: SelectItem<string>;

    private subscriptions: Subscription[] = [];
    public groupeSelectedValue: string = '';
    public originalValues: { [id: string]: Taxonomie; } = {};
    public taxonomieForm: FormGroup;
    public displayRowsAction: boolean[] = [];
    public originalNonEditedValues: any;
    public allOriginalData: Taxonomie[] = [];
    public allOriginalRawData: TaxonomieRaw[] = [];
    public filterGlobalDisabledAddButton = true;
    public editDisabledAddButton = false;
    public disableGlobalFilter = false;
    public colonnes: TableColumns[];

    public allTaxonomie: TaxonomieRaw[] = [];
    public taxonomieGroupeValue = TaxonomieGroupeValue;

    public selectedGroupes: string[] = [];
    public selectedTaxonomie: Taxonomie;

    public currentAllTaxonomies$: Observable<Taxonomie[]> = this.store
        .pipe(
            select(getAllTaxonomieState),
            tap((taxonomies: Taxonomie[]) => {
                this.populateData(taxonomies);
            })
        );

    private idNew = 'new';
    private groupeTout = 'tout';

    @ViewChild('taxoTable', { static: false }) taxoTable: Table;

    constructor(
        private formBuilder: FormBuilder,
        private confirmationService: ConfirmationService,
        private readonly uiService: UiService,
        private readonly store: Store,
        private filterService: FilterService,
    ) {
        super();
        this.filterService.register(this.customGroupesFilter, customGroupesFilter);
        this.taxonomiesGroupesGlobal$ = this.store.select(getToutesTaxonomieGroupesOptions());
        this.taxonomiesGroupes$ = this.store.select(getToutesTaxonomieGroupesOptions(false)).pipe(
            tap((value: SelectItem<string>[]) => {
                this.groupeMultiSelectOptions = value;
                this.groupeOptions.next(value);
            })
        );
        this.fournisseurOptions$ = this.store.select(getTaxonomieGroupeOptions(this.taxonomieGroupeValue.FIRME));
        this.fournisseurAuditOptions$ = this.store.select(getTaxonomieGroupeOptions(this.taxonomieGroupeValue.FIRME_AUDIT));
        this.specificFournisseurHqd$ = this.store
            .pipe(
                select(getTaxonomieGroupeSpecifiqueOptions(this.taxonomieGroupeValue.FIRME, 'HQD')),
                tap((value: any) => {
                    this.specificFournisseurHqd = value[0];
                })
            );

        this.taxonomieForm = this.formBuilder.group({
            taxonomieFormDetails: this.formBuilder.array([]),
        });

        this.colonnes = [
            { field: 'code', header: 'Code', dataType: 'text', filterType: 'text' },
            { field: 'estActif', header: 'Actif', dataType: 'checkbox', filterType: 'checkbox' },
            { field: 'groupes', header: 'Groupes', dataType: 'multiSelect', filterType: 'multiSelect', width: '200px' },
            { field: 'proprietes', header: 'Propriété', dataType: 'dropdown', filterType: 'dropdown', width: '200px' },
        ];

        this.addSubscription();
    }

    private addSubscription(): void {
        this.subscriptions.push(
            this.specificFournisseurHqd$.subscribe(),
            this.fournisseurOptions$.subscribe(),
            this.fournisseurAuditOptions$.subscribe(),
            this.taxonomiesGroupesGlobal$.subscribe(),
            this.taxonomiesGroupes$.subscribe(),
        );
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        this.uiService.openPilotageModal(false);
    }

    public mainFilter(eventValue: string): void {
        if (eventValue !== '' && eventValue !== this.groupeTout) {
            this.groupeSelectedValue = eventValue;
            this.taxoTable.filter(eventValue, 'groupes', this.customGroupesFilter);
            this.filterGlobalDisabledAddButton = false;
        } else {
            this.filterGlobalDisabledAddButton = true;
            this.groupeSelectedValue = this.groupeTout;
            this.taxoTable.filter('', 'groupes', 'contains');
        }
    }

    public filtrerCheckbox(checked: boolean, field: string, match: string = 'equals'): void {
        this.filtrerTable(checked ? checked : '', field, match);
    }

    public filtrerTexte(input: any, field: string, match: string = 'contains'): void {
        this.taxoTable.filter(input.value, field, match);
    }

    public filtrerDropdown(event: string, field: string, match: string = 'contains'): void {
        this.filtrerTable(event, field, match);
    }

    private filtrerTable(value: any, field: string, match: string): void {
        this.taxoTable.filter(value, field, match);
    }

    public populateData(taxonomies: Taxonomie[]): void {
        const formatTaxo: TaxonomieRaw[] = [];
        taxonomies.forEach((taxo: Taxonomie) => {
            formatTaxo.push({
                ...taxo,
                proprietesJson: taxo.proprietes !== '' ? JSON.parse(taxo.proprietes!.replace(/\'/gm, '"')) : { firmeID: '' }
            });
        });

        this.allTaxonomie = formatTaxo;
        this.allOriginalRawData = formatTaxo;
        this.displayRowsActions(true);
    }

    private displayRowsActions(disabled: boolean): void {
        this.allOriginalRawData.forEach((data: Taxonomie) => {
            this.displayRowsAction[data.id! as any] = disabled;
        });

        if (this.createMode && disabled) {
            this.createMode = !disabled;
        }
    }

    public getNomFirme(taxonomie: TaxonomieRaw): string {
        if (taxonomie.proprietesJson && taxonomie.proprietesJson.firmeID !== '') {
            const myTaxo = this.allOriginalRawData.find((taxo: Taxonomie) => {
                return taxo.id === taxonomie.proprietesJson!.firmeID;
            });

            return myTaxo ? myTaxo.code : '';
        } else {
            return '';
        }
    }

    public ajouterTaxonomie(): void {
        this.createMode = true;
        this.editDisabledAddButton = true;
        this.disableGlobalFilter = true;
        this.onAjouter();
        this.mainFilter(this.groupeSelectedValue);
        this.displayRowsActions(false);
        this.displayRowsAction[this.idNew as any] = true;
        this.enableEditRow(this.allTaxonomie[0]);
    }

    public onAjouter(): void {
        this.allTaxonomie.unshift({
            code: '',
            estActif: true,
            groupes: [(this.groupeSelectedValue !== '' && this.groupeSelectedValue !== this.groupeTout) ? this.groupeSelectedValue : ''],
            id: this.idNew,
            proprietes: this.groupeSelectedValue === this.taxonomieGroupeValue.AUDITEURS ? this.specificFournisseurHqd.value : '',
            proprietesJson: {
                firmeID: this.groupeSelectedValue === this.taxonomieGroupeValue.AUDITEURS ? this.specificFournisseurHqd.value : ''
            },
        });
    }

    public enableEditRow(taxonomie: Taxonomie, resetInitValue = true): void {
        this.onRowEditInit(taxonomie, resetInitValue);
        this.taxoTable.initRowEdit(taxonomie);
    }

    private propertyDropdownOptions(taxonomie: Taxonomie) {
        if (this.createMode) {
            this.propertyDropdownOptionsCreationMode(taxonomie);
        } else {
            this.propertyDropdownOptionsEditonMode(taxonomie);
        }
    }

    private propertyDropdownOptionsCreationMode(taxonomie: Taxonomie) {
        if (taxonomie.groupes[0] === this.taxonomieGroupeValue.FIRME || taxonomie.groupes[0] === this.taxonomieGroupeValue.FIRME_AUDIT) {
            this.groupsIncludeFirme = true;
            this.groupsIncludeAuditeur = false;
            this.groupsAreOtherThenFirmeAndAuditeur = false;
        } else if (taxonomie.groupes[0] === this.taxonomieGroupeValue.AUDITEURS) {
            this.groupsIncludeFirme = false;
            this.groupsIncludeAuditeur = true;
            this.groupsAreOtherThenFirmeAndAuditeur = false;
        } else {
            this.groupsIncludeFirme = false;
            this.groupsIncludeAuditeur = false;
            this.groupsAreOtherThenFirmeAndAuditeur = true;
        }
    }

    private propertyDropdownOptionsEditonMode(taxonomie: Taxonomie) {
        if (taxonomie.proprietes) {
            this.groupsIncludeFirme = false;
            const parsedProperty = JSON.parse(taxonomie.proprietes);

            const firmeGroupe = this.allTaxonomie.filter((taxo: TaxonomieRaw) => taxo.id === parsedProperty.firmeID).map((taxo: TaxonomieRaw) => taxo.groupes[0]);
            if (firmeGroupe[0] === this.taxonomieGroupeValue.FIRME_AUDIT) {
                this.groupsIncludeAuditeur = true;
                this.groupsAreOtherThenFirmeAndAuditeur = false;
                this.setUpGroupeMultiselect('audit');
            } else {
                this.groupsIncludeAuditeur = false;
                this.groupsAreOtherThenFirmeAndAuditeur = true;
                parsedProperty.firmeID === this.specificFournisseurHqd.value ? this.setUpGroupeMultiselect('hqd') : this.setUpGroupeMultiselect('inspection');
            }
        } else {
            this.groupsIncludeFirme = true;
            this.groupsIncludeAuditeur = false;
            this.groupsAreOtherThenFirmeAndAuditeur = false;
            this.setUpGroupeMultiselect(taxonomie.groupes[0] === this.taxonomieGroupeValue.FIRME ? 'firme' : 'firmeAudit');
        }
    }

    private setUpGroupeMultiselect(groupeTypes: 'hqd' | 'audit' | 'inspection' | 'firme' | 'firmeAudit') {
        const auditeur: SelectItem<string> = this.groupeMultiSelectOptions.find((option) => option.value === this.taxonomieGroupeValue.AUDITEURS);
        const inspecteur: SelectItem<string> = this.groupeMultiSelectOptions.find((option) => option.value === this.taxonomieGroupeValue.INSPECTEURS);
        const employe: SelectItem<string> = this.groupeMultiSelectOptions.find((option) => option.value === this.taxonomieGroupeValue.EMPLOYES);
        const controleurQualite: SelectItem<string> = this.groupeMultiSelectOptions.find((option) => option.value === this.taxonomieGroupeValue.CONTROLEUR_QUALITE);
        const firme: SelectItem<string> = this.groupeMultiSelectOptions.find((option) => option.value === this.taxonomieGroupeValue.FIRME);
        const firmeAudit: SelectItem<string> = this.groupeMultiSelectOptions.find((option) => option.value === this.taxonomieGroupeValue.FIRME_AUDIT);

        let options: SelectItem<string>[] = [];
        switch (groupeTypes) {
            case 'hqd': options = [inspecteur, controleurQualite, employe]; break;
            case 'inspection': options = [inspecteur, controleurQualite, employe]; break;
            case 'audit': options = [auditeur]; break;
            case 'firme':
            case 'firmeAudit': options = [firme, firmeAudit]; break;
        }

        this.groupeOptions.next(options);
    }

    private removeUnsavedTaxonomie(taxonomie: Taxonomie): void {
        this.allTaxonomie = this.allTaxonomie.filter((data) => data.id !== taxonomie.id);
    }

    public applyOriginalData(): void {
        const idFound = this.allTaxonomie.findIndex((taxo: TaxonomieRaw) => taxo.id === this.originalNonEditedValues.id);
        if (idFound !== -1) {
            this.allTaxonomie[idFound] = { ...this.originalNonEditedValues };
        }
    }

    public onRowEditInit(taxonomie: TaxonomieRaw, resetOriginal = true): void {
        this.propertyDropdownOptions(taxonomie);
        if (resetOriginal) {
            this.originalNonEditedValues = JSON.parse(JSON.stringify(taxonomie));
        }
        this.initSelectedUserGroups(taxonomie);
        this.displayRowsActions(false);
        this.displayRowsAction[taxonomie.id! as any] = true;
        this.editDisabledAddButton = true;
        this.disableGlobalFilter = true;
        this.taxoTable.editingRowKeys = { [taxonomie.id!]: true };
    }

    public initSelectedUserGroups(taxonomie: TaxonomieRaw) {
        console.dir(taxonomie);
        this.selectedGroupes = taxonomie.groupes;
    }

    public onRowEditCancel(taxonomie: TaxonomieRaw): void {
        this.displayRowsActions(true);
        delete this.taxoTable.editingRowKeys[taxonomie.id!];

        if (taxonomie.id === this.idNew) {
            this.removeUnsavedTaxonomie(taxonomie);
        } else {
            this.applyOriginalData();
        }

        this.mainFilter(this.groupeSelectedValue);
        this.editDisabledAddButton = false;
        this.disableGlobalFilter = false;
    }

    public onRowEditSave(taxonomie: TaxonomieRaw): void {
        this.displayRowsActions(true);
        this.editDisabledAddButton = false;
        this.disableGlobalFilter = false;
        delete this.taxoTable.editingRowKeys[taxonomie.id!];

        const isFirme = taxonomie.groupes.some((groupe: string) => groupe === this.taxonomieGroupeValue.FIRME || groupe === this.taxonomieGroupeValue.FIRME_AUDIT);

        if (isFirme && taxonomie.proprietesJson?.firmeID !== '') {
            taxonomie.proprietes = '';
            taxonomie.proprietesJson.firmeID = '';
        }

        if (taxonomie.proprietesJson && taxonomie.proprietesJson.firmeID !== '') {
            taxonomie.proprietes = JSON.stringify(taxonomie.proprietesJson);
        }

        if (!isFirme) {
            taxonomie.code = taxonomie.code.trim().toLowerCase();
        }

        taxonomie.id = taxonomie.id === this.idNew ? '' : taxonomie.id;

        const { proprietesJson, ...currentTaxo } = taxonomie;

        const taxo = {
            ...currentTaxo,
            groupes: this.selectedGroupes,
        } as Taxonomie;

        if ((taxo as Taxonomie).id !== '') {
            this.store.dispatch(updateOneTaxonomie({ taxonomie: taxo }));
        } else {
            const { id, proprietes, ...base } = taxo;

            if (isFirme) {
                this.store.dispatch(createOneTaxonomie({ taxonomie: base }));
            } else {
                const empOrInsp = {
                    ...base,
                    firmeId: proprietesJson?.firmeID || '',
                };

                this.store.dispatch(createOneTaxonomie({ taxonomie: empOrInsp }));
            }
        }
    }

    public onDelete(taxonomie: TaxonomieRaw): void {
        this.displayRowsActions(true);
        this.editDisabledAddButton = false;
        this.disableGlobalFilter = false;

        if (taxonomie.id !== undefined && taxonomie.id !== null && taxonomie.id !== '') {
            const { proprietesJson, ...taxo } = taxonomie;
            this.store.dispatch(deleteOneTaxonomie({ taxonomie: taxo }));
        }
    }

    public confirmDelete(taxonomie: TaxonomieRaw): void {
        this.confirmationService.confirm({
            message: taxonomie.id !== this.idNew && taxonomie.code !== ''
                ? `Voulez-vous vraiment supprimer le code ${taxonomie.code} ?`
                : `Voulez-vous vraiment supprimer cette taxonomie ?`,
            accept: () => {
                this.onDelete(taxonomie);
            },
            key: 'deleteConfirm'
        });
    }

    private isTaxonomieEdited(editedTaxonomie: any): boolean {
        return editedTaxonomie.code !== this.originalNonEditedValues.code
            || editedTaxonomie.estActif !== this.originalNonEditedValues.estActif
            || editedTaxonomie.groupes !== this.originalNonEditedValues.groupes
            || editedTaxonomie.proprietesJson.firmeID !== this.originalNonEditedValues.proprietesJson.firmeID;
    }

    public confirmCancelMAJ(taxonomie: TaxonomieRaw): void {
        if (this.isTaxonomieEdited(taxonomie)) {
            this.confirmationService.confirm({
                message: `Voulez-vous vraiment annuler les modifications ?`,
                accept: () => {
                    this.onRowEditCancel(taxonomie);
                },
                reject: () => {
                    this.enableEditRow(taxonomie, false);
                },
                key: 'updateConfirm'
            });
        } else {
            this.onRowEditCancel(taxonomie);
        }
    }

    public valueAreInvalide(taxonomie: TaxonomieRaw): boolean {
        if (taxonomie.groupes.some((groupe: string) => groupe === this.taxonomieGroupeValue.FIRME || groupe === this.taxonomieGroupeValue.FIRME_AUDIT)) {
            return taxonomie.code === '';
        } else {
            return taxonomie.code === '' || (taxonomie.proprietesJson !== undefined && taxonomie.proprietesJson.firmeID === '');
        }
    }

    public canDelete(taxonomie: TaxonomieRaw): boolean {
        if (taxonomie.groupes.some((groupe: string) => groupe === this.taxonomieGroupeValue.FIRME || groupe === this.taxonomieGroupeValue.FIRME_AUDIT)) {
            return this.allOriginalRawData.filter((taxo: TaxonomieRaw) => taxo.proprietesJson?.firmeID === taxonomie.id).length === 0;
        } else {
            return true;
        }
    }

    public onSelectedGroupes(event: any) {
        if (this.selectedGroupes.length > 0) {
            const isFirmeOrAudit = [this.taxonomieGroupeValue.FIRME, this.taxonomieGroupeValue.FIRME_AUDIT].includes(event.itemValue);
            const hasFirmeOrAudit = this.selectedGroupes.some(selectedGroupe => [this.taxonomieGroupeValue.FIRME as string, this.taxonomieGroupeValue.FIRME_AUDIT]
                .includes(selectedGroupe));

            this.selectedGroupes = isFirmeOrAudit || hasFirmeOrAudit ? [event.itemValue] : event.value;
        } else {
            this.selectedGroupes = event.value;
        }
    }

    public onSelectedGroupesFilter(event: any, field: string) {
        this.taxoTable.filter(event.value, field, this.customGroupesFilter);
    }
}
