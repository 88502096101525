import { Component, Input, OnInit } from '@angular/core';
import { ComponentBase } from '@ngxhq/common-ui';
import { Store } from '@ngrx/store';
import { logoutUser } from '../../../core/store/actions/user.actions';
import { LocalStorageIndex } from '../../../shared/enums/local-storage-index.enum';
import * as sharedActions from '../../../state/shared/shared.actions';
import { LocalStorageService } from '../../../services/local-storage.service';
import { combineLatest, filter, map, Observable, take, takeUntil } from 'rxjs';
import { IndexedDbService } from '../../../features/offline/services/indexed-db.service';
import { UserRoleValue } from '../../../shared/models/user-roles.model';
import { SelectItem } from 'primeng/api';
import { UserInformation } from '../../../shared/models/user-informations.model';
import { AppConfiguration } from '../../../shared/config/app-config.model';
import {
    selectIdentiteUtilisateur,
    selectUtilisateurActiveRoles,
    selectUtilisateurFirmeName,
    selectUtilisateurIsMobile,
    selectUtilisateurRoles
} from '../../../state/shared/shared.selectors';
import { NetworkService } from '../../../services/network.service';

@Component({
    selector: 'app-user-identity',
    templateUrl: './user-identity.component.html',
    styleUrls: ['./user-identity.component.scss'],
})
export class UserIdentityComponent extends ComponentBase implements OnInit {
    public firmeName: string = '';
    public selectedRole: string;
    public environmentName: string;

    public get requestStackSize(): number {
        return this.indexedDbService.requestStackSize;
    }

    get isNetworkOnline() {
        return this.networkService.isOnline;
    }

    public utilisateurActiveRole: string;
    public utilisateurIsMobile: boolean = false;
    public identiteUtilisateur: UserInformation;
    public selectUtilisateurActiveRoles$: Observable<string[]> = this.store.select(selectUtilisateurActiveRoles);
    public selectUtilisateurIsMobile$: Observable<boolean> = this.store.select(selectUtilisateurIsMobile);
    public options = this.store.select(selectUtilisateurRoles).pipe(
        filter(roles => !!roles),
        map(roles => this.populateOptions(roles)),
        takeUntil(this.destroyed),
    );

    @Input() public set callResetRole(value: boolean) {
        if (value) {
            this.selectedRole = this.utilisateurActiveRole;
        }
    }

    constructor(
        private store: Store,
        private localStorageService: LocalStorageService,
        private indexedDbService: IndexedDbService,
        private configs: AppConfiguration,
        private networkService: NetworkService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.setUpEnvironnement();
        this.subscribeToSelectUtilisateurActiveRoles();
        this.subscribeToSelectUtilisateurIsMobile();
        this.subscibeToSelectIdentiteUtilisateur();
        this.subscribeToSelectUtilisateurFirmeName();
    }

    private setUpEnvironnement() {
        this.environmentName = this.configs ? this.configs.environment : '';
    }

    private subscribeToSelectUtilisateurActiveRoles() {
        this.selectUtilisateurActiveRoles$
            .pipe(
                takeUntil(this.destroyed),
            ).subscribe((roles: string[]) => {
                if (roles.length > 0) {
                    if (roles && roles.length === 1) {
                        this.utilisateurActiveRole = roles[0];
                        this.selectedRole = this.utilisateurActiveRole;
                    }
                }
            });
    }

    private subscribeToSelectUtilisateurIsMobile() {
        this.selectUtilisateurIsMobile$
            .pipe(
                takeUntil(this.destroyed)
            ).subscribe((utilisateurIsMobile: boolean) => {
                this.utilisateurIsMobile = utilisateurIsMobile;
            });
    }

    private subscibeToSelectIdentiteUtilisateur() {
        this.store.select(selectIdentiteUtilisateur)
            .pipe(
                takeUntil(this.destroyed),
            ).subscribe((identiteUtilisateur) => {
                if (identiteUtilisateur) {
                    this.identiteUtilisateur = identiteUtilisateur;
                }
            });
    }

    private subscribeToSelectUtilisateurFirmeName() {
        this.store.select(selectUtilisateurFirmeName)
            .pipe(
                takeUntil(this.destroyed)
            ).subscribe((utilisateurFirmeName) => {
                if (utilisateurFirmeName) {
                    this.firmeName = utilisateurFirmeName;
                }
            });
    }

    private populateOptions(roles: string[]) {
        return [
            ...roles.map(role => {
                return { label: UserRoleValue[role as keyof typeof UserRoleValue], value: role };
            })
        ] as SelectItem<string>[];
    }

    public logout() {
        this.store.dispatch(logoutUser());
    }

    public selectRole(role: string, event: MouseEvent) {
        event.stopPropagation();
        if (this.requestStackSize === 0) {
            this.selectedRole = role;
        }
    }

    public cancel() {
        this.selectedRole = this.utilisateurActiveRole;
    }

    public async apply() {
        this.changeActiveRoleSuccess();
    }

    private changeActiveRoleSuccess() {
        combineLatest([this.selectUtilisateurActiveRoles$, this.selectUtilisateurIsMobile$]).pipe(
            take(1)
        ).subscribe(([utilisateurActiveRole, utilisateurIsMobile]) => {
            if (utilisateurActiveRole !== undefined && utilisateurActiveRole.length > 0 && utilisateurIsMobile) {
                this.subscribeToResetDatabase();
            } else {
                this.reloadPage();
            }
        });
    }

    public reloadPage() {
        this.localStorageService.setItem(LocalStorageIndex.ACTIVE_ROLE, this.selectedRole);
        this.store.dispatch(sharedActions.setUserActiveRole({ role: this.selectedRole }));
        document.location.reload();
    }

    private subscribeToResetDatabase() {
        this.indexedDbService.resetDatabase().pipe(
            filter(dbIsClear => !!dbIsClear),
            takeUntil(this.destroyed)
        ).subscribe((_dbIsClear: boolean) => {
            this.reloadPage();
        });
    }
}
