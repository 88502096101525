<ng-container *ngIf="numberOfItems !== null && numberOfItems !== undefined && numberOfItems > 0">
    <div *ngIf="isLoading" class="main-table-mobile-dialog__loading">
        <app-loading-spinner></app-loading-spinner>
    </div>
    <div class="data-container">
        <div class="table-container">
            <ng-content select="[table]"></ng-content>
        </div>
        <div class="main-table-mobile-dialog__info" *ngIf="selectedProjet">
            <div class="info-properties">Projet:
                <div class="info-properties__value">{{name}}</div>
            </div>
            <div class="info-properties">Statut:
                <div class="info-properties__value">{{statut}}</div>
            </div>
            <div class="info-properties">Assigné le:
                <div class="info-properties__value">{{assignDate}}
                </div>
            </div>
            <div class="info-properties">
                <div class="info-properties__label">{{propertyQuantityMessage}}</div>
                <div class="info-properties__value">{{quantity}}</div>
            </div>
            <ng-content select="[action]"></ng-content>
        </div>
    </div>
</ng-container>
<p *ngIf="numberOfItems === null || numberOfItems === undefined || numberOfItems === 0">
    <ng-content select="[emptyMessage]"></ng-content>
</p>
