import { Action, createReducer, on } from '@ngrx/store';
import * as InspectionActions from './inspection.actions';
import { InspectionState } from './inspection.state';

export const inspectionInitialState: InspectionState = {
    projetInspectionList: null,
    projetInspectionListLoading: false,
    loadProjetInspectionListError: null,
    projetInspection: null,
    projetInspectionError: null,
    createProjetInspection: null,
    createProjetInspectionLoading: false,
    createProjetInspectionSuccess: false,
    createProjetInspectionError: null,
    assignProjetInspection: null,
    assignProjetInspectionSuccess: false,
    assignProjetInspectionError: null,
    activateProjetInspection: null,
    activateProjetInspectionSuccess: false,
    activateProjetInspectionError: null,
    currentActiveProjetInspection: null,
    selectedPointInspection: null,
    cancelProjetInspection: null,
    cancelProjetInspectionSuccess: false,
    cancelProjetInspectionError: null,
    rejectProjetInspection: null,
    rejectProjetInspectionSuccess: false,
    rejectProjetInspectionError: null,
    completeProjetInspection: null,
    completeProjetInspectionSuccess: false,
    completeProjetInspectionError: null,
    completeProjetInspectionLoading: false,
    approveProjetInspection: null,
    approveProjetInspectionSuccess: false,
    approveProjetInspectionError: null,
    approveProjetInspectionLoading: false,
    createPointInspectionSuccess: false,
    createPointInspectionError: null,
    updatePointInspectionSuccess: false,
    updatePointInspectionError: null,
    createAnomalieInspection: null,
    createAnomalieInspectionSuccess: false,
    createAnomalieInspectionError: null,
    updateAnomalieInspection: null,
    updateAnomalieInspectionSuccess: false,
    updateAnomalieInspectionError: null,
    deleteAnomalieInspectionSuccess: false,
    deleteAnomalieInspectionError: null,
    pointsInspection: [],
    pointsInspectionLoading: false,
    loadPointsInspectionError: null,
    addPointInspectionPhotoSuccess: false,
    addPointInspectionPhotoError: null,
    addAnomalieInspectionPhotoSuccess: false,
    addAnomalieInspectionPhotoError: null,
    deletePointInspectionPhotoSuccess: false,
    deletePointInspectionPhotoError: null,
    deleteAnomalieInspectionPhotoSuccess: false,
    deleteAnomalieInspectionPhotoError: null,
};

export const inspectionReducer = createReducer<InspectionState>(
    inspectionInitialState,
    on(InspectionActions.loadProjetInspectionList, (state) => {
        return {
            ...state,
            projetInspectionList: null,
            projetInspectionListLoading: true,
            loadProjetInspectionListError: null
        };
    }),
    on(InspectionActions.loadProjetInspectionListSuccess, (state, action) => {
        return {
            ...state,
            projetInspectionList: action.projetList,
            projetInspectionListLoading: false,
            loadProjetInspectionListError: null
        };
    }),
    on(InspectionActions.loadProjetInspectionListError, (state, action) => {
        return {
            ...state,
            projetInspectionList: null,
            projetInspectionListLoading: false,
            loadProjetInspectionListError: action.error,
        };
    }),
    on(InspectionActions.getProjetInspectionById, (state) => {
        return {
            ...state,
            projetInspection: null,
            projetInspectionError: null
        };
    }),
    on(InspectionActions.getProjetInspectionByIdSuccess, (state, action) => {
        return {
            ...state,
            projetInspection: action.projetInspection,
            projetInspectionError: null
        };
    }),
    on(InspectionActions.getProjetInspectionByIdError, (state, action) => {
        return {
            ...state,
            projetInspection: null,
            projetInspectionError: action.error
        };
    }),
    on(InspectionActions.startCreateProjetInspection, (state) => {
        return {
            ...state,
            createProjetInspectionLoading: true,
            createProjetInspectionSuccess: false,
            createProjetInspectionError: null
        };
    }),
    on(InspectionActions.createProjetInspectionSuccess, (state, action) => {
        return {
            ...state,
            createProjetInspection: action.createProjetInspection,
            createProjetInspectionLoading: false,
            createProjetInspectionSuccess: true,
        };
    }),
    on(InspectionActions.createProjetInspectionError, (state, action) => {
        return {
            ...state,
            createProjetInspectionLoading: false,
            createProjetInspectionSuccess: false,
            createProjetInspectionError: action.error
        };
    }),
    on(InspectionActions.assignProjetInspection, (state) => {
        return {
            ...state,
            assignProjetInspection: null,
            assignProjetInspectionSuccess: false,
            assignProjetInspectionError: null,
        };
    }),
    on(InspectionActions.assignProjetInspectionSuccess, (state, action) => {
        return {
            ...state,
            assignProjetInspection: action.assignProjetInspection,
            assignProjetInspectionSuccess: true,
            assignProjetInspectionError: null,
        };
    }),
    on(InspectionActions.assignProjetInspectionError, (state, action) => {
        return {
            ...state,
            assignProjetInspection: null,
            assignProjetInspectionSuccess: false,
            assignProjetInspectionError: action.error,
        };
    }),
    on(InspectionActions.activateProjetInspection, (state) => {
        return {
            ...state,
            activateProjetInspection: null,
            activateProjetInspectionSuccess: false,
            activateProjetInspectionError: null,
        };
    }),
    on(InspectionActions.activateProjetInspectionSuccess, (state, action) => {
        const index = state.projetInspectionList.findIndex(projet => projet.id === action.activateProjetInspection.id);
        const projetInspectionList = [...state.projetInspectionList];
        projetInspectionList[index] = action.activateProjetInspection;

        return {
            ...state,
            projetInspectionList: projetInspectionList,
            activateProjetInspection: action.activateProjetInspection,
            activateProjetInspectionSuccess: true,
            activateProjetInspectionError: null,
        };
    }),
    on(InspectionActions.activateProjetInspectionError, (state, action) => {
        return {
            ...state,
            activateProjetInspection: null,
            activateProjetInspectionSuccess: false,
            activateProjetInspectionError: action.error,
        };
    }),
    on(InspectionActions.setCurrentActiveProjetInspection, (state, action) => {
        return {
            ...state,
            currentActiveProjetInspection: action.projetInspection,
        };
    }),
    on(InspectionActions.setCurrentActiveProjetInspectionById, (state, action) => {
        return {
            ...state,
            currentActiveProjetInspection: state.projetInspectionList?.find(projet => projet.id === action.projetInspectionId),
        };
    }),
    on(InspectionActions.setSelectedPointInspection, (state, action) => {
        return {
            ...state,
            selectedPointInspection: action.pointInspection,
        };
    }),
    on(InspectionActions.cancelProjetInspection, (state) => {
        return {
            ...state,
            cancelProjetInspection: null,
            cancelProjetInspectionSuccess: false,
            cancelProjetInspectionError: null,
        };
    }),
    on(InspectionActions.cancelProjetInspectionSuccess, (state, action) => {
        return {
            ...state,
            cancelProjetInspection: action.cancelProjetInspection,
            cancelProjetInspectionSuccess: true,
            cancelProjetInspectionError: null,
        };
    }),
    on(InspectionActions.cancelProjetInspectionError, (state, action) => {
        return {
            ...state,
            cancelProjetInspection: null,
            cancelProjetInspectionSuccess: false,
            cancelProjetInspectionError: action.error,
        };
    }),
    on(InspectionActions.rejectProjetInspection, (state) => {
        return {
            ...state,
            rejectProjetInspection: null,
            rejectProjetInspectionSuccess: false,
            rejectProjetInspectionError: null,
        };
    }),
    on(InspectionActions.rejectProjetInspectionSuccess, (state, action) => {
        return {
            ...state,
            rejectProjetInspection: action.projetInspection,
            rejectProjetInspectionSuccess: true,
            rejectProjetInspectionError: null,
        };
    }),
    on(InspectionActions.rejectProjetInspectionError, (state, action) => {
        return {
            ...state,
            rejectProjetInspection: null,
            rejectProjetInspectionSuccess: false,
            rejectProjetInspectionError: action.error,
        };
    }),
    on(InspectionActions.completeProjetInspection, (state) => {
        return {
            ...state,
            completeProjetInspection: null,
            completeProjetInspectionLoading: true,
            completeProjetInspectionSuccess: false,
            completeProjetInspectionError: null,
        };
    }),
    on(InspectionActions.completeProjetInspectionSuccess, (state, action) => {
        return {
            ...state,
            projetInspectionList: state.projetInspectionList.filter(projet => projet.id !== action.projetInspection.id),
            pointsInspection: [],
            completeProjetInspection: action.projetInspection,
            completeProjetInspectionLoading: false,
            completeProjetInspectionSuccess: true,
            completeProjetInspectionError: null,
        };
    }),
    on(InspectionActions.completeProjetInspectionError, (state, action) => {
        return {
            ...state,
            completeProjetInspection: null,
            completeProjetInspectionLoading: false,
            completeProjetInspectionSuccess: false,
            completeProjetInspectionError: action.error,
        };
    }),
    on(InspectionActions.approveProjetInspection, (state) => {
        return {
            ...state,
            approveProjetInspection: null,
            approveProjetInspectionLoading: true,
            approveProjetInspectionSuccess: false,
            approveProjetInspectionError: null,
        };
    }),
    on(InspectionActions.approveProjetInspectionSuccess, (state, action) => {
        return {
            ...state,
            approveProjetInspection: action.projetInspection,
            approveProjetInspectionLoading: false,
            approveProjetInspectionSuccess: true,
            approveProjetInspectionError: null,
        };
    }),
    on(InspectionActions.approveProjetInspectionError, (state, action) => {
        return {
            ...state,
            approveProjetInspection: null,
            approveProjetInspectionLoading: false,
            approveProjetInspectionSuccess: false,
            approveProjetInspectionError: action.error,
        };
    }),
    on(InspectionActions.createPointInspection, (state) => {
        return {
            ...state,
            createPointInspectionSuccess: false,
            createProjetInspectionError: null
        };
    }),
    on(InspectionActions.createPointInspectionSuccess, (state, action) => {
        const pointsInspection = [...state.pointsInspection, action.pointInspection];

        const projetInspectionList = state.projetInspectionList.map(projet =>
            projet.id === action.pointInspection.projetId
                ? { ...projet, pointInspections: pointsInspection }
                : projet
        );

        return {
            ...state,
            pointsInspection,
            projetInspectionList,
            selectedPointInspection: action.pointInspection,
            currentActiveProjetInspection: { ...state.currentActiveProjetInspection, pointInspections: pointsInspection },
            createPointInspectionSuccess: true,
            createProjetInspectionError: null
        };
    }),
    on(InspectionActions.createPointInspectionError, (state, action) => {
        return {
            ...state,
            createPointInspectionSuccess: false,
            createProjetInspectionError: action.error
        };
    }),
    on(InspectionActions.updatePointInspection, (state) => {
        return {
            ...state,
            updatePointInspectionSuccess: false,
            updatePointInspectionError: null
        };
    }),
    on(InspectionActions.updatePointInspectionSuccess, (state, action) => {
        return {
            ...state,
            pointsInspection: state.pointsInspection.map(point => point.id === action.pointInspection.id ? action.pointInspection : point),
            selectedPointInspection: action.pointInspection,
            updatePointInspectionSuccess: true,
            updatePointInspectionError: null
        };
    }),
    on(InspectionActions.updatePointInspectionError, (state, action) => {
        return {
            ...state,
            updatePointInspectionSuccess: false,
            updatePointInspectionError: action.error
        };
    }),
    on(InspectionActions.createAnomalieInspection, (state) => {
        return {
            ...state,
            createAnomalieInspection: null,
            createAnomalieInspectionSuccess: false,
            createAnomalieInspectionError: null
        };
    }),
    on(InspectionActions.createAnomalieInspectionSuccess, (state, action) => {
        const pointsInspection = state.pointsInspection.map(point =>
            point.id === action.anomalie.inspectionId
                ? { ...point, anomalies: [...point.anomalies, action.anomalie] }
                : point
        );

        const updatedAnomalies = [...state.selectedPointInspection.anomalies, action.anomalie];

        return {
            ...state,
            pointsInspection,
            selectedPointInspection: { ...state.selectedPointInspection, anomalies: updatedAnomalies },
            createAnomalieInspection: action.anomalie,
            createAnomalieInspectionSuccess: action.success,
            createAnomalieInspectionError: null,
        };
    }),
    on(InspectionActions.createAnomalieInspectionError, (state, action) => {
        return {
            ...state,
            createAnomalieInspection: null,
            createAnomalieInspectionSuccess: false,
            createAnomalieInspectionError: action.error
        };
    }),
    on(InspectionActions.updateAnomalieInspection, (state) => {
        return {
            ...state,
            updateAnomalieInspection: null,
            updateAnomalieInspectionSuccess: false,
            updateAnomalieInspectionError: null
        };
    }),
    on(InspectionActions.updateAnomalieInspectionSuccess, (state, action) => {
        const pointsInspection = state.pointsInspection.map(point =>
            point.id === action.anomalie.inspectionId
                ? { ...point, anomalies: point.anomalies.map(anomalie => anomalie.id === action.anomalie.id ? action.anomalie : anomalie) }
                : point
        );

        let selectedPointInspection = state.selectedPointInspection;
        if (selectedPointInspection) {
            selectedPointInspection = {
                ...selectedPointInspection,
                anomalies: state.selectedPointInspection.anomalies.map(anomalie => anomalie.id === action.anomalie.id ? action.anomalie : anomalie)
            };
        }

        return {
            ...state,
            pointsInspection,
            selectedPointInspection: selectedPointInspection,
            updateAnomalieInspection: action.anomalie,
            updateAnomalieInspectionSuccess: action.success,
            updateAnomalieInspectionError: null,
        };
    }),
    on(InspectionActions.updateAnomalieInspectionError, (state, action) => {
        return {
            ...state,
            updateAnomalieInspection: null,
            updateAnomalieInspectionSuccess: false,
            updateAnomalieInspectionError: action.error
        };
    }),
    on(InspectionActions.deleteAnomalieInspection, (state) => {
        return {
            ...state,
            deleteAnomalieInspectionSuccess: false,
            deleteAnomalieInspectionError: null
        };
    }),
    on(InspectionActions.deleteAnomalieInspectionSuccess, (state, action) => {
        return {
            ...state,
            selectedPointInspection: {
                ...state.selectedPointInspection,
                anomalies: state.selectedPointInspection.anomalies.filter(anomalie => anomalie.id !== action.anomalieId)
            },
            deleteAnomalieInspectionSuccess: action.success,
            deleteAnomalieInspectionError: null
        };
    }),
    on(InspectionActions.deleteAnomalieInspectionError, (state, action) => {
        return {
            ...state,
            deleteAnomalieInspectionSuccess: false,
            deleteAnomalieInspectionError: action.error
        };
    }),
    on(InspectionActions.clearProjetInspectionData, () => {
        return {
            ...inspectionInitialState
        };
    }),
    on(InspectionActions.loadPointsInspectionBbox, (state) => {
        return {
            ...state,
            pointsInspectionLoading: true,
            loadPointsInspectionError: null
        };
    }),
    on(InspectionActions.loadPointsInspectionBboxSuccess, (state, action) => {
        return {
            ...state,
            pointsInspection: action.pointsInspection,
            pointsInspectionLoading: false,
            loadPointsInspectionError: null
        };
    }),
    on(InspectionActions.loadPointsInspectionBboxError, (state, action) => {
        return {
            ...state,
            pointsInspection: [],
            pointsInspectionLoading: false,
            loadPointsInspectionError: action.error,
        };
    }),
    on(InspectionActions.addPointInspectionPhoto, (state) => {
        return {
            ...state,
            addPointInspectionPhotoSuccess: false,
            addPointInspectionPhotoError: null
        };
    }),
    on(InspectionActions.addPointInspectionPhotoSuccess, (state, action) => {
        return {
            ...state,
            pointsInspection: state.pointsInspection.map(point => point.id === action.pointInspection.id ? action.pointInspection : point),
            selectedPointInspection: action.pointInspection,
            addPointInspectionPhotoSuccess: true,
            addPointInspectionPhotoError: null
        };
    }),
    on(InspectionActions.addPointInspectionPhotoError, (state, action) => {
        return {
            ...state,
            addPointInspectionPhotoSuccess: false,
            addPointInspectionPhotoError: action.error
        };
    }),
    on(InspectionActions.addAnomalieInspectionPhoto, (state) => {
        return {
            ...state,
            addAnomalieInspectionPhotoSuccess: false,
            addAnomalieInspectionPhotoError: null
        };
    }),
    on(InspectionActions.addAnomalieInspectionPhotoSuccess, (state, action) => {
        const pointsInspection = state.pointsInspection?.map(point =>
            point.id === action.pointInspectionId
                ? {
                    ...point, anomalies: point.anomalies?.map(
                        anomalie => anomalie.id === action.anomalieId ? { ...anomalie, photos: [...anomalie.photos, action.photo] } : anomalie
                    )
                }
                : point
        );

        const updatedAnomalies = state.selectedPointInspection.anomalies.map(anomalie =>
            anomalie.id === action.anomalieId
                ? { ...anomalie, photos: [...anomalie.photos, action.photo] }
                : anomalie
        );

        return {
            ...state,
            pointsInspection,
            selectedPointInspection: { ...state.selectedPointInspection, anomalies: updatedAnomalies },
            addAnomalieInspectionPhotoSuccess: true,
            addAnomalieInspectionPhotoError: null
        };
    }),
    on(InspectionActions.addAnomalieInspectionPhotoError, (state, action) => {
        return {
            ...state,
            addAnomalieInspectionPhotoSuccess: false,
            addAnomalieInspectionPhotoError: action.error
        };
    }),
    on(InspectionActions.deletePointInspectionPhoto, (state) => {
        return {
            ...state,
            deletePointInspectionPhotoSuccess: false,
            deletePointInspectionPhotoError: null
        };
    }),
    on(InspectionActions.deletePointInspectionPhotoSuccess, (state, action) => {
        return {
            ...state,
            pointsInspection: state.pointsInspection.map(point => point.id === action.pointInspection.id ? action.pointInspection : point),
            deletePointInspectionPhotoSuccess: true,
            deletePointInspectionPhotoError: null
        };
    }),
    on(InspectionActions.deletePointInspectionPhotoError, (state, action) => {
        return {
            ...state,
            deletePointInspectionPhotoSuccess: false,
            deletePointInspectionPhotoError: action.error
        };
    }),
    on(InspectionActions.deleteAnomalieInspectionPhoto, (state) => {
        return {
            ...state,
            deleteAnomalieInspectionPhotoSuccess: false,
            deleteAnomalieInspectionPhotoError: null
        };
    }),
    on(InspectionActions.deleteAnomalieInspectionPhotoSuccess, (state) => {
        return {
            ...state,
            deleteAnomalieInspectionPhotoSuccess: true,
            deleteAnomalieInspectionPhotoError: null
        };
    }),
    on(InspectionActions.deleteAnomalieInspectionPhotoError, (state, action) => {
        return {
            ...state,
            deleteAnomalieInspectionPhotoSuccess: false,
            deleteAnomalieInspectionPhotoError: action.error
        };
    }),
    on(InspectionActions.getPointsActiveProjetInspection, (state) => {
        return {
            ...state,
            pointsInspectionLoading: true,
            loadPointsInspectionError: null
        };
    }),
    on(InspectionActions.getPointsActiveProjetInspectionSuccess, (state, action) => {
        return {
            ...state,
            pointsInspection: action.pointsInspection,
            selectedPointInspection: action.pointsInspection.find(point => point.id === state.selectedPointInspection?.id),
            pointsInspectionLoading: false,
            loadPointsInspectionError: null
        };
    }),
    on(InspectionActions.getPointsActiveProjetInspectionError, (state, action) => {
        return {
            ...state,
            pointsInspection: [],
            pointsInspectionLoading: false,
            loadPointsInspectionError: action.error,
        };
    }),
    on(InspectionActions.getPointsByProjetInspectionId, (state) => {
        return {
            ...state,
            pointsInspectionLoading: true,
            loadPointsInspectionError: null
        };
    }),
    on(InspectionActions.getPointsByProjetInspectionIdSuccess, (state, action) => {
        return {
            ...state,
            pointsInspection: action.pointsInspection,
            pointsInspectionLoading: false,
            loadPointsInspectionError: null
        };
    }),
    on(InspectionActions.getPointsByProjetInspectionIdError, (state, action) => {
        return {
            ...state,
            pointsInspection: [],
            pointsInspectionLoading: false,
            loadPointsInspectionError: action.error,
        };
    }),
    on(InspectionActions.clearPointsInspection, (state) => {
        return {
            ...state,
            selectedPointInspection: null,
            pointsInspection: []
        };
    })
);

export function reducer(state: any, action: Action) {
    return inspectionReducer(state, action);
}

