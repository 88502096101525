import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Update } from '@ngrx/entity';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { MessageService } from 'primeng/api';
import { interval, lastValueFrom, of, Subject } from 'rxjs';
import { catchError, concatMap, map, mergeMap, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { ProjetAction } from '../../../inspection/models/projet-action.enum';
import { Severite } from '../../../../enums/severite';
import { StatutProjet } from '../../models/statut-projet.enum';
import { StoreName } from '../../../offline/models/indexed-db-store-name.enum';
import { ActiverProjet, AssigneProjet, ModifierProjet } from '../../models';
import { CreatedProjet } from '../../models/created-projet.model';
import { Projet } from '../../models/projet.model';
import * as projetActions from './projet.actions';
import * as AuditActions from '../../../audit/state/audit.actions';
import * as OfflineActions from '../../../offline/state/offline.actions';
import * as PointInspectionActions from '../../../../core/store/actions/pointInspection.action';
import { ResultatTransfertAvisInspection, SearchResult } from '../../../../core/api/client/models';
import { ProjetService as ProjetApiService } from '../../../../core/api/client/services';
import { ProjetService } from '../../services/projet.service';
import { Store } from '@ngrx/store';
import { State } from '../../../../state/app.state';
import { getCurrentActiveProjetAudit } from '../../../audit/state/audit.selectors';
import {
    annulerProjetError,
    annulerProjetSuccess,
    onApprouveProjetSuccess,
    onModifierProjetSuccess,
    onModifierStatutProjetSuccess,
    ProjetActions,
} from './projet.actions';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import {
    ProjetsRapportValidationDialogComponent
} from '../../../projets/components/dialog/projets-rapport-validation-dialog/projets-rapport-validation-dialog.component';

@Injectable()
export class ProjetEffects {

    public fetchProjets$ = createEffect(() => this.actions$.pipe(
        ofType(ProjetActions.FETCH_PROJETS),
        switchMap(async () => {
            if (!navigator.onLine) {
                return await lastValueFrom(this.dbService.getAll<Projet>(StoreName.PROJETS));
            };
            const projets: Projet[] = [];

            const pageSize = 40;
            let pageNumber = 1;

            const firstResult = await this.fetchProjets(pageSize, pageNumber);
            let totalCount = firstResult.pagination.totalCount;
            projets.push(...firstResult.items);

            while (totalCount === pageSize) {
                pageNumber++;
                const newProjets = await this.fetchProjets(pageSize, pageNumber);
                totalCount = newProjets.pagination.totalCount;
                if (newProjets.items?.length) {
                    projets.push(...newProjets.items);
                };
            };

            return projets;
        }),
        switchMap(projets => {
            return of(projetActions.fetchProjetsSuccess({ projets }));
        }),
        catchError(async () => projetActions.fetchProjetsError())
    ));

    public assigneProjet$ = createEffect(() => this.actions$.pipe(
        ofType(ProjetActions.ASSIGNE_PROJET),
        switchMap(({ projetId, assigneData }) => this.apiService.patch<Projet, AssigneProjet>(`/projets/${projetId}`, assigneData)),
        map((projetAssigne) => projetActions.assigneProjetSuccess({ projet: projetAssigne })),
        catchError((error: unknown) => of(projetActions.assigneProjetError({ error }))),
    ));

    public activerProjet$ = createEffect(() => this.actions$.pipe(
        ofType(ProjetActions.ACTIVER_PROJET),
        switchMap(({ projetId, activerData }) => this.apiService.patch<Projet, ActiverProjet>(`/projets/${projetId}`, activerData)),
        map((projetActive) => projetActions.activerProjetSuccess({ projet: projetActive })),
        catchError(async () => projetActions.fetchProjetsError())),
    );

    public createProjet$ = createEffect(() => this.actions$.pipe(
        ofType(ProjetActions.CREATE_PROJET),
        // eslint-disable-next-line rxjs/no-unsafe-switchmap
        switchMap((action: { type: string, projet: Projet }) => {
            return this.apiService.post<CreatedProjet, Projet>('/projets', action.projet).pipe( // TODO REFACTOR USE GENERATED API CreatedProjetDto
                tap(createdProjetResponse => {
                    if (!createdProjetResponse) {
                        return of(null);
                    }
                    return projetActions.postProjetSuccess({ projet: createdProjetResponse.projetDto });
                }),
                switchMap(createdProjetResponse => {
                    const isCheckSumChanged$ = new Subject<void>();

                    const timer = interval(3000).pipe(takeUntil(isCheckSumChanged$));
                    let compteur = 0;

                    timer.pipe(tap(async () => {
                        const reponse = await lastValueFrom(this.apiService.get<string>(`/projets/${createdProjetResponse.projetDto.id}/statut`));
                        const newCheckSum = reponse;
                        compteur++;
                        if (compteur === 10 || (createdProjetResponse.checksum !== newCheckSum &&
                            (createdProjetResponse.projetDto.statut === StatutProjet.nouveau || createdProjetResponse.projetDto.statut === StatutProjet.erreur))) {
                            isCheckSumChanged$.next();
                        }
                    })).subscribe(); // Pas certain
                    return isCheckSumChanged$.pipe(switchMap(() => {
                        return this.apiService.get<Projet>(`/projets/${createdProjetResponse.projetDto.id}?expandEquipement=true`);
                    }));
                }),
                map((projet) => {
                    if (!projet) {
                        this.popupErrorMessage('Création de projet', `Une erreur est survenue lors de la création du projet`);
                        return projetActions.createProjetError();
                    } else if (projet.statut === StatutProjet.erreur) {
                        this.popupErrorMessage('Création de projet', `Erreur lors de la création du projet ${projet.nom}`);
                        return projetActions.createProjetError();
                    } else if (projet.statut === StatutProjet.enCreation) {
                        this.popupInfoMessage('Création de projet', 'La création du projet prend plus de temps qu\'à l\'habituel.  Veuillez patienter', true);
                        return projetActions.createProjetSuccess({ projet });
                    } else {
                        this.messageService.add(
                            {
                                severity: Severite.success,
                                closable: true,
                                sticky: true,
                                summary: 'Création de projet',
                                data: { projet },
                                styleClass: 'creation-projet_toast',
                                contentStyleClass: 'creation-projet_toast p-toast-message-content',
                                key: 'messageProjet'
                            });

                        return projetActions.createProjetSuccess({ projet });
                    }
                }),
                catchError((e: any) => {
                    const detail = e?.error ? e.error?.error?.message : `Une erreur est survenue lors de la création d'un projet`;
                    this.popupErrorMessage('Création de projet', detail);
                    return of({ type: ProjetActions.CREATE_PROJET_ERROR });
                })
            );
        })
    ));

    public annuleProjet$ = createEffect(() => this.actions$.pipe(
        ofType(ProjetActions.ANNULER_PROJET),
        switchMap((action: { projet: Projet, modificationData: ModifierProjet }) => {
            // action.projet.id as string, action.modificationData, 'response'
            return this.projetApiService.patchApiV1ProjetsIdResponse({ id: action.projet.id, body: action.modificationData })
                .pipe(
                    map((response: HttpResponse<any>) => {
                        this.popupSuccessMessage('Annulation de projet', `Projet annulé avec succès`);
                        const updatedProjet: Update<Projet> = { id: ((response.body as Projet).id as string), changes: (response.body as Partial<Projet>) };
                        return annulerProjetSuccess({ projet: updatedProjet });
                    })
                );
        }),
        catchError((error: any) => of(annulerProjetError({ error: error }))),
    ));

    public supprimerProjet$ = createEffect(() => this.actions$.pipe(
        ofType(ProjetActions.SUPPRIMER_PROJET),
        switchMap((action: { projet: Projet }) => {
            // action.projet.id as string, 'response'
            return this.projetApiService.deleteApiV1ProjetsIdResponse(action.projet.id)
                .pipe(
                    map((response: HttpResponse<any>) => {
                        if (response instanceof HttpResponse && response.status === 200
                            && (response.body === undefined || response.body === null || response.body === '')) {
                            return projetActions.supprimerProjetSuccess();
                        }
                        return annulerProjetError({ error: '' });
                    })
                );
        }),
        catchError((error: any) => of(annulerProjetError({ error: error }))),
    ));

    public supprimerProjetSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(ProjetActions.SUPPRIMER_PROJET_SUCCESS),
        switchMap(async () => projetActions.fetchProjets()),
    ));

    public onEnvoyerAvisSAP$ = createEffect(() => this.actions$.pipe(
        ofType(ProjetActions.ENVOYER_AVIS_PROJET),
        switchMap(({ projetId }) => this.apiService.post<Array<ResultatTransfertAvisInspection>, unknown>(`/projets/${projetId}/avis`, {})),
        map((resultatTransferAvis) => projetActions.onResultatRapportAvisSAPRecu({ rapport: resultatTransferAvis }),),
        catchError(async () => projetActions.onErreurRapportAvisSAP())
    ));

    public onErreurRapportAvisSAP$ = createEffect(() => this.actions$.pipe(
        ofType(ProjetActions.NOTIFIER_ERREUR_RAPPORT_AVIS_SAP),
        tap(() => {
            this.popupErrorMessage(`Création d'avis`, `Une erreur est survenue lors de la création d'avis.`);
        })
    ), { dispatch: false });

    public onResultatRapportAvisSapRecu$ = createEffect(() => this.actions$.pipe(
        ofType(ProjetActions.NOTIFIER_RESULTAT_RAPPORT_AVIS_SAP),
        tap((action: { rapport: Array<ResultatTransfertAvisInspection> }) => {
            if (action.rapport) {
                if (action.rapport.length === 0) {
                    this.popupInfoMessage(`Création d'avis`, `La création des avis a été exécutée. Aucun avis n'a été généré.`);
                } else if (action.rapport.some(r => r.succes === false)) {
                    const title: string = `Création d'avis`;
                    const message: string = `La création de certains avis est tombée en erreur. Veuillez consulter le rapport de création d'avis pour les détails.`;
                    this.popupAvertissementMessage(title, message, true);
                } else if (action.rapport.every(r => r.succes === true)) {
                    this.popupSuccessMessage(`Création d'avis`, `La création des avis a été exécutée avec succès.`);
                }
            }
        })
    ), { dispatch: false });

    /*******************************/
    /****  Modifier un projet  ****/
    /*******************************/
    public onModifierProjet$ = createEffect(() => this.actions$.pipe(
        ofType(ProjetActions.MODIFIER_PROJET),
        concatMap(({ projetId, modificationData }) => {
            return this.apiService.put<Projet, ModifierProjet>(`/projets/${projetId}?expandEquipement=true`, modificationData);
        }),
        map((projet) => {
            const updatedProjet: Update<Projet> = { id: projet.id as string, changes: projet };
            return onModifierProjetSuccess({ projet: updatedProjet });
        })
    ));

    public onModifierStatutProjet$ = createEffect(() => this.actions$.pipe(
        ofType(ProjetActions.MODIFIER_STATUT_PROJET),
        mergeMap((action: { projet: Projet, statut: ActiverProjet }) => {
            const askedStatut = action.statut.action;
            return this.apiService.patch<Projet, ActiverProjet>(`/projets/${action.projet.id}?expandEquipement=true`, action.statut).pipe(
                map((backendProjet) => {
                    const updatedProjet: Update<Projet> = { id: backendProjet.id as string, changes: backendProjet };
                    return onModifierStatutProjetSuccess({ projet: updatedProjet, projetOriginal: action.projet, askedStatut: askedStatut });
                }),
                catchError((error: any) => {
                    if (error.status === 409) {
                        this.ref = this.dialogService.open(ProjetsRapportValidationDialogComponent,
                            {
                                header: 'Erreur de validation',
                                width: '100%',
                                height: '100%',
                                modal: false,
                                styleClass: 'mobile-dialog',
                                data: { projetId: action.projet.id }
                            });
                    }

                    return of(projetActions.onModifierStatutProjetError({ error, projetOriginal: action.projet, askedStatut }));
                }),
            );
        }),
    ));

    public onModifierStatutProjetSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(ProjetActions.MODIFIER_STATUT_PROJET_SUCCESS),
        tap((action: { projet: Update<Projet>, projetOriginal: Projet, askedStatut: ProjetAction }) => {
            let title: string = '';
            let message: string = '';
            if (action.projet.changes.statut && action.projet.changes.statut !== action.projetOriginal.statut) {
                switch (action.askedStatut) {
                    case ProjetAction.ACTIVER:
                    case ProjetAction.ACTIVER_AQ: {
                        title = 'Activer un projet';
                        message = `Le projet ${action.projetOriginal.nom} a été activé avec succès`;
                    } break;
                    case ProjetAction.COMPLETER:
                    case ProjetAction.COMPLETER_AQ: {
                        title = 'Compléter un projet';
                        message = `Le projet ${action.projetOriginal.nom} a été complété avec succès`;
                    } break;
                    case ProjetAction.VALIDER: {
                        title = 'Valider un projet';
                        message = `Le projet ${action.projetOriginal.nom} a été validé avec succès`;
                    } break;
                }
                this.popupSuccessMessage(title, message);
            } else {
                switch (action.askedStatut) {
                    case ProjetAction.ACTIVER:
                    case ProjetAction.ACTIVER_AQ: {
                        title = 'Activer un projet';
                        message = `Le projet ${action.projetOriginal.nom} n'a pas été validé. Veuillez contacter votre administrateur`;
                    } break;
                    case ProjetAction.COMPLETER:
                    case ProjetAction.COMPLETER_AQ: {
                        title = 'Compléter un projet';
                        message = `Le projet ${action.projetOriginal.nom} n'a pas été complété. Veuillez contacter votre administrateur`;
                    } break;
                    case ProjetAction.VALIDER: {
                        title = 'Valider un projet';
                        message = `Le projet ${action.projetOriginal.nom} n'a pas été validé. Veuillez contacter votre administrateur`;
                    } break;
                }
                this.popupErrorMessage(title, message);
            }
        }),
    ), { dispatch: false });

    public onModifierStatutProjetError$ = createEffect(() => this.actions$.pipe(
        ofType(ProjetActions.MODIFIER_STATUT_PROJET_ERROR),
        tap((action: { error: any, projetOriginal: Projet, askedStatut: ProjetAction }) => {
            let title = '';
            let message = '';

            switch (action.askedStatut) {
                case ProjetAction.ACTIVER: {
                    title = 'Activer un projet';
                    message = `Le projet ${action.projetOriginal.nom} n'a pas été validé. Veuillez contacter votre administrateur`;
                    break;
                }
                case ProjetAction.COMPLETER: {
                    title = 'Compléter un projet';
                    message = `Le projet ${action.projetOriginal.nom} n'a pas été complété. Veuillez contacter votre administrateur`;
                    break;
                }
                case ProjetAction.VALIDER: {
                    if (action.error.status !== 409) {
                        title = 'Valider un projet';
                        message = `Le projet ${action.projetOriginal.nom} n'a pas été validé. Veuillez contacter votre administrateur`;

                    }
                    return;  //  Quitter plus tôt pour éviter le message d'erreur popupErrorMessage de VALIDER cas 409
                }
            }
            this.popupErrorMessage(title, message);
        })
    ), { dispatch: false });

    /*******************************/
    /****  Approuver un projet  ****/
    /*******************************/
    public onApprouveProjet$ = createEffect(() => this.actions$.pipe(
        ofType(ProjetActions.APPROUVER_PROJET),
        mergeMap((action: { projet: Projet, statut: any }) => {
            return this.apiService.patch<Projet, ActiverProjet>(`/projets/${action.projet.id}?expandEquipement=true`, action.statut).pipe(
                map((projet) => {
                    const updatedProjet: Update<Projet> = { id: projet.id as string, changes: projet };
                    return onApprouveProjetSuccess({ projet: updatedProjet });
                }),
                catchError((error: any) => of(projetActions.onApprouveProjetError({ error }))),
            );
        }),
    ));

    public onApprouveProjetSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(ProjetActions.APPROUVER_PROJET_SUCCESS),
        tap((action: { projet: Update<Projet> }) => {
            this.popupSuccessMessage('Approuver un projet', `Le projet ${action.projet.changes.nom} a été approuvé avec succès`);
        })
    ), { dispatch: false });

    public onApprouveProjetError$ = createEffect(() => this.actions$.pipe(
        ofType(ProjetActions.APPROUVER_PROJET_ERROR),
        tap(() => {
            const details = `Le projet n'a pas été approuvé. Veuillez contacter votre administrateur`;
            this.popupErrorMessage('Approuver un projet', details);
        })
    ), { dispatch: false });

    /*******************************/
    /****  Rejeter un projet  ****/
    /*******************************/
    public onRejeterProjet$ = createEffect(() => this.actions$.pipe(
        ofType(ProjetActions.REJETER_PROJET),
        mergeMap((action: { projet: Projet, rejeterData: ModifierProjet }) => {
            return this.apiService.patch<Projet, ModifierProjet>(`/projets/${action.projet.id}?expandEquipement=true`, action.rejeterData).pipe(
                map((projet) => {
                    const updatedProjet: Update<Projet> = { id: projet.id as string, changes: projet };
                    return projetActions.rejeterProjetSuccess({ projet: updatedProjet });
                }),
                catchError((error: any) => of(projetActions.rejeterProjetError({ error }))),
            );
        }),
    ));

    public onRejeterProjetSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(ProjetActions.REJETER_PROJET_SUCCESS),
        tap((action: { projet: Update<Projet> }) => {
            this.popupSuccessMessage('Rejeter un projet', `Le projet ${action.projet.changes.nom} a été rejeté avec succès`);
        })
    ), { dispatch: false });

    public onRejeterProjetError$ = createEffect(() => this.actions$.pipe(
        ofType(ProjetActions.REJETER_PROJET_ERROR),
        tap(() => {
            const details = `Le projet n'a pas été rejeté. Veuillez contacter votre administrateur`;
            this.popupErrorMessage('Rejeter un projet', details);
        })
    ), { dispatch: false });

    updateProjetToIndexedDb$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(projetActions.updateProjetToIndexedDb),
            withLatestFrom(this.store.select(getCurrentActiveProjetAudit)),
            mergeMap(([_, currentActiveProjetAudit]) => {
                return this.projetService.getProjet({ id: currentActiveProjetAudit.projetId }).pipe(
                    mergeMap(projet => {
                        return [
                            PointInspectionActions.setPointsInspectionSuccess({ pointsInspection: projet.pointInspections as any || [] }),
                            OfflineActions.updateProjetToIndexedDb({ projet })
                        ];
                    }),
                    catchError((error: unknown) => of(AuditActions.getPointsActiveProjetAuditError({ error })))
                );
            })
        );
    });

    constructor(
        private actions$: Actions,
        private store: Store<State>,
        private messageService: MessageService,
        private apiService: ApiService,
        private dbService: NgxIndexedDBService,
        private projetApiService: ProjetApiService,
        private projetService: ProjetService,
        public ref: DynamicDialogRef,
        private readonly dialogService: DialogService,
    ) { };

    private fetchProjets(pageSize: number, pageNumber: number): Promise<SearchResult> {
        return lastValueFrom(this.apiService.get<SearchResult>(`/projets?pageSize=${pageSize}&pageNumber=${pageNumber}`));
    };

    private popupSuccessMessage(title: string, message: string) {
        this.messageService.add(
            {
                severity: Severite.success,
                closable: true,
                summary: title,
                detail: message
            });
    }

    private popupErrorMessage(title: string, message: string) {
        this.messageService.add(
            {
                severity: Severite.erreur,
                closable: true,
                summary: title,
                detail: message,
                sticky: true,
            });
    }

    private popupAvertissementMessage(title: string, message: string, sticky: boolean = true) {
        this.messageService.add(
            {
                severity: Severite.avertissement,
                closable: true,
                summary: title,
                detail: message,
                sticky: sticky,
            });
    }

    private popupInfoMessage(title: string, message: string, sticky: boolean = false) {
        this.messageService.add({
            severity: Severite.info,
            closable: true,
            summary: title,
            detail: message,
            sticky: sticky,
        });
    }
}
