import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { PointInspectionDto } from '../../../core/api/client/models';
import { selectAnomaliesPilotage } from '../../../state/shared/shared.selectors';
import { Severite } from '../../../enums/severite';
import { OfflineService } from '../services/offline.service';
import { PhotoService } from '../../../services/photo.service';
import { State } from '../../../state/app.state';
import * as OfflineActions from './offline.actions';
import * as AuditActions from '../../audit/state/audit.actions';
import * as InspectionActions from '../../inspection/state/inspection.actions';
import * as PhotosActions from '../../../shared/photos/state/photos.actions';
import * as SharedActions from '../../../state/shared/shared.actions';
import { getCurrentActiveProjetAudit } from '../../audit/state/audit.selectors';
import {
    filterDeletedAnomalies,
    getCurrentActiveProjetInspection
} from '../../inspection/state/inspection.selectors';
import { StatutProjetsInspection } from '../../projets/models/statut-projets-inspection.enum';

@Injectable()
export class OfflineEffects {
    loadProjetInspectionList$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(OfflineActions.loadProjetInspectionList),
            mergeMap(() =>
                this.offlineService.getProjetsDownloaded()
                    .pipe(
                        map(projetInspectionList => InspectionActions.loadProjetInspectionListSuccess({ projetList: projetInspectionList })),
                        catchError((error: unknown) => of(InspectionActions.loadProjetInspectionListError({ error })))
                    )));
    });

    loadProjetAuditList$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(OfflineActions.loadProjetAuditList),
            mergeMap(() =>
                this.offlineService.getProjetsAuditDownloaded()
                    .pipe(
                        map(projetAuditList => AuditActions.loadProjetAuditListSuccess({ projetAuditList })),
                        catchError((error: unknown) => of(AuditActions.loadProjetAuditListError({ error })))
                    )));
    });

    updatePointInspection$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(OfflineActions.updatePointInspection),
            mergeMap(action =>
                this.offlineService.updatePointInspection(action.pointInspection, action.poteauAction)
                    .pipe(
                        mergeMap(pointInspection => {
                            return [
                                InspectionActions.updatePointInspectionSuccess({ pointInspection }),
                                OfflineActions.getPointsActiveProjetInspection()
                            ];
                        }),
                        catchError((error: unknown) => of(InspectionActions.updatePointInspectionError({ error })))
                    )));
    });

    updatePointAudit$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(OfflineActions.updatePointAudit),
            mergeMap(action => {
                return this.offlineService.updatePointAudit(action.projetAudit, action.pointAudit).pipe(
                    mergeMap(pointAudit => {
                        return [
                            AuditActions.updatePointAuditSuccess({ pointAudit }),
                            OfflineActions.getPointsActiveProjetInspectionForAudit(),
                            OfflineActions.getPointsActiveProjetAudit(),
                        ];
                    }),
                    catchError((error: unknown) => of(AuditActions.updatePointAuditError({ error })))
                );
            })
        );
    });

    createPointInspection$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(OfflineActions.createPointInspection),
            concatMap(action =>
                this.offlineService.createPointInspection(action.pointInspection)
                    .pipe(
                        mergeMap((pointInspection) => {
                            return [
                                InspectionActions.createPointInspectionSuccess({ pointInspection }),
                                OfflineActions.getPointsActiveProjetInspection()
                            ];
                        }),
                        catchError((error: any) => {
                            if (error.status !== 404) {
                                this.messageService.add(
                                    {
                                        severity: 'error',
                                        summary: `Création de poteau`,
                                        detail: `Une erreur est survenue lors de la création de poteau.`
                                    }
                                );
                            }

                            return of(InspectionActions.createPointInspectionError({ error }));
                        })
                    )));
    });

    createPointAudit$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(OfflineActions.createPointAudit),
            concatMap(action =>
                this.offlineService.createPointAudit(action.pointInspection)
                    .pipe(
                        mergeMap((pointAudit) => {
                            return [
                                AuditActions.createPointAuditSuccess({ pointAudit }),
                                OfflineActions.getPointsActiveProjetAudit(),
                                OfflineActions.getPointsActiveProjetInspectionForAudit()
                            ];
                        }),
                        catchError((error: unknown) => of(AuditActions.createPointAuditError({ error })))
                    )));
    });

    createAnomalieInspection$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(OfflineActions.createAnomalieInspection),
            withLatestFrom(this.store.select(selectAnomaliesPilotage)),
            mergeMap(([action, anomaliePilotage]) =>
                this.offlineService.createAnomalieInspection(action.pointInspection, action.anomalie, anomaliePilotage)
                    .pipe(
                        mergeMap((anomalie) => {
                            return [
                                InspectionActions.createAnomalieInspectionSuccess({ anomalie, success: true }),
                                OfflineActions.getPointsActiveProjetInspection()
                            ];
                        }),
                        catchError((error: any) => {
                            if (error.status !== 404) {
                                this.messageService.add(
                                    {
                                        severity: 'error',
                                        summary: `Création d'anomalie`,
                                        detail: `Une erreur est survenue lors de la création d'anomalie.`
                                    }
                                );
                            }

                            return of(InspectionActions.createAnomalieInspectionError({ error }));
                        })
                    )));
    });

    updateAnomalieInspection$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(OfflineActions.updateAnomalieInspection),
            withLatestFrom(this.store.select(selectAnomaliesPilotage)),
            mergeMap(([action, anomaliePilotage]) =>
                this.offlineService.updateAnomalieInspection(action.pointInspection, action.anomalie, anomaliePilotage)
                    .pipe(
                        mergeMap((anomalie) => {
                            return [
                                InspectionActions.updateAnomalieInspectionSuccess({ anomalie, success: true }),
                                OfflineActions.getPointsActiveProjetInspection()
                            ];
                        }),
                        catchError((error: unknown) => of(InspectionActions.updateAnomalieInspectionError({ error })))
                    )));
    });

    addPointAudit$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(OfflineActions.addPointAudit),
            concatMap(action => {
                return this.offlineService.addPointAudit(action.projetAudit, action.pointAudit).pipe(
                    mergeMap((pointAudit) => {
                        return [
                            AuditActions.addPointAuditSuccess({ pointAudit }),
                            OfflineActions.getPointsActiveProjetAudit(),
                            OfflineActions.getPointsActiveProjetInspectionForAudit()
                        ];
                    }),
                    catchError((error: unknown) => of(AuditActions.addPointAuditError({ error })))
                );
            })
        );
    });

    addPointAuditPhoto$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(OfflineActions.addPointAuditPhoto),
            concatMap(action => {
                const photoFile = this.photoService.blobToFile(action.photo.data, action.photo.nomOriginal);

                return this.offlineService.addPointAuditPhoto(action.pointAudit, action.photo, photoFile)
                    .pipe(
                        mergeMap((pointAudit) => {

                            return [
                                AuditActions.addPointAuditPhotoSuccess({ pointAudit, photo: action.photo }),
                                PhotosActions.addPhotoToIndexedDb({ photo: action.photo }),
                                OfflineActions.getPointsActiveProjetAudit()
                            ];
                        }),
                        catchError((error: unknown) => of(AuditActions.addPointAuditPhotoError({ error })))
                    );
            })

        );
    });

    addPointInspectionPhoto$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(OfflineActions.addPointInspectionPhoto),
            concatMap(action => {
                const photoFile = this.photoService.blobToFile(action.photo.data, action.photo.nomOriginal);

                return this.offlineService.addPointInspectionPhoto(action.pointInspection, action.photo, photoFile)
                    .pipe(
                        mergeMap((pointInspection) => {
                            return [
                                InspectionActions.addPointInspectionPhotoSuccess({ pointInspection }),
                                PhotosActions.addPhotoToIndexedDb({ photo: action.photo }),
                                OfflineActions.getPointsActiveProjetInspection()
                            ];
                        }),
                        catchError((error: unknown) => of(InspectionActions.addPointInspectionPhotoError({ error })))
                    );
            })
        );
    });

    addAnomalieAuditPhoto$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(OfflineActions.addAnomalieAuditPhoto),
            concatMap(action => {
                const photoFile = this.photoService.blobToFile(action.photo.data, action.photo.nomOriginal);

                return this.offlineService.addAnomalieAuditPhoto(action.pointAudit, action.anomalieAuditId, action.photo, photoFile)
                    .pipe(
                        mergeMap(() => {
                            return [
                                AuditActions.addAnomalieAuditPhotoSuccess(
                                    {
                                        pointAuditId: action.pointAudit.id,
                                        anomalieAuditId: action.anomalieAuditId,
                                        photo: action.photo
                                    }
                                ),
                                PhotosActions.addPhotoToIndexedDb({ photo: action.photo }),
                                OfflineActions.getPointsActiveProjetAudit()
                            ];
                        }),
                        catchError((error: unknown) => of(AuditActions.addAnomalieAuditPhotoError({ error })))
                    );
            }));
    });

    addAnomalieInspectionPhoto$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(OfflineActions.addAnomalieInspectionPhoto),
            concatMap(action => {
                const photoFile = this.photoService.blobToFile(action.photo.data, action.photo.nomOriginal);

                return this.offlineService.addAnomalieInspectionPhoto(action.pointInspection, action.anomalieId, action.photo, photoFile)
                    .pipe(
                        mergeMap(() => {
                            return [
                                InspectionActions.addAnomalieInspectionPhotoSuccess(
                                    {
                                        pointInspectionId: action.pointInspection.id,
                                        anomalieId: action.anomalieId,
                                        photo: action.photo
                                    }
                                ),
                                PhotosActions.addPhotoToIndexedDb({ photo: action.photo }),
                                OfflineActions.getPointsActiveProjetInspection()
                            ];
                        }),
                        catchError((error: unknown) => of(InspectionActions.addAnomalieInspectionPhotoError({ error })))
                    );
            }
            ));
    });

    deletePointAuditPhoto$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(OfflineActions.deletePointAuditPhoto),
            concatMap(action =>
                this.offlineService.deletePointAuditPhoto(action.pointAudit, action.photo)
                    .pipe(
                        mergeMap(() => {
                            return [
                                AuditActions.deletePointAuditPhotoSuccess({ pointAudit: action.pointAudit, photo: action.photo }),
                                OfflineActions.getPointsActiveProjetAudit()
                            ];
                        }),
                        catchError((error: unknown) => of(AuditActions.deletePointAuditPhotoError({ error })))
                    )));
    });

    deletePointInspectionPhoto$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(OfflineActions.deletePointInspectionPhoto),
            concatMap(action =>
                this.offlineService.deletePointInspectionPhoto(action.pointInspection, action.photo)
                    .pipe(
                        mergeMap(pointInspection => {
                            return [
                                InspectionActions.deletePointInspectionPhotoSuccess({ pointInspection }),
                                OfflineActions.getPointsActiveProjetInspection()
                            ];
                        }),
                        catchError((error: unknown) => of(InspectionActions.deletePointInspectionPhotoError({ error })))
                    )));
    });

    deleteAnomalieAuditPhoto$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(OfflineActions.deleteAnomalieAuditPhoto),
            concatMap(action =>
                this.offlineService.deleteAnomalieAuditPhoto(action.pointAudit, action.anomalieAuditId, action.photo)
                    .pipe(
                        mergeMap(() => {
                            return [
                                AuditActions.deleteAnomalieAuditPhotoSuccess({
                                    pointAudit: action.pointAudit,
                                    anomalieAuditId: action.anomalieAuditId,
                                    photo: action.photo
                                }),
                                OfflineActions.getPointsActiveProjetAudit()
                            ];
                        }),
                        catchError((error: unknown) => of(AuditActions.deleteAnomalieAuditPhotoError({ error })))
                    )));
    });

    deleteAnomalieInspectionPhoto$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(OfflineActions.deleteAnomalieInspectionPhoto),
            concatMap(action =>
                this.offlineService.deleteAnomalieInspectionPhoto(action.pointInspection, action.anomalieId, action.photo)
                    .pipe(
                        mergeMap(() => {
                            return [
                                InspectionActions.deleteAnomalieInspectionPhotoSuccess(),
                                OfflineActions.getPointsActiveProjetInspection()
                            ];
                        }),
                        catchError((error: unknown) => of(InspectionActions.deleteAnomalieInspectionPhotoError({ error })))
                    )));
    });

    deleteAnomalieInspection$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(OfflineActions.deleteAnomalieInspection),
            concatMap(action =>
                this.offlineService.deleteAnomalieInspection(action.pointInspection, action.anomalieId)
                    .pipe(
                        mergeMap(() => {
                            return [
                                InspectionActions.deleteAnomalieInspectionSuccess({ anomalieId: action.anomalieId, success: true }),
                                OfflineActions.getPointsActiveProjetInspection()
                            ];
                        }),
                        catchError((error: unknown) => of(InspectionActions.deleteAnomalieInspectionError({ error })))
                    )));
    });

    loadPointsAuditBbox$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(OfflineActions.loadPointsAuditBbox),
            withLatestFrom(this.store.select(getCurrentActiveProjetAudit)),
            mergeMap(([action, currentActiveProjetAudit]) =>
                this.offlineService.getPointsAudit(currentActiveProjetAudit?.id)
                    .pipe(
                        map(pointsAudit => AuditActions.loadPointsAuditBboxSuccess({ bbox: action.bbox, pointsAudit })),
                        catchError((error: unknown) => of(AuditActions.loadPointsAuditBboxError({ bbox: action.bbox, error })))
                    )));
    });

    loadPointsInspectionBbox$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(OfflineActions.loadPointsInspectionBbox),
            withLatestFrom(this.store.select(getCurrentActiveProjetInspection)),
            mergeMap(([action, currentActiveProjetInspection]) =>
                this.offlineService.getPointsInspection(currentActiveProjetInspection?.id)
                    .pipe(
                        mergeMap(pointsInspection => this.filterDeletedAnomaliesPointsInspection(pointsInspection)), // TODO REFACTOR
                        map(pointsInspection => InspectionActions.loadPointsInspectionBboxSuccess({ bbox: action.bbox, pointsInspection })),
                        catchError((error: unknown) => of(InspectionActions.loadPointsInspectionBboxError({ bbox: action.bbox, error })))
                    )));
    });

    getPointsActiveProjetAudit$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(OfflineActions.getPointsActiveProjetAudit),
            withLatestFrom(this.store.select(getCurrentActiveProjetAudit)),
            mergeMap(([_, currentActiveProjetAudit]) =>
                this.offlineService.getProjetAudit(currentActiveProjetAudit?.id)
                    .pipe(
                        map(projetAudit => AuditActions.getPointsActiveProjetAuditSuccess({ pointsAudit: projetAudit.pointAudits! })),
                        catchError((error: unknown) => of(AuditActions.getPointsActiveProjetAuditError({ error })))
                    )));
    });

    getPointsActiveProjetInspectionForAudit$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(OfflineActions.getPointsActiveProjetInspectionForAudit),
            withLatestFrom(this.store.select(getCurrentActiveProjetAudit)),
            mergeMap(([_, currentActiveProjetAudit]) =>
                this.offlineService.getProjetInspection(currentActiveProjetAudit?.projetId)
                    .pipe(
                        map(projet => InspectionActions.getPointsActiveProjetInspectionSuccess({ pointsInspection: projet.pointInspections })),
                        catchError((error: unknown) => of(InspectionActions.getPointsActiveProjetInspectionError({ error })))
                    )));
    });

    getPointsActiveProjetInspection$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(OfflineActions.getPointsActiveProjetInspection),
            withLatestFrom(this.store.select(getCurrentActiveProjetInspection)),
            mergeMap(([_, currentActiveProjetInspection]) =>
                this.offlineService.getProjetInspection(currentActiveProjetInspection?.id)
                    .pipe(
                        map(projetInspection => InspectionActions.getPointsActiveProjetInspectionSuccess({ pointsInspection: projetInspection.pointInspections })),
                        catchError((error: unknown) => of(InspectionActions.getPointsActiveProjetInspectionError({ error })))
                    )));
    });

    addProjetToIndexedDb$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(OfflineActions.addProjetToIndexedDb),
            mergeMap((action) => {
                return this.offlineService.addProjetToIndexedDb(action.projetId)
                    .pipe(
                        mergeMap(projet => {
                            const actions: Action[] = [
                                OfflineActions.addProjetToIndexedDbSuccess({ projet }),
                                InspectionActions.getPointsActiveProjetInspectionSuccess({ pointsInspection: projet.pointInspections }),
                                PhotosActions.cachePointInspectionPhotos({ projetId: projet.id }),
                                PhotosActions.cacheAnomaliesPhotos({ projetId: projet.id }),
                            ];

                            if (projet.statut === StatutProjetsInspection.correctionEnCours) {
                                const pointInspection = projet.pointInspections.find(p => p.pointsAudit && p.pointsAudit.length > 0);
                                const projetAuditId = pointInspection.pointsAudit[0].projetAuditId;

                                actions.push(
                                    PhotosActions.cachePointAuditPhotos({ projetAuditId }),
                                    PhotosActions.cacheAnomaliesAuditPhotos({ projetAuditId }),
                                );
                            }

                            return actions;

                        }),
                        catchError((error: unknown) => of(OfflineActions.addProjetToIndexedDbError({ error })))
                    );
            }
            ));
    });

    addProjetAuditToIndexedDb$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(OfflineActions.addProjetAuditToIndexedDb),
            mergeMap(action => {
                return this.offlineService.addProjetAuditToIndexedDb(action.projetAudit)
                    .pipe(
                        mergeMap(projetAudit => {
                            return [
                                OfflineActions.addProjetAuditToIndexedDbSuccess({ projetAudit }),
                                AuditActions.getPointsActiveProjetAuditSuccess({ pointsAudit: projetAudit.pointAudits }),
                                PhotosActions.cachePointAuditPhotos({ projetAuditId: projetAudit.id }),
                                PhotosActions.cacheAnomaliesAuditPhotos({ projetAuditId: projetAudit.id }),
                            ];
                        }),
                        catchError((error: unknown) => of(OfflineActions.addProjetAuditToIndexedDbError({ error })))
                    );
            }
            ));
    });

    updateProjetAuditToIndexedDb$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(OfflineActions.updateProjetAuditToIndexedDb),
            mergeMap(action => {
                return this.offlineService.updateProjetAuditToIndexedDb(action.projetAudit)
                    .pipe(
                        map(projetAudit => OfflineActions.updateProjetAuditToIndexedDbSuccess({ projetAudit })),
                        catchError((error: unknown) => of(OfflineActions.updateProjetAuditToIndexedDbError({ error })))
                    );
            }
            ));
    });

    updateProjetToIndexedDb$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(OfflineActions.updateProjetToIndexedDb),
            mergeMap(action =>
                this.offlineService.updateProjetToIndexedDb(action.projet).pipe(
                    map(projet => OfflineActions.updateProjetToIndexedDbSuccess({ projet })),
                    catchError((error: unknown) => of(OfflineActions.updateProjetToIndexedDbError({ error })))
                )
            ));
    });

    deleteProjetFromIndexedDb$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(OfflineActions.deleteProjetFromIndexedDb),
            mergeMap(action => {
                return this.offlineService.deleteProjetFromIndexedDb(action.storeName, action.projetId)
                    .pipe(
                        map(success => OfflineActions.deleteProjetFromIndexedDbSuccess({ success })),
                        catchError((error: unknown) => of(OfflineActions.deleteProjetFromIndexedDbError({ error })))
                    );
            }
            ));
    });

    deleteInspectionPhotosFromIndexedDb$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(OfflineActions.deleteInspectionPhotosFromIndexedDb),
            mergeMap(action => {
                return this.offlineService.deleteInspectionPhotosFromIndexedDb(action.projetId)
                    .pipe(
                        map(photoIds => OfflineActions.deletePhotosFromIndexedDbSuccess({ photoIds })),
                        catchError((error: unknown) => of(OfflineActions.deletePhotosFromIndexedDbError({ error })))
                    );
            }
            ));
    });

    deleteAuditPhotosFromIndexedDb$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(OfflineActions.deleteAuditPhotosFromIndexedDb),
            mergeMap(action => {
                return this.offlineService.deleteAuditPhotosFromIndexedDb(action.projetAuditId)
                    .pipe(
                        map(photoIds => OfflineActions.deletePhotosFromIndexedDbSuccess({ photoIds })),
                        catchError((error: unknown) => of(OfflineActions.deletePhotosFromIndexedDbError({ error })))
                    );
            }
            ));
    });

    getProjetsDownloaded$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(OfflineActions.getProjetsDownloaded),
            mergeMap(() => {
                return this.offlineService.getProjetsDownloaded()
                    .pipe(
                        map(projets => OfflineActions.getProjetsDownloadedSuccess({ projets })),
                        catchError((error: unknown) => of(OfflineActions.getProjetsDownloadedError({ error })))
                    );
            }
            ));
    });

    getProjetsAuditDownloaded$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(OfflineActions.getProjetsAuditDownloaded),
            mergeMap(() => {
                return this.offlineService.getProjetsAuditDownloaded()
                    .pipe(
                        map(projetsAudit => OfflineActions.getProjetsAuditDownloadedSuccess({ projetsAudit })),
                        catchError((error: unknown) => of(OfflineActions.getProjetsAuditDownloadedError({ error })))
                    );
            }
            ));
    });

    getProjetInspectionById$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(OfflineActions.getProjetInspectionById),
            mergeMap((action) => {
                return this.offlineService.getProjetInspection(action.projetId)
                    .pipe(
                        map((projetInspection) => InspectionActions.getProjetInspectionByIdSuccess({ projetInspection })),
                        catchError((error: unknown) => of(InspectionActions.getProjetInspectionByIdError({ error })))
                    );
            }
            ));
    });

    selectEsriAccessToken$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(OfflineActions.selectEsriAccessToken),
            mergeMap(() =>
                this.offlineService.selectEsriAccessToken()
                    .pipe(
                        map(esriAccessToken => SharedActions.selectEsriAccessTokenSuccess({ esriAccessToken })),
                        catchError((error: any) => {
                            if (error.status !== 404) {
                                this.messageService.add(
                                    {
                                        severity: Severite.erreur,
                                        summary: 'Erreur Token',
                                        detail: `Une erreur est survenue lors de la récupération du token Esri.`
                                    }
                                );
                            }

                            return of(SharedActions.selectEsriAccessTokenFailure({ error }));
                        })
                    )
            ));
    });

    constructor(
        private actions$: Actions,
        private store: Store<State>,
        private offlineService: OfflineService,
        private messageService: MessageService,
        private photoService: PhotoService
    ) { }

    private filterDeletedAnomaliesPointsInspection(pointsInspection: PointInspectionDto[]) { // TODO REFACTOR
        return this.store.select(filterDeletedAnomalies(pointsInspection));
    }
}
