<p-contextMenu #menu [model]="menuItems" triggerEvent="click" [target]="actionsButton"></p-contextMenu>

<app-main-table-mobile-dialog [isLoading]="projetsDownloadedLoading" [numberOfItems]="projetInspectionList?.length"
    [selectedProjet]="selectedProjetInspection" [name]="selectedProjetInspection?.nom"
    [statut]="statutProjetValue[selectedProjetInspection?.statut]"
    [assignDate]="assignationDate ? (assignationDate | date: 'dd/MM/YYYY') : null"
    [quantity]="selectedProjetInspection?.nombreTotalPoteaux" propertyQuantityMessage="Nombre total de poteaux:">
    <ng-container table>
        <p-table *ngIf="!projetsDownloadedLoading" [value]="projetInspectionList" selectionMode="single"
            [selection]="selectedProjetInspection" (selectionChange)="selectionChanged($event)" dataKey="id"
            (onRowSelect)="onRowSelect(selectedProjetInspection, menu)" (onRowUnselect)="onRowUnselect(menu)"
            [scrollable]="true" scrollHeight="flex" responsiveLayout="scroll">
            <ng-template pTemplate="header">
                <tr>
                    <th scope="col" *ngFor="let col of columns" [style.width]="col.width">
                        {{col.header}}
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData">
                    <td *ngFor="let col of columns">
                        <ng-container [ngSwitch]="col.field">
                            <ng-container *ngSwitchCase="'statut'">
                                <span *ngIf="isProjetInspectionActivated(rowData)">
                                    <i class="pi pi-check"></i>
                                </span>
                            </ng-container>
                            <ng-container *ngSwitchCase="'downloaded'">
                                <span *ngIf="isProjetInspectionDownloaded(rowData)">
                                    <i class="pi pi-check"></i>
                                </span>
                            </ng-container>
                            <ng-container *ngSwitchDefault>
                                {{rowData[col.field]}}
                            </ng-container>
                        </ng-container>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </ng-container>
    <ng-container action>
        <button #actionsButton pButton pRipple icon="pi pi-ellipsis-v"
            class="p-button-rounded p-button-secondary actions-button"
            (click)="onActionsButtonClick($event, menu, selectedProjetInspection)"></button>
    </ng-container>
    <ng-container emptyMessage>Aucun projet activé ou téléchargé.</ng-container>
</app-main-table-mobile-dialog>

<p-confirmDialog #cdDownload header="Téléchargement projet" icon="pi pi-exclamation-triangle" appendTo="body"
    key='downloadProjetInspectionConfirm'>
    <ng-template pTemplate="footer">
        <button type="button" pButton icon="pi pi-times" class="p-button-text p-mr-4" label="Annuler"
            (click)="cdDownload.reject()"></button>
        <button type="button" pButton icon="pi pi-check" class="p-button-text p-ml-4" label="OK"
            (click)="cdDownload.accept()"></button>
    </ng-template>
</p-confirmDialog>

<p-confirmDialog #cdInvalidProjet header="Compléter un projet" key="validateInvalidProjetConfirm" appendTo="body">
    <ng-template pTemplate="footer">
        <button type="button" pButton class="p-button-text" label="Consulter le rapport de validation"
            (click)="cdInvalidProjet.reject()"></button>
        <button type="button" pButton class="p-button-text" label="Compléter le projet"
            (click)="cdInvalidProjet.accept()"></button>
    </ng-template>
</p-confirmDialog>
