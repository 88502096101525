import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SynchronisationState } from './synchronisation.state';

const getSynchronisationFeatureState = createFeatureSelector<SynchronisationState>('synchronisation');

export const getSyncErrors = createSelector(
    getSynchronisationFeatureState,
    state => state.syncErrors
);

export const getSyncErrorsLoading = createSelector(
    getSynchronisationFeatureState,
    state => state.syncErrorsLoading
);

export const getRequests = createSelector(
    getSynchronisationFeatureState,
    state => state.requests
);

export const getUploadRequestsSuccess = createSelector(
    getSynchronisationFeatureState,
    state => state.uploadRequestsSuccess
);

export const getUploadRequestsError = createSelector(
    getSynchronisationFeatureState,
    state => state.uploadRequestsError
);
