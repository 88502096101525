<header class="header">
    <section class="header__title">
        <a class="link">
            <img src="{{imagePath}}capture_blanc_46x130.png" alt="">
        </a>
    </section>
    <section class="header__content">
        <app-header-menu>
            <ng-container *ngIf="(selectUtilisateurActiveRoles$ | async) as activeRole">
                <ng-container *ngIf="activeRole?.length > 0 && enableMainMenu">
                    <app-header-menu-item menuWidth="240px" icon="fas fa-bars">
                        <app-header-navigation-list [items]="menu"></app-header-navigation-list>
                    </app-header-menu-item>
                    <app-header-menu-item [separator]="true"></app-header-menu-item>
                </ng-container>
            </ng-container>
            <app-header-menu-item menuWidth="340px" [icon]="user ? 'appicons ic-user' : 'appicons ic-sign-in-alt'"
                (menuOpened)="openedMenu()" (menuClosed)="closedMenu()">
                <app-user-profile>
                    <ng-container *ngIf="(selectUtilisateurActiveRoles$ | async) as activeRole">
                        <app-user-identity *ngIf="activeRole?.length > 0"
                            [callResetRole]="shouldResetRole"></app-user-identity>
                    </ng-container>
                </app-user-profile>
            </app-header-menu-item>
        </app-header-menu>
    </section>
</header>
