export enum PointInspectionProperties {
    ID = 'id',
    STATUT = 'statut',
    PROPRIETAIRE = 'proprietaire',
    CODE_A_BARRES = 'codeABarres',
    LCLCL_DISTANT = 'lclclDistant',

    ACCESSIBLE_CAMION = 'accessibleCamion',
    ADRESSE_TRAVAUX = 'adresseTravaux',
    IND_EQUIPEMENT_MAJEUR = 'indEquipementMajeur',
    LCLCL_POTEAU = 'lclclPoteau',
}
