import { LayerGroupe } from '../../enums/layer-groupe';
import { LayerType } from '../../map/models/layer-type.enum';
//import { mapStyleConfig } from '../../map/models/map-style-config.const';
import { StyleLayer } from '../../map/models/style-layer.model';

export const NATIONS_STYLE_LEGENDE: StyleLayer[] = [
    {
        'nomGroupe': LayerGroupe.NATIONS,
        'value': {
            'id': 'Nations',
            'type': LayerType.FILL,
            'source': 'Nations',
            'minzoom': 5,
            'layout': { 'visibility': 'visible' },
            'paint': {
                /* eslint-disable @typescript-eslint/naming-convention */
                'fill-color': '#0077a6',
                'fill-opacity': 0.5,
                'fill-outline-color': '#000000'
                /* eslint-enable @typescript-eslint/naming-convention */
            }
        }
    }
];
