<div *ngIf="projetInspectionListLoading$ | async" class="spinner-placeholder">
    <app-loading-spinner></app-loading-spinner>
</div>

<ng-container *ngIf="projetInspectionList$ | async as projetInspectionList">
    <!-- <app-projet-inspection-list [projetInspection]="projetInspectionList"></app-projet-inspection-list> -->
    <div class="inspection-list">
        <app-projet-list-mobile-dialog *ngIf="isUserMobile" [projetInspectionList]="projetInspectionList"
            [columns]="mobileColumns" [canInspecterProjet]="canInspecterProjet"
            [canInspecterProjetAQ]="canInspecterProjetAQ" [canCompleteProjet]="canCompleteProjet"
            [canCompleteProjetAQ]="canCompleteProjetAQ" [isOffline]="isAppOffline">
        </app-projet-list-mobile-dialog>
    </div>
</ng-container>
