import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProjetsState } from './projets.state';
import { PointInspectionDto } from '../../../core/api/client/models';

const getProjetsFeatureState = createFeatureSelector<ProjetsState>('projets');

export const getProjetsList = createSelector(
    getProjetsFeatureState,
    state => state.projetsList!
);

export const getProjetsListLoading = createSelector(
    getProjetsFeatureState,
    state => state.projetsListLoading
);

export const getValidationProjets = createSelector(
    getProjetsFeatureState,
    state => state.validationProjets
);

export const getInvalidProjetPointsInspection = (projetId: string) => createSelector(
    getValidationProjets,
    validationProjets => {
        const invalidePointsInspectionList: PointInspectionDto[] = [];
        if (validationProjets) {
            const projet = validationProjets[projetId];

            (projet?.pointInspections || []).forEach(pi => {
                if (pi.anomalies?.length > 0) {
                    const hasNoPhotos = !pi.photos || pi.photos.length === 0;
                    const anomalieWithoutPhotos = pi.anomalies.some(anomalie => anomalie.photos?.length === 0);

                    if (hasNoPhotos || anomalieWithoutPhotos) {
                        invalidePointsInspectionList.push(pi);
                    }
                }
            });
        }

        return invalidePointsInspectionList;
    }
);

export const getInvalidProjetPointsInspectionLoading = createSelector(
    getProjetsFeatureState,
    state => state.validationProjetsLoading
);

export const getCreateProjetInspection = createSelector(
    getProjetsFeatureState,
    state => state.createProjetInspection
);

export const getAssignProjetInspectionSuccess = createSelector(
    getProjetsFeatureState,
    state => state.assignProjetInspectionSuccess
);

export const getValidateProjetInspectionLoading = createSelector(
    getProjetsFeatureState,
    state => state.validateProjetInspectionLoading
);

export const getValidateProjetInspectionSuccess = createSelector(
    getProjetsFeatureState,
    state => state.validateProjetInspectionSuccess
);

export const getApproveProjetInspectionSuccess = createSelector(
    getProjetsFeatureState,
    state => state.approveProjetInspectionSuccess
);

export const getRejectProjetInspectionSuccess = createSelector(
    getProjetsFeatureState,
    state => state.rejectProjetInspectionSuccess
);

export const getUpdateProjetInspectionSuccess = createSelector(
    getProjetsFeatureState,
    state => state.updateProjetInspectionSuccess
);

export const getCreateAvisSap = createSelector(
    getProjetsFeatureState,
    state => state.createAvisSap
);

export const getCancelProjetInspectionSuccess = createSelector(
    getProjetsFeatureState,
    state => state.cancelProjetInspectionSuccess
);

export const getDeleteProjetInspectionSuccess = createSelector(
    getProjetsFeatureState,
    state => state.deleteProjetInspectionSuccess
);

export const getProjetInspectionHistory = createSelector(
    getProjetsFeatureState,
    state => state.projetInspectionHistory
);

/////////////////////////////////
////////////  Audit  ////////////
/////////////////////////////////

export const getProjetsAuditList = createSelector(
    getProjetsFeatureState,
    state => state.projetAuditList
);

export const getProjetsAuditListLoading = createSelector(
    getProjetsFeatureState,
    state => state.projetAuditListLoading
);

export const getCreateProjetAuditLoading = createSelector(
    getProjetsFeatureState,
    state => state.createProjetAuditLoading
);

export const getCreateProjetAuditSuccess = createSelector(
    getProjetsFeatureState,
    state => {
        return {
            success: state.createProjetAuditSuccess,
            projetAudit: state.createProjetAudit
        };
    }
);

export const getAssignProjetAuditSuccess = createSelector(
    getProjetsFeatureState,
    state => state.assignProjetAuditSuccess
);

export const getApproveProjetAuditSuccess = createSelector(
    getProjetsFeatureState,
    state => state.approveProjetAuditSuccess
);

export const getCancelProjetAuditSuccess = createSelector(
    getProjetsFeatureState,
    state => state.cancelProjetAuditSuccess
);

export const getRejectProjetAuditSuccess = createSelector(
    getProjetsFeatureState,
    state => state.rejectProjetAuditSuccess
);

export const getProjetAuditHistory = createSelector(
    getProjetsFeatureState,
    state => state.projetAuditHistory
);

