import { LayerGroupe } from '../../enums/layer-groupe';
import { ProjetProperties } from '../../features/projets/models/projet-properties.enum';
import { StatutProjetsInspection, StatutProjetsInspectionValue } from '../../features/projets/models/statut-projets-inspection.enum';
import { FeatureSource } from '../../map/models/feature-source.enum';
import { LayerType } from '../../map/models/layer-type.enum';
import { mapStyleConfig } from '../../map/models/map-style-config.const';
import { StyleLayer } from '../../map/models/style-layer.model';

export const PROJETS_INSPECTION_STYLE_LEGENDE: StyleLayer[] = [
    generateStyle(StatutProjetsInspection.enCreation),
    generateStyle(StatutProjetsInspection.nouveau),
    generateStyle(StatutProjetsInspection.inspectionAssigne),
    generateStyle(StatutProjetsInspection.inspectionEnCours),
    generateStyle(StatutProjetsInspection.inspectionCompletee),
    generateStyle(StatutProjetsInspection.aqAssigne),
    generateStyle(StatutProjetsInspection.aqEnCours),
    generateStyle(StatutProjetsInspection.aqCompletee),
    generateStyle(StatutProjetsInspection.attenteApprobationHQ),
    generateStyle(StatutProjetsInspection.approbationFinaleHQ),
    generateStyle(StatutProjetsInspection.conforme),
    generateStyle(StatutProjetsInspection.nonConforme),
    generateStyle(StatutProjetsInspection.correctionAssignee),
    generateStyle(StatutProjetsInspection.correctionEnCours),
    generateStyle(StatutProjetsInspection.correctionCompletee),
    generateStyle(StatutProjetsInspection.auditEnCours, '(Inspection)'),
    generateStyle(StatutProjetsInspection.audite),
    generateStyle(StatutProjetsInspection.annule, null, 'none'),
    generateStyle(StatutProjetsInspection.erreur, null, 'none')
];

function generateStyle(statut: StatutProjetsInspection, suffix: null | string = null, visibility: any = 'visible'): StyleLayer {
    return {
        'nomGroupe': LayerGroupe.PROJETS_INSPECTION,
        'value': {
            'id': suffix ? `${StatutProjetsInspectionValue[statut]} ${suffix}` : StatutProjetsInspectionValue[statut],
            'type': LayerType.FILL,
            'source': FeatureSource.PROJETS_INSPECTION,
            'minzoom': 5,
            'filter': ['==', ['get', ProjetProperties.STATUT], statut],
            'layout': { 'visibility': visibility },
            'paint': {
                /* eslint-disable @typescript-eslint/naming-convention */
                'fill-color': mapStyleConfig.colors[statut],
                'fill-outline-color': mapStyleConfig.colors[statut],
                /* eslint-enable @typescript-eslint/naming-convention */
            }
        },
    };
}
