import { Injectable } from '@angular/core';
import { queryFeatures, GeometryType, IQueryResponse, IQueryFeaturesResponse } from '@esri/arcgis-rest-feature-layer';
import { QueryLigne } from '../shared/models/query-ligne.model';
import { LocalStorageIndex } from '../shared/enums/local-storage-index.enum';
import { LocalStorageService } from './local-storage.service';
import { catchError, from, Observable, throwError } from 'rxjs';
import { serviceUrl } from '../enums/serviceUrl';

@Injectable({
    providedIn: 'root'
})

export class EsriRequeteService {
    esriAccessToken = JSON.parse(this.localStorageService.getItem(LocalStorageIndex.ESRI_ACCESS_TOKEN));

    constructor(private localStorageService: LocalStorageService) { }

    public executeQueryspatial(geometry: any, geometryType: GeometryType): Promise<IQueryFeaturesResponse | IQueryResponse> {
        return queryFeatures
            ({
                url: serviceUrl.POTEAU,
                httpMethod: 'POST',
                geometry: geometry,
                params: { 'token': `${this.esriAccessToken.token}` },
                hideToken: true,
                geometryType,
                spatialRel: 'esriSpatialRelIntersects',
                inSR: '4326',
                outSR: '4326',
                f: 'geojson',
                returnGeometry: true,
                outFields: ['poteau_id', 'OBJECTID', 'Usage', 'Proprietaire']
            });
    }

    public executeQueryCountOnly(geometry: any, geometryType: GeometryType): Observable<IQueryFeaturesResponse | IQueryResponse> {
        return from(queryFeatures({
            url: serviceUrl.POTEAU,
            httpMethod: 'POST',
            geometry: geometry,
            params: { 'token': `${this.esriAccessToken.token}` },
            hideToken: true,
            geometryType,
            spatialRel: 'esriSpatialRelIntersects',
            inSR: '4326',
            outSR: '4326',
            f: 'geojson',
            returnCountOnly: true
        })).pipe(
            catchError((error: any) => {
                console.error('Erreur dans l\'exécution de la requête spatiale:', error);
                return throwError(() => new Error('Échec de l\'exécution de la requête spatiale.'));
            })
        );
    }

    public rechercheNumeroLigne(queryParametres: QueryLigne, triphaseSeulement: boolean): Promise<IQueryFeaturesResponse | IQueryResponse> {
        const query: string = this.getLigneSpatialQuery(queryParametres, triphaseSeulement);
        return queryFeatures
            ({
                url: serviceUrl.CONDUCTEUR_MT_AERIEN,
                httpMethod: 'POST',
                params: { 'token': `${this.esriAccessToken.token}` },
                hideToken: true,
                where: query,
                inSR: '4326',
                outSR: '4326',
                groupByFieldsForStatistics: 'poste_ligne',
                f: 'geojson',
                orderByFields: 'poste_ligne',
                returnDistinctValues: true,
                returnGeometry: true
            });
    }

    private getLigneSpatialQuery(querystring: QueryLigne, triphaseSeulement: boolean): string {
        if (triphaseSeulement) {
            return `poste_ligne = '${querystring.nomLigne}' AND code_ced = '${querystring.territoire}' AND nb_phase = 'Triphasé'`;
        } else {
            return `poste_ligne = '${querystring.nomLigne}' AND code_ced = '${querystring.territoire}'`;
        };
    }

    public verifierIntersect(geometry: any, geometryType: GeometryType, url: string, where: string): Promise<IQueryFeaturesResponse | IQueryResponse> {
        return queryFeatures
            ({
                url,
                httpMethod: 'POST',
                geometry: geometry,
                params: { 'token': `${this.esriAccessToken.token}` },
                hideToken: true,
                geometryType,
                spatialRel: 'esriSpatialRelIntersects',
                where,
                inSR: '4326',
                outSR: '4326',
                f: 'geojson',
                returnGeometry: true
            });
    }
}
