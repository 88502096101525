import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { filter, takeUntil } from 'rxjs';
import { getTaxonomieById } from '../../../../core/store/selectors/taxonomie.selectors';
import { Store } from '@ngrx/store';
import { BaseComponent } from '../../../../shared/components/abstract-base-component';
import { State } from '../../../../state/app.state';
import { PopUpInfoCloseEvent } from '../../../models/pop-up-info-close-event.model';
import { getProjetInspectionById } from '../../../../features/inspection/state/inspection.selectors';
import { ProjetProperties } from '../../../../features/projets/models/projet-properties.enum';
import { ProjetLabels } from '../../../../features/projets/models/projet-labels.enum';
import { ProjetCompletDto } from '../../../../core/api/client/models';
import { ProjetTypes, projetTypesMap } from '../../../../features/projets/models/projet-priority-type.enum';
import { StatutProjetsInspection, STATUT_PROJETS_INSPECTION_NAME } from '../../../../features/projets/models/statut-projets-inspection.enum';
import { dateTimeChange } from '../../../../shared/utils';

@Component({
    selector: 'app-info-projet',
    templateUrl: './info-projet.component.html',
    styleUrls: ['./info-projet.component.scss']
})
export class InfoProjetComponent extends BaseComponent implements OnChanges {
    public data: { [name: string]: any } = {};
    public verticalProperties: string[] = [];
    public nomProjet: string = '';
    public subTitle: string = `Projet d'inspection`;

    @Input() feature: mapboxgl.MapboxGeoJSONFeature;
    @Output() closed: EventEmitter<PopUpInfoCloseEvent> = new EventEmitter<PopUpInfoCloseEvent>();

    constructor(
        private store: Store<State>
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.feature?.currentValue) {
            this.subscribeToGetProjetInspectionById(changes.feature.currentValue);
        }
    }

    public close() {
        this.closed.emit({ closed: true });
    }

    private subscribeToGetProjetInspectionById(feature: mapboxgl.MapboxGeoJSONFeature) {
        this.store.select(getProjetInspectionById(feature.properties[ProjetProperties.ID]))
            .pipe(
                filter(projet => !!projet),
                takeUntil(this.destroyed)
            ).subscribe(projet => {
                this.initData(projet);
            });
    }

    private subscribeToGetFirmeById(firmeId: string) {
        this.store.select(getTaxonomieById(firmeId))
            .pipe(
                filter(firme => !!firme),
                takeUntil(this.destroyed)
            ).subscribe(firme => {
                this.data = {
                    ...this.data,
                    ...(firme ? { [ProjetLabels.FIRME]: firme.code } : {})
                };
            });
    }

    private initData(projet: ProjetCompletDto) {
        this.nomProjet = projet.nom;
        this.data = {
            ...(projet.statut ? { [ProjetLabels.STATUT]: STATUT_PROJETS_INSPECTION_NAME.get(projet.statut as StatutProjetsInspection) } : {}),
            ...(projet.type ? { [ProjetLabels.TYPE]: projetTypesMap.get(projet.type!.toLowerCase() as ProjetTypes) } : {}),
            ...(projet.numeroOrdreDeTravail ? { [ProjetLabels.NUMERO_ORDRE_TRAVAIL]: projet.numeroOrdreDeTravail } : {}),
            ...(projet.creeLe ? { [ProjetLabels.CREE_LE]: dateTimeChange(projet.creeLe.toString(), 'dd/MM/YYYY') } : {}),
            ...(projet.territoire ? { [ProjetLabels.TERRITOIRE]: projet.territoire } : {}),
            ...(projet.inspectionAnnee ? { [ProjetLabels.ANNEE_INSPECTION]: projet.inspectionAnnee } : {}),
            ...(projet.assigneA ? { [ProjetLabels.ASSIGNE_A]: projet.assigneA } : {}),
        };

        this.subscribeToGetFirmeById(projet.firme);

        this.verticalProperties = [
            ProjetLabels.ASSIGNE_A,
        ];
    }
}
