
export enum StatutProjetsAudit {
    approuve = 'approuve',
    nonApprouve = 'nonApprouve',
    cree = 'cree',
    assigne = 'assigne',
    enCours = 'enCours',
    enCreation = 'enCreation',
    auditComplete = 'auditComplete',
    annule = 'annule',
    enErreur = 'enErreur'
}

export enum StatutProjetsAuditValue {
    approuve = 'Audit approuvé',
    nonApprouve = 'Audit non approuvé',
    cree = 'Audit créé',
    assigne = 'Audit assigné',
    enCours = 'Audit en cours',
    enCreation = 'Audit en création',
    auditComplete = 'Audit completé',
    annule = 'Audit annulé',
    enErreur = 'En erreur'
}

export const STATUT_PROJETS_AUDIT_NAME = new Map<StatutProjetsAudit, string>([
    [StatutProjetsAudit.approuve, StatutProjetsAuditValue.approuve],
    [StatutProjetsAudit.nonApprouve, StatutProjetsAuditValue.nonApprouve],
    [StatutProjetsAudit.cree, StatutProjetsAuditValue.cree],
    [StatutProjetsAudit.assigne, StatutProjetsAuditValue.assigne],
    [StatutProjetsAudit.enCours, StatutProjetsAuditValue.enCours],
    [StatutProjetsAudit.enCreation, StatutProjetsAuditValue.enCreation],
    [StatutProjetsAudit.auditComplete, StatutProjetsAuditValue.auditComplete],
    [StatutProjetsAudit.annule, StatutProjetsAuditValue.annule],
    [StatutProjetsAudit.enErreur, StatutProjetsAuditValue.enErreur]
]);
