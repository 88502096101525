import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    ProjetAuditsService,
    PointsAuditService,
} from '../../../core/api/client/services';
import {
    ProjetAuditDto,
    PointAuditDto,
    PhotoEntity,
} from '../../../core/api/client/models';

@Injectable({
    providedIn: 'root'
})
export class AuditService {

    constructor(
        private pointsAuditApiService: PointsAuditService,
        private projetAuditsApiService: ProjetAuditsService,
    ) { }

    public getProjetsAudit(params: ProjetAuditsService.GetApiV1AuditsParams): Observable<ProjetAuditDto[]> {
        return this.projetAuditsApiService.getApiV1Audits(params)
            .pipe(
                map(data => {
                    return data.items.map(item => {
                        return {
                            ...item
                        };
                    });
                })
            );
    }

    public getProjetAudit(projetAuditId: string): Observable<ProjetAuditDto> {
        return this.projetAuditsApiService.getApiV1AuditsId(projetAuditId);
    }

    public getPointsAudit(params: PointsAuditService.GetApiV1PointsauditParams): Observable<PointAuditDto[]> {
        return this.pointsAuditApiService.getApiV1Pointsaudit(params)
            .pipe(
                map(data => {
                    return data.items.map(item => {
                        return {
                            ...item
                        };
                    });
                })
            );
    }

    public updateProjetAudit(params: ProjetAuditsService.PatchApiV1AuditsIdParams): Observable<ProjetAuditDto> {
        return this.projetAuditsApiService.patchApiV1AuditsId(params);
    }

    public createPointAudit(params: PointAuditDto): Observable<PointAuditDto> {
        return this.pointsAuditApiService.postApiV1Pointsaudit(params);
    }

    public updatePointAudit(params: PointsAuditService.PutApiV1PointsauditPointAuditIdParams): Observable<PointAuditDto> {
        return this.pointsAuditApiService.putApiV1PointsauditPointAuditId(params);
    }

    public addPointAudit(params: ProjetAuditsService.PostApiV1AuditsIdInspectionsPointInspectionIdParams): Observable<ProjetAuditDto> {
        return this.projetAuditsApiService.postApiV1AuditsIdInspectionsPointInspectionId(params);
    }

    public addPointAuditPhoto(params: PointsAuditService.PostApiV1PointsauditPointAuditIdPhotosParams): Observable<PhotoEntity> {
        return this.pointsAuditApiService.postApiV1PointsauditPointAuditIdPhotos(params);
    }

    public addAnomalieAuditPhoto(params: PointsAuditService.PostApiV1PointsauditPointAuditIdAnomaliesIdAnomalieAuditPhotosParams): Observable<PhotoEntity> {
        return this.pointsAuditApiService.postApiV1PointsauditPointAuditIdAnomaliesIdAnomalieAuditPhotos(params);
    }

    public deletePointAuditPhoto(params: PointsAuditService.DeleteApiV1PointsauditPointAuditIdPhotosIdPhotoParams): Observable<null> {
        return this.pointsAuditApiService.deleteApiV1PointsauditPointAuditIdPhotosIdPhoto(params);
    }

    public deleteAnomalieAuditPhoto(params: PointsAuditService.DeleteApiV1PointsauditPointAuditIdAnomaliesIdAnomalieAuditPhotosIdPhotoParams): Observable<null> {
        return this.pointsAuditApiService.deleteApiV1PointsauditPointAuditIdAnomaliesIdAnomalieAuditPhotosIdPhoto(params);
    }
}
