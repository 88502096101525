<ng-container *ngIf="separator else notSeparator">
    <div class="header-menu-item separator-width">
        <span class="separator"></span>
    </div>
</ng-container>
<ng-template #notSeparator>
    <a *ngIf="routerLink" class="header-menu-item" [routerLink]="routerLink"><i [class]="icon"></i></a>
    <button *ngIf="!routerLink" [cdkMenuTriggerFor]="menu" class="header-menu-item" (click)="openingMenu()"><i
            [class]="icon"></i></button>

    <ng-template #menu>
        <div class="header-menu-item__content" [ngStyle]="menuWidth ? {width: menuWidth}: null" cdkMenu
            (closed)="closingMenu()">
            <ng-content></ng-content>
        </div>
    </ng-template>
</ng-template>