<div class="selected__container">
    <div class="container__content">
        <h3>Veuillez sélectionner le rôle avec lequel vous souhaitez vous connecter:</h3>
        <p-dropdown class="select-role" [(ngModel)]="selectedRole" [options]="options | async" appendTo="body"
            optionLabel="label" dataKey="label"></p-dropdown>
    </div>
    <div class="container__action">
        <button class="p-button" pButton pRipple icon="pi pi-check" label="Valider" (click)="save()"
            [disabled]="selectedRole.value === undefined"></button>
    </div>
</div>
