import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
    AnomalieBaseDto,
    PhotoEntity,
    PointInspectionDto,
    ProjetCompletDto,
} from '../../../core/api/client/models';
import {
    AnomalieService,
    InspectionService as PointsInspectionService,
    ProjetService,
} from '../../../core/api/client/services';

@Injectable({
    providedIn: 'root'
})
export class InspectionService {

    constructor(
        private anomalieApiService: AnomalieService,
        private projetApiService: ProjetService,
        private pointsInspectionApiService: PointsInspectionService
    ) { }

    public getProjetsInspection(params: ProjetService.GetApiV1ProjetsParams): Observable<ProjetCompletDto[]> {
        return this.projetApiService.getApiV1Projets(params)
            .pipe(
                map(data => {
                    return data.items.map(item => {
                        return {
                            ...item
                        };
                    });
                })
            );
    }

    public getProjetInspection(params: ProjetService.GetApiV1ProjetsIdParams): Observable<ProjetCompletDto> {
        return this.projetApiService.getApiV1ProjetsId(params);
    }

    public getPointsInspection(params: PointsInspectionService.GetApiV1InspectionsParams): Observable<PointInspectionDto[]> {
        return this.pointsInspectionApiService.getApiV1Inspections(params)
            .pipe(
                map(data => {
                    return data.items.map(item => {
                        return {
                            ...item
                        };
                    });
                })
            );
    }

    public createProjetInspection(params: ProjetService.PostApiV1ProjetsIdInspectionsParams): Observable<PointInspectionDto> {
        return this.projetApiService.postApiV1ProjetsIdInspections(params);
    }

    public getCreateProjetInspectionStatus(id: string) {
        return this.projetApiService.getApiV1ProjetsIdStatut(id);
    }

    public updateProjetInspection(params: ProjetService.PatchApiV1ProjetsIdParams): Observable<ProjetCompletDto> {
        return this.projetApiService.patchApiV1ProjetsId(params);
    }

    public createPointInspection(params: ProjetService.PostApiV1ProjetsIdInspectionsParams): Observable<PointInspectionDto> {
        return this.projetApiService.postApiV1ProjetsIdInspections(params);
    }

    public updatePointInspection(params: PointsInspectionService.PutApiV1InspectionsIdParams): Observable<PointInspectionDto> {
        return this.pointsInspectionApiService.putApiV1InspectionsId(params);
    }

    public updateAnomalieInspection(params: ProjetService.PutApiV1ProjetsIdInspectionInspectionIdAnomaliesParams): Observable<AnomalieBaseDto> {
        return this.projetApiService.putApiV1ProjetsIdInspectionInspectionIdAnomalies(params);
    }

    public deleteAnomalieInspection(anomalieId: string): Observable<null> {
        return this.anomalieApiService.deleteApiV1AnomaliesAnomalieId(anomalieId);
    }

    public addPointInspectionPhoto(params: PointsInspectionService.PostApiV1InspectionsInspectionIdPhotosParams): Observable<PhotoEntity> {
        return this.pointsInspectionApiService.postApiV1InspectionsInspectionIdPhotos(params);
    }

    public deletePointInspectionPhoto(params: PointsInspectionService.DeleteApiV1InspectionsInspectionIdPhotosNomPhotoParams): Observable<null> {
        return this.pointsInspectionApiService.deleteApiV1InspectionsInspectionIdPhotosNomPhoto(params);
    }

    public addAnomalieInspectionPhoto(params: AnomalieService.PostApiV1AnomaliesAnomalieIdPhotosParams): Observable<PhotoEntity> {
        return this.anomalieApiService.postApiV1AnomaliesAnomalieIdPhotos(params);
    }

    public deleteAnomalieInspectionPhoto(params: AnomalieService.DeleteApiV1AnomaliesAnomalieIdPhotosNomPhotoParams): Observable<null> {
        return this.anomalieApiService.deleteApiV1AnomaliesAnomalieIdPhotosNomPhoto(params);
    }
}
