<ng-container *ngIf="utilisateurActiveRole">
    <div class="user-roles item" *ngIf="(options | async) as receiveRoles ">
        <div *ngIf="receiveRoles.length < 2">
            <span class="text"><span class="bold">Rôle: </span>{{utilisateurActiveRole}}</span>
        </div>
        <div class="roles-fieldset" *ngIf="receiveRoles.length >= 2">
            <div class="user-active-role text bold">Rôle:</div>
            <div class="roleList__buttonContainer">
                <ng-container *ngFor="let role of receiveRoles">
                    <div>
                        <p-button [label]="role.label" [class]="role.value === selectedRole ? 'primary' : 'secondary'"
                            [type]="role.value === selectedRole ? 'primary' : 'secondary'"
                            [icon]="role.value === utilisateurActiveRole ? 'fa-solid fa-check' : ''"
                            [disabled]="requests.length > 0" (click)="selectRole(role.value, $event)">
                        </p-button>
                    </div>
                </ng-container>
                <div class="lock-message" *ngIf="requests.length > 0 && (selectUtilisateurIsMobile$ | async)">
                    Veuillez synchroniser vos données avant de changer de rôle.
                </div>
                <div class="roles-actions" *ngIf="selectedRole !== utilisateurActiveRole">
                    <p-button class="action-button" label="Annuler" (click)="cancel()">
                    </p-button>
                    <p-button class="action-button" label="Appliquer" (click)="apply()">
                    </p-button>
                </div>
            </div>
        </div>
    </div>
    <div class="item" *ngIf="identiteUtilisateur">
        <span class="text"><span class="bold">Firme: </span>{{firmeName}}</span>
    </div>
    <div class="item" *ngIf="environmentName !== undefined && environmentName !== ''">
        <span class="text"><span class="bold">Environnement: </span>{{environmentName}}</span>
    </div>
</ng-container>