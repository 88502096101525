import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from '../../../shared/components/abstract-base-component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, takeUntil } from 'rxjs';
import { RechercheConfig } from '../models/recherche.enum';
import { selectUtilisateurIsMobile } from '../../../state/shared/shared.selectors';
import { Store } from '@ngrx/store';
import { getRechercheList, isRechercheLoading } from '../state/recherche.selectors';
import { RechercheListe } from '../models/recherche-liste.model';

@Component({
    selector: 'app-recherche-main-dialog',
    templateUrl: './recherche-main-dialog.component.html',
    styleUrls: ['./recherche-main-dialog.component.scss']
})
export class RechercheMainDialogComponent extends BaseComponent implements OnInit {
    public rechercheForm: FormGroup;
    public selectedLayer: string;
    public isUserMobile: boolean = false;
    public dropdownRechercheList: string[] = [RechercheConfig.RECHERCHE_LCLCL, RechercheConfig.RECHERCHE_CODE_BARRES];
    public rechercheConfig = RechercheConfig;
    public rechercheIsLoading$: Observable<boolean> = this.store.select(isRechercheLoading);
    public rechercheListe: RechercheListe | null = null;
    public rechercheListe$: Observable<RechercheListe | null> = this.store.select(getRechercheList);

    @Input() showRechercheDialog: boolean = true;
    @Output() closeRechercheDialog = new EventEmitter<boolean>();

    constructor(private readonly store: Store) {
        super();
    }

    public ngOnInit(): void {
        this.initForm();
        this.subscribeToLayerOnChange();
        this.subscribeToIsUserMobile();
        this.subscribreToRechercheListe();
    }

    private subscribeToLayerOnChange() {
        this.rechercheForm.controls.layerSelect.valueChanges
            .pipe(
                takeUntil(this.destroyed)
            )
            .subscribe((layer: string) => this.resetData(layer));
    }

    private subscribeToIsUserMobile() {
        this.store.select(selectUtilisateurIsMobile).pipe(
            takeUntil(this.destroyed)
        ).subscribe(_isUserMobile => {
            this.isUserMobile = _isUserMobile;
            this.setUpForMobile();
        });
    }

    private subscribreToRechercheListe() {
        this.rechercheListe$.pipe(
            takeUntil(this.destroyed)
        ).subscribe((rechercheListe: RechercheListe | null) => {
            this.rechercheListe = rechercheListe;
        });
    }

    private setUpForMobile() {
        if (!this.isUserMobile) {
            this.dropdownRechercheList.push(RechercheConfig.RECHERCHE_LIGNE);
        }
        this.dropdownRechercheList.push(RechercheConfig.RECHERCHE_SQRC);
    }

    private initForm() {
        this.rechercheForm = new FormGroup({
            layerSelect: new FormControl(null, [Validators.required]),
        });
    }

    private resetData(layer: string) {
        this.rechercheListe = null;
        this.selectedLayer = layer;
    }

    public onCloseDialog(event: boolean) {
        this.showRechercheDialog = event;
        this.closeRechercheDialog.emit(event);
    }

}
