import { NgModule } from '@angular/core';
import { CdkMenuModule } from '@angular/cdk/menu';
import { CommonModule } from '@angular/common';
import { CommonUiModule } from '@ngxhq/common-ui';
import { ButtonModule } from 'primeng/button';
import { MainMenuComponent } from './main-menu/main-menu.component';
import { SharedModule } from '../shared/shared.module';
import { HeaderComponent } from './header/header/header.component';
import { MenuModule } from 'primeng/menu';
import { HeaderMenuComponent } from './header/header-menu/header-menu.component';
import { HeaderMenuItemComponent } from './header/header-menu-item/header-menu-item.component';
import { UserProfileComponent } from './header/user-profile/user-profile.component';
import { HeaderNavigationListComponent } from './header/header-navigation-list/header-navigation-list.component';
import { UserIdentityComponent } from './header/user-identity/user-identity.component';

@NgModule({
    imports: [
        CommonModule,
        CommonUiModule,
        SharedModule,
        ButtonModule,
        MenuModule,
        CdkMenuModule
    ],
    exports: [
        MainMenuComponent,
        HeaderComponent,
        HeaderMenuComponent,
        HeaderMenuItemComponent,
        UserProfileComponent,
        HeaderNavigationListComponent,
        UserIdentityComponent
    ],
    declarations: [
        MainMenuComponent,
        HeaderComponent,
        HeaderMenuComponent,
        HeaderMenuItemComponent,
        UserProfileComponent,
        HeaderNavigationListComponent,
        UserIdentityComponent
    ],
    providers: [
    ],
})
export class ShellModule {
}
