import { createAction, props } from '@ngrx/store';
import { BoundingBoxInfo } from '../../../shared/models/bounding-box-info.model';
import {
    PointAuditDto,
    ProjetAuditDto,
    PatchProjetAuditDto,
    PointInspectionDto,
    PhotoEntity
} from '../../../core/api/client/models';

export enum AuditActionTypes {
    LOAD_PROJET_AUDIT_LIST = '[Audit] Load Projet Audit List',
    LOAD_PROJET_AUDIT_LIST_SUCCESS = '[Audit] Load Projet Audit List Success',
    LOAD_PROJET_AUDIT_LIST_ERROR = '[Audit] Load Projet Audit List Error',
    ACTIVATE_PROJET_AUDIT = '[Audit] Activate Projet Audit',
    ACTIVATE_PROJET_AUDIT_SUCCESS = '[Audit] Activate Projet Audit Success',
    ACTIVATE_PROJET_AUDIT_ERROR = '[Audit] Activate Projet Audit Error',
    SET_CURRENT_ACTIVE_PROJET_AUDIT = '[Audit] Set Current Active Projet Audit',
    SET_CURRENT_ACTIVE_PROJET_AUDIT_BY_ID = '[Audit] Set Current Active Projet Audit By Id',
    SET_SELECTED_POINT_AUDIT = '[Audit] Set Selected Point Audit',
    COMPLETE_PROJET_AUDIT = '[Audit] Complete Projet Audit',
    COMPLETE_PROJET_AUDIT_SUCCESS = '[Audit] Complete Projet Audit Success',
    COMPLETE_PROJET_AUDIT_ERROR = '[Audit] Complete Projet Audit Error',
    UPDATE_POINT_AUDIT = '[Audit] Update Point Audit',
    UPDATE_POINT_AUDIT_SUCCESS = '[Audit] Update Point Audit Success',
    UPDATE_POINT_AUDIT_ERROR = '[Audit] Update Point Audit Error',
    UPDATE_PROJET_AUDIT_TO_INDEXEDDB = '[Audit] Update Projet Audit To IndexedDb',
    LOAD_POINTS_AUDIT_BBOX = '[Audit] Get Points Audit Bbox',
    LOAD_POINTS_AUDIT_BBOX_SUCCESS = '[Audit] Get Points Audit Bbox Success',
    LOAD_POINTS_AUDIT_BBOX_ERROR = '[Audit] Get Points Audit Bbox Error',
    GET_POINTS_ACTIVE_PROJET_AUDIT = '[Audit] Get Points From Active Projet Audit',
    GET_POINTS_ACTIVE_PROJET_AUDIT_SUCCESS = '[Audit] Get Points Active Projet Audit Success',
    GET_POINTS_ACTIVE_PROJET_AUDIT_ERROR = '[Audit] Get Points Active Projet Audit Error',
    GET_POINTS_AUDIT_BY_PROJET_AUDIT_ID = '[Audit] Get Points Audit By Projet Audit Id',
    GET_POINTS_AUDIT_BY_PROJET_AUDIT_ID_SUCCESS = '[Audit] Get Points Audit By Projet Audit Id Success',
    GET_POINTS_AUDIT_BY_PROJET_AUDIT_ID_ERROR = '[Audit] Get Points Audit By Projet Audit Id Error',
    ADD_POINT_AUDIT = '[Audit] Add Point Audit',
    ADD_POINT_AUDIT_ERROR = '[Audit] Add Point Audit Error',
    ADD_POINT_AUDIT_PHOTO = '[Audit] Add Point Audit Photo',
    ADD_POINT_AUDIT_PHOTO_SUCCESS = '[Audit] Add Point Audit Photo Success',
    ADD_POINT_AUDIT_PHOTO_ERROR = '[Audit] Add Point Audit Photo Error',
    ADD_POINT_AUDIT_SUCCESS = '[Audit] Add Point Audit Success',
    ADD_ANOMALIE_AUDIT_PHOTO = '[Audit] Add Anomalie Audit Photo',
    ADD_ANOMALIE_AUDIT_PHOTO_SUCCESS = '[Audit] Add Anomalie Audit Photo Success',
    ADD_ANOMALIE_AUDIT_PHOTO_ERROR = '[Audit] Add Anomalie Audit Photo Error',
    DELETE_POINT_AUDIT_PHOTO = '[Audit] Delete Point Audit Photo',
    DELETE_POINT_AUDIT_PHOTO_SUCCESS = '[Audit] Delete Point Audit Photo Success',
    DELETE_POINT_AUDIT_PHOTO_ERROR = '[Audit] Delete Point Audit Photo Error',
    DELETE_ANOMALIE_AUDIT_PHOTO = '[Audit] Delete Anomalie Audit Photo',
    DELETE_ANOMALIE_AUDIT_PHOTO_SUCCESS = '[Audit] Delete Anomalie Audit Photo Success',
    DELETE_ANOMALIE_AUDIT_PHOTO_ERROR = '[Audit] Delete Anomalie Audit Photo Error',
    CREATE_POINT_INSPECTION_AUDIT = `[Audit] Create Point Inspection And Point Audit`,
    CREATE_POINT_INSPECTION_AUDIT_SUCCESS = `[Audit] Create Point Inspection And Point Audit Success`,
    CREATE_POINT_INSPECTION_AUDIT_ERROR = `[Audit] Create Point Inspection And Point Audit Error`,
}

/*** LOAD PROJET AUDIT LIST */
export const loadProjetAuditList = createAction(AuditActionTypes.LOAD_PROJET_AUDIT_LIST);

export const loadProjetAuditListSuccess = createAction(AuditActionTypes.LOAD_PROJET_AUDIT_LIST_SUCCESS, props<{ projetAuditList: ProjetAuditDto[] }>());

export const loadProjetAuditListError = createAction(AuditActionTypes.LOAD_PROJET_AUDIT_LIST_ERROR, props<{ error: any }>());

/*** ACTIVATE PROJET AUDIT ***/
export const activateProjetAudit = createAction(AuditActionTypes.ACTIVATE_PROJET_AUDIT, props<{ projetAuditId: string, activateData: PatchProjetAuditDto }>());

export const activateProjetAuditSuccess = createAction(AuditActionTypes.ACTIVATE_PROJET_AUDIT_SUCCESS, props<{ activateProjetAudit: ProjetAuditDto }>());

export const activateProjetAuditError = createAction(AuditActionTypes.ACTIVATE_PROJET_AUDIT_ERROR, props<{ error: any }>());

export const setCurrentActiveProjetAudit = createAction(AuditActionTypes.SET_CURRENT_ACTIVE_PROJET_AUDIT, props<{ projetAudit: ProjetAuditDto }>());

export const setCurrentActiveProjetAuditById = createAction(AuditActionTypes.SET_CURRENT_ACTIVE_PROJET_AUDIT_BY_ID, props<{ projetAuditId: string }>());

export const setSelectedPointAudit = createAction(AuditActionTypes.SET_SELECTED_POINT_AUDIT, props<{ pointAudit: PointAuditDto }>());

/*** COMPLETE PROJET AUDIT ***/
export const completeProjetAudit = createAction(AuditActionTypes.COMPLETE_PROJET_AUDIT, props<{ projetAuditId: string, completeData: PatchProjetAuditDto }>());

export const completeProjetAuditSuccess = createAction(AuditActionTypes.COMPLETE_PROJET_AUDIT_SUCCESS, props<{ projetAudit: ProjetAuditDto }>());

export const completeProjetAuditError = createAction(AuditActionTypes.COMPLETE_PROJET_AUDIT_ERROR, props<{ error: any }>());

/*** UPDATE POINT AUDIT ***/
export const updatePointAudit = createAction(AuditActionTypes.UPDATE_POINT_AUDIT, props<{ pointAudit: PointAuditDto }>());

export const updatePointAuditSuccess = createAction(AuditActionTypes.UPDATE_POINT_AUDIT_SUCCESS, props<{ pointAudit: PointAuditDto }>());

export const updatePointAuditError = createAction(AuditActionTypes.UPDATE_POINT_AUDIT_ERROR, props<{ error: any }>());

/*** UPDATE PROJET AUDIT TO INDEXEDDB ***/
export const updateProjetAuditToIndexedDb = createAction(AuditActionTypes.UPDATE_PROJET_AUDIT_TO_INDEXEDDB, props<{ projetAuditId: string }>());

/*** ADD POINT AUDIT ***/
export const addPointAudit = createAction(AuditActionTypes.ADD_POINT_AUDIT, props<{ pointAudit: PointAuditDto }>());

export const addPointAuditSuccess = createAction(AuditActionTypes.ADD_POINT_AUDIT_SUCCESS, props<{ pointAudit: PointAuditDto }>());

export const addPointAuditError = createAction(AuditActionTypes.ADD_POINT_AUDIT_ERROR, props<{ error: any }>());

/*** ADD POINT AUDIT PHOTO ***/
export const addPointAuditPhoto = createAction(AuditActionTypes.ADD_POINT_AUDIT_PHOTO, props<{ pointAudit: PointAuditDto, photo: PhotoEntity }>());

export const addPointAuditPhotoSuccess = createAction(AuditActionTypes.ADD_POINT_AUDIT_PHOTO_SUCCESS, props<{ pointAudit: PointAuditDto, photo: PhotoEntity }>());

export const addPointAuditPhotoError = createAction(AuditActionTypes.ADD_POINT_AUDIT_PHOTO_ERROR, props<{ error: any }>());

/*** ADD ANOMALIE AUDIT PHOTO ***/
export const addAnomalieAuditPhoto = createAction(AuditActionTypes.ADD_ANOMALIE_AUDIT_PHOTO,
    props<{ pointAudit: PointAuditDto, anomalieAuditId: string, photo: PhotoEntity }>());

export const addAnomalieAuditPhotoSuccess = createAction(AuditActionTypes.ADD_ANOMALIE_AUDIT_PHOTO_SUCCESS,
    props<{ pointAuditId: string, anomalieAuditId: string, photo: PhotoEntity }>());

export const addAnomalieAuditPhotoError = createAction(AuditActionTypes.ADD_ANOMALIE_AUDIT_PHOTO_ERROR, props<{ error: any }>());

/*** DELETE POINT AUDIT PHOTO ***/
export const deletePointAuditPhoto = createAction(AuditActionTypes.DELETE_POINT_AUDIT_PHOTO, props<{ pointAudit: PointAuditDto, photo: PhotoEntity }>());

export const deletePointAuditPhotoSuccess = createAction(AuditActionTypes.DELETE_POINT_AUDIT_PHOTO_SUCCESS, props<{ pointAudit: PointAuditDto, photo: PhotoEntity }>());

export const deletePointAuditPhotoError = createAction(AuditActionTypes.DELETE_POINT_AUDIT_PHOTO_ERROR, props<{ error: any }>());

/*** DELETE ANOMALIE AUDIT PHOTO ***/
export const deleteAnomalieAuditPhoto = createAction(AuditActionTypes.DELETE_ANOMALIE_AUDIT_PHOTO,
    props<{ pointAudit: PointAuditDto, anomalieAuditId: string, photo: PhotoEntity }>());

export const deleteAnomalieAuditPhotoSuccess = createAction(AuditActionTypes.DELETE_ANOMALIE_AUDIT_PHOTO_SUCCESS,
    props<{ pointAudit: PointAuditDto, anomalieAuditId: string, photo: PhotoEntity }>());

export const deleteAnomalieAuditPhotoError = createAction(AuditActionTypes.DELETE_ANOMALIE_AUDIT_PHOTO_ERROR, props<{ error: any }>());

/*** GET POINTS ACTIVE PROJET AUDIT ***/
export const getPointsActiveProjetAudit = createAction(AuditActionTypes.GET_POINTS_ACTIVE_PROJET_AUDIT);

export const getPointsActiveProjetAuditSuccess = createAction(AuditActionTypes.GET_POINTS_ACTIVE_PROJET_AUDIT_SUCCESS, props<{ pointsAudit: PointAuditDto[] }>());

export const getPointsActiveProjetAuditError = createAction(AuditActionTypes.GET_POINTS_ACTIVE_PROJET_AUDIT_ERROR, props<{ error: any }>());

/*** GET POINTS BY PROJET AUDIT ID***/
export const getPointsAuditByProjetAuditId = createAction(AuditActionTypes.GET_POINTS_AUDIT_BY_PROJET_AUDIT_ID, props<{ projetAuditId: string }>());

export const getPointsAuditByProjetAuditIdSuccess = createAction(AuditActionTypes.GET_POINTS_AUDIT_BY_PROJET_AUDIT_ID_SUCCESS, props<{ pointsAudit: PointAuditDto[] }>());

export const getPointsAuditByProjetAuditIdError = createAction(AuditActionTypes.GET_POINTS_AUDIT_BY_PROJET_AUDIT_ID_ERROR, props<{ error: any }>());

/*** LOAD POINTS AUDIT BBOX ***/
export const loadPointsAuditBbox = createAction(AuditActionTypes.LOAD_POINTS_AUDIT_BBOX, props<BoundingBoxInfo>());

export const loadPointsAuditBboxSuccess = createAction(AuditActionTypes.LOAD_POINTS_AUDIT_BBOX_SUCCESS, props<{ bbox: BoundingBoxInfo, pointsAudit: PointAuditDto[] }>());

export const loadPointsAuditBboxError = createAction(AuditActionTypes.LOAD_POINTS_AUDIT_BBOX_ERROR, props<{ bbox: BoundingBoxInfo, error: any }>());

/*** CREATE POINT INSPECTION AND POINT AUDIT ***/
export const createPointAudit = createAction(AuditActionTypes.CREATE_POINT_INSPECTION_AUDIT, props<{ pointInspection: PointInspectionDto }>());

export const createPointAuditSuccess = createAction(AuditActionTypes.CREATE_POINT_INSPECTION_AUDIT_SUCCESS, props<{ pointAudit: PointAuditDto }>());

export const createPointAuditError = createAction(AuditActionTypes.CREATE_POINT_INSPECTION_AUDIT_ERROR, props<{ error: any }>());
