import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from '../../shared/components/abstract-base-component';
import { Store } from '@ngrx/store';
import { State } from '../../state/app.state';
import * as InspectionActions from './state/inspection.actions';
import { getProjetInspectionList, getProjetInspectionListLoading } from './state/inspection.selectors';
import { takeUntil } from 'rxjs';
import {
    selectCanCompleteProjet,
    selectCanCompleteProjetAQ,
    selectCanInspecterProjet,
    selectCanInspecterProjetAQ,
    selectIsAppOnline,
    selectUtilisateurIsMobile
} from '../../state/shared/shared.selectors';
import { projetInspectionListMobileDialogColumn } from './models/projet-inspection-list-dialog.column';


@Component({
    selector: 'app-inspection',
    templateUrl: './inspection.component.html',
    styleUrls: ['./inspection.component.scss']
})
export class InspectionComponent extends BaseComponent implements OnInit, OnDestroy {

    public projetInspectionList$ = this.store.select(getProjetInspectionList);
    public projetInspectionListLoading$ = this.store.select(getProjetInspectionListLoading);

    mobileColumns = projetInspectionListMobileDialogColumn;

    public isAppOffline = true;
    public isUserMobile = false;
    public canInspecterProjet = false;
    public canInspecterProjetAQ = false;
    public canCompleteProjet = false;
    public canCompleteProjetAQ = false;

    constructor(private store: Store<State>
    ) {
        super();
    }

    ngOnInit(): void {
        this.store.dispatch(InspectionActions.loadProjetInspectionList());

        this.subscribeToIsAppOffline();
        this.subscribeToIsUserMobile();
        this.subscribeToCanInspecterProjet();
        this.subscribeToCanInspecterProjetAQ();
        this.subscribeToCanCompleteProjet();
        this.subscribeToCanCompleteProjetAQ();
    }

    private subscribeToIsAppOffline() {
        this.store.select(selectIsAppOnline).pipe(
            takeUntil(this.destroyed)
        ).subscribe(_isAppOnline => {
            this.isAppOffline = !_isAppOnline;
        });
    }

    private subscribeToIsUserMobile() {
        this.store.select(selectUtilisateurIsMobile).pipe(
            takeUntil(this.destroyed)
        ).subscribe(_isUserMobile => {
            this.isUserMobile = _isUserMobile;
        });
    }

    private subscribeToCanInspecterProjet() {
        this.store.select(selectCanInspecterProjet).pipe(
            takeUntil(this.destroyed)
        ).subscribe(_canInspecterProjet => {
            this.canInspecterProjet = _canInspecterProjet;
        });
    }

    private subscribeToCanInspecterProjetAQ() {
        this.store.select(selectCanInspecterProjetAQ).pipe(
            takeUntil(this.destroyed)
        ).subscribe(_canInspecterProjetAQ => {
            this.canInspecterProjetAQ = _canInspecterProjetAQ;
        });
    }

    private subscribeToCanCompleteProjet() {
        this.store.select(selectCanCompleteProjet).pipe(
            takeUntil(this.destroyed)
        ).subscribe(_canCompleteProjet => {
            this.canCompleteProjet = _canCompleteProjet;
        });
    }

    private subscribeToCanCompleteProjetAQ() {
        this.store.select(selectCanCompleteProjetAQ).pipe(
            takeUntil(this.destroyed)
        ).subscribe(_canCompleteProjetAQ => {
            this.canCompleteProjetAQ = _canCompleteProjetAQ;
        });
    }

}
