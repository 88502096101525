import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ProjetsComponent } from './projets.component';
import { ProjetsListComponent } from './components/projets-list/projets-list.component';
import { ProjetsListDialogComponent } from './components/projets-list/projets-list-dialog/projets-list-dialog.component';
import { RapportCreationAvisDialogComponent } from './components/dialog/rapport-creation-avis-dialog/rapport-creation-avis-dialog.component';
import { AssignProjetInspectionDialogComponent } from './components/dialog/assign-projet-inspection-dialog/assign-projet-inspection-dialog.component';
import { ModifyProjetInspectionDialogComponent } from './components/dialog/modify-projet-inspection-dialog/modify-projet-inspection-dialog.component';
import { RejeterProjetInspectionDialogComponent } from './components/dialog/rejeter-projet-inspection-dialog/rejeter-projet-inspection-dialog.component';
import { CreateProjetInspectionDialogComponent } from './components/dialog/create-projet-inspection-dialog/create-projet-inspection-dialog.component';
import { AssignProjetAuditDialogComponent } from './components/dialog/assign-projet-audit-dialog/assign-projet-audit-dialog.component';
import { CreateProjetAuditDialogComponent }
    from './components/dialog/create-projet-audit-dialog/create-projet-audit-dialog.component';
import { ProjetsRapportValidationDialogComponent } from './components/dialog/projets-rapport-validation-dialog/projets-rapport-validation-dialog.component';

import { SharedModule, } from '../../shared/shared.module';
import { ProjetsEffects } from './state/projets.effects';
import { projetsReducer } from './state/projets.reducer';

import { FrenchTitleCasePipe } from '../../shared/pipes/french-title-case.pipe';

@NgModule({
    declarations: [
        ProjetsComponent,
        ProjetsListComponent,
        ProjetsListDialogComponent,
        RapportCreationAvisDialogComponent,
        AssignProjetInspectionDialogComponent,
        ModifyProjetInspectionDialogComponent,
        RejeterProjetInspectionDialogComponent,
        CreateProjetInspectionDialogComponent,
        AssignProjetAuditDialogComponent,
        CreateProjetAuditDialogComponent,
        ProjetsRapportValidationDialogComponent,
    ],
    imports: [
        SharedModule,
        StoreModule.forFeature('projets', projetsReducer),
        EffectsModule.forFeature([ProjetsEffects])
    ],
    exports: [
        CreateProjetInspectionDialogComponent,
        CreateProjetAuditDialogComponent,
    ],
    providers: [
        FrenchTitleCasePipe
    ]
})
export class ProjetsModule { }
