import { Component, OnInit } from '@angular/core';
import { OpenIdService, OpenIdUserInfo } from '@ngxhq/security';
import { filter, map, switchMap, takeUntil } from 'rxjs';
import { UserViewModel } from '../../models/user-view-model';
import { ComponentBase } from '@ngxhq/common-ui';
import { Store } from '@ngrx/store';
import { logoutIdentiteUtilisateur } from '../../../state/shared/shared.actions';
import { Router } from '@angular/router';

@Component({
    selector: 'app-user-profile',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent extends ComponentBase implements OnInit {
    public user: UserViewModel;

    constructor(
        private openIdService: OpenIdService,
        private store: Store,
        private router: Router
    ) {
        super();
    }

    ngOnInit(): void {
        this.openIdService.status
            .pipe(
                filter(authenticationState => authenticationState.isAuthenticated),
                switchMap(() => this.openIdService.getUserInfo()),
                map(userInfo => this.mapToViewModel(userInfo)),
                takeUntil(this.destroyed)
            )
            .subscribe(user => {
                this.user = user;
            });
    }

    private mapToViewModel(userInfo: OpenIdUserInfo): UserViewModel {
        return {
            fullname: `${userInfo.firstName} ${userInfo.lastName}`.trim(),
            initials: `${userInfo.firstName[0] || ''}${userInfo.lastName[0] || ''}`
        };
    }

    public logout() {
        this.store.dispatch(logoutIdentiteUtilisateur());
    }

    public async login(): Promise<void> {
        await this.router.navigate(['/']);
    }
}
