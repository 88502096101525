import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../../state/app.state';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TableColumn } from '../../../../../shared/models/table-column.model';
import { MapService } from '../../../../../map/services/map.service';
import { PatchProjetInspectionDto, PointInspectionDto } from '../../../../../core/api/client/models';
import {
    getInvalidProjetPointsInspection,
    getInvalidProjetPointsInspectionLoading,
    getValidateProjetInspectionLoading,
    getValidateProjetInspectionSuccess
} from '../../../state/projets.selectors';
import { UiService } from '../../../../../services/ui.service';
import { filter, Observable, takeUntil } from 'rxjs';
import { BaseComponent } from '../../../../../shared/components/abstract-base-component';
import { ProjetInspectionActions } from '../../../models/projets-inspection-actions.enum';
import * as ProjetsActions from '../../../../projets/state/projets.actions';

@Component({
    selector: 'app-projets-rapport-validation-dialog',
    templateUrl: './projets-rapport-validation-dialog.component.html',
    styleUrls: ['./projets-rapport-validation-dialog.component.scss']
})
export class ProjetsRapportValidationDialogComponent extends BaseComponent implements OnInit {
    public projetId: string | null = null;
    public invalidPointsInspection$: Observable<PointInspectionDto[]>;
    public invalidPointsInspectionLoading$ = this.store.select(getInvalidProjetPointsInspectionLoading);
    public validateProjetInspectionLoading$ = this.store.select(getValidateProjetInspectionLoading);

    public columns: TableColumn[] = [
        { field: 'codeABarres', header: 'Code à barres' },
        { field: 'codeErreur', header: 'Message' },
        { field: 'action', header: '' }
    ];

    constructor(
        public config: DynamicDialogConfig,
        private ref: DynamicDialogRef,
        private store: Store<State>,
        private mapService: MapService,
        private uiService: UiService,
    ) {
        super();
    }

    ngOnInit() {
        this.projetId = this.config.data.projetId;
        this.invalidPointsInspection$ = this.store.select(getInvalidProjetPointsInspection(this.projetId));
    }

    public onZoomPointInspection(pointInspection: PointInspectionDto) {
        this.uiService.openProjetInspectionListModal(false);
        this.mapService.zoomPointInspection(pointInspection);
    }

    public validateProjet() {
        const validateData: PatchProjetInspectionDto = { action: ProjetInspectionActions.VALIDER };
        this.store.dispatch(ProjetsActions.validateProjetInspection({ projetInspectionId: this.projetId, validateData }));

        this.subscribeToValidateProjetInspectionSuccess();
    }

    private subscribeToValidateProjetInspectionSuccess() {
        this.store.select(getValidateProjetInspectionSuccess).pipe(
            filter(validated => !!validated),
            takeUntil(this.destroyed)
        ).subscribe(validated => {
            if (validated) {
                setTimeout(() => {
                    this.ref.close();
                }, 3000);
            }
        });
    }
}
