<ul class="navigation__list">
    <ng-container *ngFor="let item of items">
        <li *ngIf="item && (item['visible'] === undefined || item['visible'] === true)" class="list__item">
            <a (click)="item.link()" class="item__link">
                <i [class]="item.icon" class="link__icon"></i>
                <span class="link__label">{{item.label}}</span>
            </a>
        </li>
    </ng-container>
</ul>
