import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { flattenDeep } from 'lodash';
import { of } from 'rxjs';
import { switchMap, map, debounceTime, withLatestFrom } from 'rxjs/operators';
import { AnomalieActionTypes, forbiddenGetAnomaliesBbox, getAnomaliesBboxSuccess } from './anomalie.action';
import { ApiService } from 'src/app/services/api.service';
import { StoreName } from '../../../offline/models/indexed-db-store-name.enum';
import { Projet } from '../../../projets/models/projet.model';
import { mapStyleConfig } from '../../../../map/models/map-style-config.const';
import { IndexedDbService } from '../../../offline/services/indexed-db.service';
import { AnomalieBaseDto } from '../../../../core/api/client/models';
import { Store } from '@ngrx/store';
import { selectUtilisateurIsInspecteurOrControleur } from '../../../../state/shared/shared.selectors';

@Injectable()
export class AnomalieEffects {

    public getAnomaliesWithBbox$ = createEffect(() => this.actions$.pipe(
        ofType(AnomalieActionTypes.GET_ANOMALIES_BBOX),
        debounceTime(200),
        withLatestFrom(this.store.select(selectUtilisateurIsInspecteurOrControleur)),
        switchMap(([{ bbox, zoomLevel }, isInspecteurOuControleur]) => {
            if (isInspecteurOuControleur) {
                return of(null);
            }
            if (!navigator.onLine) {
                return this.dbService.getAll(StoreName.PROJETS).pipe(
                    map((p: any) => (p[0] as Projet)?.pointInspections || []),
                    map(pis => {
                        const anomalies = pis.map(pi => pi.anomalies || []);
                        return flattenDeep(anomalies);
                    }),
                );
            }
            if (zoomLevel >= mapStyleConfig.poteau.minZoom) {
                const { xmin, ymin, xmax, ymax } = bbox;
                const bboxParams = `xmin=${xmin}&ymin=${ymin}&xmax=${xmax}&ymax=${ymax}`;
                return this.apiService.get<AnomalieBaseDto[]>(`/anomalies?pageSize=5000&${bboxParams}`).pipe( // TODO: Refactor retirer apiService
                    map((x: any) => x.items)
                );
            }
            return of([]);
        }),
        map((anomalies) => {
            if (!anomalies) {
                return forbiddenGetAnomaliesBbox();
            }
            return getAnomaliesBboxSuccess({ anomalies });
        })
    ));

    constructor(
        private store: Store,
        private actions$: Actions,
        private apiService: ApiService,
        private readonly dbService: IndexedDbService,
    ) { }
}
