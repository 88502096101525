<section class="error__wrapper">
    <ng-container>
        <h1>Application Capture - rôle</h1>
        <h2>Les rôles attribués pour cet utilisateur ne font pas partie des SIGA requises pour l'application Capture
        </h2>
    </ng-container>
    <p>
        Veuillez valider vos demandes SIGA afin d'avoir le(s) rôle(s) nécessaire(s) pour accéder à Capture
    </p>
</section>
