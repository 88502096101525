<!-- Proviens de la fondation, à modifier quand on aura des maquettes -->
<div *ngIf="user" class="user-profile__header">
    <div class='hq-header-menu-profile'>
        <div class='hq-header-menu-profile__image'>
            <img *ngIf="user.profileImage" [src]='user.profileImage' alt='' />
            <div class='hq-header-menu-profile__initials' *ngIf="!user.profileImage">
                {{user?.initials | uppercase}}
            </div>
        </div>
        <div class='hq-header-menu-profile__description'>
            <div class='hq-header-menu-profile__user-fullname' (click)="$event.stopPropagation()">{{user.fullname}}
            </div>
        </div>
    </div>
</div>

<div class="user-profile__content">
    <ng-content></ng-content>
</div>

<div class="user-profile__footer">
    <p-button *ngIf="!user" pRipple type="button" label="Se connecter" size="large" icon="appicons ic-sign-in-alt"
        (click)="login()"></p-button>

    <p-button *ngIf="user" pRipple type="button" label="Se déconnecter" size="large" icon="fas fa-sign-out-alt"
        (click)="logout()"></p-button>
</div>