import { ServiceFeature } from '../enums/serviceFeature';

export const serviceFeatureInstDistVue: string = 'https://services5.arcgis.com/jD5Z6QYavsBfnE6G/arcgis/rest/services/A396_Capture_Inst_Dist_Vue/FeatureServer/';
export const serviceFeatureInstDist: string = 'https://services5.arcgis.com/jD5Z6QYavsBfnE6G/arcgis/rest/services/A396_Capture_Inst_Dist_20241128/FeatureServer/';
export const serviceFeatureAiresProtegees: string = 'https://services5.arcgis.com/jD5Z6QYavsBfnE6G/arcgis/rest/services/Aires_protegees_vue/FeatureServer/';

export const serviceUrl = {
    POSTE: serviceFeatureInstDistVue + ServiceFeature.POSTE,
    POTEAU: serviceFeatureInstDistVue + ServiceFeature.POTEAU,
    LCLCL_AERIEN: serviceFeatureInstDistVue + ServiceFeature.LCLCL_AERIEN,
    LCLCL_SOUTERRAIN: serviceFeatureInstDistVue + ServiceFeature.LCLCL_SOUTERRAIN,
    CONDUCTEUR_MT_SOUTERRAIN: serviceFeatureInstDistVue + ServiceFeature.CONDUCTEUR_MT_AERIEN,
    CONDUCTEUR_MT_AERIEN: serviceFeatureInstDistVue + ServiceFeature.CONDUCTEUR_MT_SOUTERRAIN,
    PARC_DE_POTEAU_HQ: serviceFeatureInstDistVue + ServiceFeature.PARC_DE_POTEAU_HQ,
    PARC_DE_POTEAU_AUTRE: serviceFeatureInstDistVue + ServiceFeature.PARC_DE_POTEAU_AUTRE,
    NATIONS: serviceFeatureInstDist + ServiceFeature.NATIONS,
    RAINETTES: serviceFeatureAiresProtegees + ServiceFeature.RAINETTES,
};
