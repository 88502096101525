<div *ngIf="(invalidPointsInspectionLoading$ | async) || (validateProjetInspectionLoading$ | async)"
    class="spinner-placeholder">
    <app-loading-spinner></app-loading-spinner>
</div>
<ng-container
    *ngIf="(invalidPointsInspectionLoading$ | async) === false && (validateProjetInspectionLoading$ | async) === false">
    <div class="container" *ngIf="invalidPointsInspection$ | async as invalidPointsInspection">
        <p-table *ngIf="invalidPointsInspection?.length > 0" [columns]="columns" [value]="invalidPointsInspection"
            responsiveLayout="scroll">
            <ng-template pTemplate="header">
                <tr>
                    <th *ngFor="let col of columns " class="p-flex-column">
                        {{col.header}}
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
                <tr (dblclick)="onZoomPointInspection(rowData)">
                    <td class="vCenter">{{rowData.poteau.codeABarres}}</td>
                    <td class="vCenter">Photo manquante</td>
                    <td class="sticky-column">
                        <div class="sticky-column__container">
                            <button pButton pRipple class="p-button-rounded p-button-secondary" icon="pi pi-search-plus"
                                (click)="onZoomPointInspection(rowData)"></button>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <div class="action" *ngIf="invalidPointsInspection.length !== 0">
            <button type="button" pButton pRipple icon="fas fa-check-double" pTooltip="Valider le projet"
                tooltipPosition="top" (click)="validateProjet()" label="Valider le projet"></button>
        </div>
        <div *ngIf="invalidPointsInspection?.length === 0">
            Tous les points d'inspection sont valides.
        </div>
    </div>
</ng-container>
