import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
// import { Store } from '@ngrx/store';
import { dateTimeChange } from '../../../shared/utils';
import { ProjetInspectionHistory } from '../models/projet-inspection-history.model';
import { ProjetAuditsService, ProjetService } from '../../../core/api/client/services';
import {
    CreateProjetDto,
    CreatedProjetAuditDto,
    CreatedProjetDto,
    // PatchProjetInspectionDto,
    ProjetAuditDto,
    ProjetAvecHistoriqueBaseDto,
    ProjetCompletDto,
    ProjetHistoriqueDto,
    ResultatTransfertAvisInspection,
    SearchResult
} from '../../../core/api/client/models';
import { ProjetAuditHistory } from '../../audit/models/projet-audit-history.model';
// import { ProjetInspectionActions } from '../models/projets-inspection-actions.enum';
// import * as ProjetsActions from '../../projets/state/projets.actions';

@Injectable({
    providedIn: 'root'
})
export class ProjetsService {

    constructor(
        private projetApiService: ProjetService,
        private projetAuditsApiService: ProjetAuditsService,
        // private store: Store,
    ) { }

    public getProjets(params: ProjetService.GetApiV1ProjetsParams): Observable<ProjetCompletDto[]> {
        return this.projetApiService.getApiV1Projets(params)
            .pipe(
                map(data => {
                    return data.items.map(item => {
                        return {
                            ...item
                        };
                    });
                })
            );
    }

    public getProjetsAudit(params: ProjetAuditsService.GetApiV1AuditsParams): Observable<ProjetAuditDto[]> {
        return this.projetAuditsApiService.getApiV1Audits(params)
            .pipe(
                map(data => {
                    return data.items.map(item => {
                        return {
                            ...item
                        };
                    });
                })
            );
    }

    public getProjetInspection(params: ProjetService.GetApiV1ProjetsIdParams): Observable<ProjetCompletDto> {
        return this.projetApiService.getApiV1ProjetsId(params);
    }

    public getProjetAudit(projetAuditId: string): Observable<ProjetAuditDto> {
        return this.projetAuditsApiService.getApiV1AuditsId(projetAuditId);
    }

    public getProjetInspectionHistory(projetId: string): Observable<ProjetInspectionHistory[]> {
        return this.projetApiService.getApiV1ProjetsIdHistorique(projetId)
            .pipe(
                map(data => this.formatProjetHistory(data)));
    }

    public patchProjetInspection(params: ProjetService.PatchApiV1ProjetsIdParams): Observable<ProjetCompletDto> {
        return this.projetApiService.patchApiV1ProjetsId(params);
    }

    public updateProjetInspection(params: ProjetService.PutApiV1ProjetsIdParams): Observable<ProjetCompletDto> {
        return this.projetApiService.putApiV1ProjetsId(params);
    }

    public createProjetInspection(params: CreateProjetDto): Observable<CreatedProjetDto> {
        return this.projetApiService.postApiV1Projets(params);
    }

    public getCreateProjetInspectionStatus(id: string) {
        return this.projetApiService.getApiV1ProjetsIdStatut(id);
    }

    public createProjetAudit(params: ProjetService.PostApiV1ProjetsIdAuditsParams): Observable<CreatedProjetAuditDto> {
        return this.projetApiService.postApiV1ProjetsIdAudits(params);
    }

    public getCreateProjetAuditStatus(id: string) {
        return this.projetAuditsApiService.getApiV1AuditsIdStatut(id);
    }

    public deleteProjetInspection(id: string): Observable<null> {
        return this.projetApiService.deleteApiV1ProjetsId(id);
    }

    private formatProjetHistory(data: ProjetAvecHistoriqueBaseDto): ProjetInspectionHistory[] {
        return data.historique?.map((historique: ProjetHistoriqueDto) => (
            {
                ...historique,
                dateOperation: dateTimeChange(historique.dateOperation?.toString(), 'YYYY-MM-dd'),
                creeLe: dateTimeChange(historique.creeLe?.toString(), 'YYYY-MM-dd'),
                assigneLe: dateTimeChange(historique.assigneLe?.toString(), 'YYYY-MM-dd'),
                activeLe: dateTimeChange(historique.activeLe?.toString(), 'YYYY-MM-dd'),
                completeLe: dateTimeChange(historique.completeLe?.toString(), 'YYYY-MM-dd'),
                valideLe: dateTimeChange(historique.valideLe?.toString(), 'YYYY-MM-dd'),
                auditAnnuleLe: dateTimeChange(historique.auditAnnuleLe?.toString(), 'YYYY-MM-dd'),
                auditCreeLe: dateTimeChange(historique.auditCreeLe?.toString(), 'YYYY-MM-dd'),
                approbationHqLe: dateTimeChange(historique.approbationHqLe?.toString(), 'YYYY-MM-dd'),
                annuleLe: dateTimeChange(historique.annuleLe?.toString(), 'YYYY-MM-dd'),
                aqAssigneA: historique.aqAssigneA,
                aqAssignePar: historique.aqAssignePar,
                aqActiveLe: dateTimeChange(historique.aqActiveLe?.toString(), 'YYYY-MM-dd'),
                aqActivePar: historique.aqActivePar,
                aqCompletePar: historique.aqCompletePar,
                aqCompleteLe: dateTimeChange(historique.aqCompleteLe?.toString(), 'YYYY-MM-dd')
            }
        ) as ProjetInspectionHistory);
    }

    public updateProjetAudit(params: ProjetAuditsService.PatchApiV1AuditsIdParams): Observable<ProjetAuditDto> {
        return this.projetAuditsApiService.patchApiV1AuditsId(params);
    }

    public createAvisSap(projetId: string): Observable<ResultatTransfertAvisInspection[]> {
        return this.projetApiService.postApiV1ProjetsIdAvis(projetId);
    }

    public getProjetAuditHistory(params: ProjetAuditsService.GetApiV1AuditsParams, projetAuditId: string): Observable<ProjetAuditHistory[]> {
        return this.projetAuditsApiService.getApiV1Audits(params)
            .pipe(
                map(data => this.formatProjetAuditHistory(data, projetAuditId))
            );
    }

    private formatProjetAuditHistory(data: SearchResult, projetAuditId: string): ProjetAuditHistory[] {
        const projetAuditHistory: ProjetAuditHistory[] = [];
        data.items!
            .filter(item => item.historique?.length)
            .map(item => {
                item.historique!
                    .filter((historique: ProjetAuditHistory) => historique.auditId === projetAuditId)
                    .map((historique: ProjetAuditHistory) => {
                        projetAuditHistory.push(
                            {
                                id: historique.id,
                                auditId: historique.auditId,
                                nom: item.nom,
                                statut: historique.statut,
                                creeLe: dateTimeChange(historique.creeLe?.toString(), 'YYYY-MM-dd'),
                                creePar: historique.creePar,
                                assigneLe: dateTimeChange(historique.assigneLe?.toString(), 'YYYY-MM-dd'),
                                assignePar: historique.assignePar,
                                assigneA: historique.assigneA,
                                activeLe: dateTimeChange(historique.activeLe?.toString(), 'YYYY-MM-dd'),
                                activePar: historique.activePar,
                                completeLe: dateTimeChange(historique.completeLe?.toString(), 'YYYY-MM-dd'),
                                completePar: historique.completePar,
                                approuveLe: dateTimeChange(historique.approuveLe?.toString(), 'YYYY-MM-dd'),
                                approuvePar: historique.approuvePar,
                                annuleLe: dateTimeChange(historique.annuleLe?.toString(), 'YYYY-MM-dd'),
                                annulePar: historique.annulePar
                            });
                    });
            });
        return projetAuditHistory;
    }

    // public validateProjetInspection(projetId: string) {
    //     const validateData: PatchProjetInspectionDto = { action: ProjetInspectionActions.VALIDER };
    //     this.store.dispatch(ProjetsActions.validateProjetInspection({ projetInspectionId: projetId, validateData }));
    // }
}
