import { Pipe, PipeTransform } from '@angular/core';
/*
 * Change la première lettre d'une "string" pour la mettre en majuscule
 * Usage:
 *   value | frenchTitleCase
 * Example:
 *   {{ "firme d'audit" | frenchTitleCase }}
 *   formats to: "Firme d'audit"
*/
@Pipe({ name: 'frenchTitleCase' })
export class FrenchTitleCasePipe implements PipeTransform {
    transform(value: string | string[]): string {
        if (!value) {
            return '';
        } else if (typeof value === 'string' || value instanceof String) {
            const first = value.substring(0, 1).toUpperCase();
            return first + value.substring(1);
        } else if (Array.isArray(value)) {
            return value.map((item: string) => {
                const first = item.substring(0, 1).toUpperCase();
                return first + item.substring(1);
            }).join(', ');
        } else {
            return value;
        }
    }
}
