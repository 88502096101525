import { createAction, props } from '@ngrx/store';
import { EsriAccessToken } from '../../shared/models/esri-access-token.model';
import { AnomaliePilotageDto, ExtractionInfosDTO } from '../../core/api/client/models';
import { DataExtractionFormat } from '../../shared/enums/data-extraction-format.enum';
import { UserInformation } from '../../shared/models/user-informations.model';

export enum SharedActionTypes {
    SET_ONLINE_STATUT = '[Shared] Set App Online Statut',
    GET_ESRI_ACCESS_TOKEN = '[Shared] Get Esri Access Token',
    GET_ESRI_ACCESS_TOKEN_SUCCESS = '[Shared] Get Esri Access Token Success',
    GET_ESRI_ACCESS_TOKEN_FAILURE = '[Shared] Get Esri Access Token Failure',
    GET_DATA_EXTRACTION = '[Shared] Get Data Extraction',
    GET_DATA_EXTRACTION_SUCCESS = '[Shared] Get Data Extraction Success',
    GET_DATA_EXTRACTION_FAILURE = '[Shared] Get Data Extraction Failure',
    START_DATA_EXTRACTION = '[Shared] Start Data Extraction',
    START_DATA_EXTRACTION_SUCCESS = '[Shared] Start Data Extraction Success',
    START_DATA_EXTRACTION_FAILURE = '[Shared] Start Data Extraction Failure',
    LOAD_ANOMALIES_PILOTAGE = '[Anomalie Pilotage] Get Anomalies Pilotage',
    LOAD_ANOMALIES_PILOTAGE_SUCCESS = '[Anomalie Pilotage] Get Anomalies Pilotage Success',
    LOAD_ANOMALIES_PILOTAGE_FAILURE = '[Anomalie Pilotage] Get Anomalies Pilotage Failure',
    GET_IDENTITE_UTILISATEUR = '[Shared] Get Identié utilisateur From Backend',
    GET_IDENTITE_UTILISATEUR_SUCCESS = '[Shared] Get Identié utilisateur From Backend Success',
    GET_IDENTITE_UTILISATEUR_FAILLURE = '[Shared] Get Identié utilisateur From Backend Failure',
    SET_USER_ACTIVE_ROLE = '[Shared] Set User active role',
    SET_USER_ACTIVE_ROLE_SUCCESS = '[Shared] Set User active role Success',
    SET_USER_ACTIVE_ROLE_FAILURE = '[Shared] Set User active role Failure',
    LOGOUT_IDENTITE_UTILISATEUR = '[Shared] Logout Identité utilisateur',
}

export const setAppOnlineStatut = createAction(SharedActionTypes.SET_ONLINE_STATUT, props<{ online: boolean }>());

export const selectEsriAccessToken = createAction(SharedActionTypes.GET_ESRI_ACCESS_TOKEN);

export const selectEsriAccessTokenSuccess = createAction(SharedActionTypes.GET_ESRI_ACCESS_TOKEN_SUCCESS, props<{ esriAccessToken: EsriAccessToken }>());

export const selectEsriAccessTokenFailure = createAction(SharedActionTypes.GET_ESRI_ACCESS_TOKEN_FAILURE, props<{ error: any }>());

export const selectDataExtraction = createAction(SharedActionTypes.GET_DATA_EXTRACTION, props<{ key: string }>());

export const getDataExtractionSuccess = createAction(SharedActionTypes.GET_DATA_EXTRACTION_SUCCESS, props<{ dataExtraction: ExtractionInfosDTO }>());

export const getDataExtractionFailure = createAction(SharedActionTypes.GET_DATA_EXTRACTION_FAILURE, props<{ error: any }>());

export const startDataExtraction = createAction(SharedActionTypes.START_DATA_EXTRACTION, props<{ typeExtraction: string, format: DataExtractionFormat }>());

export const startDataExtractionSuccess = createAction(SharedActionTypes.START_DATA_EXTRACTION_SUCCESS, props<{ id: string, format: DataExtractionFormat }>());

export const startDataExtractionFailure = createAction(SharedActionTypes.START_DATA_EXTRACTION_FAILURE, props<{ error: any }>());

export const loadAnomaliesPilotage = createAction(SharedActionTypes.LOAD_ANOMALIES_PILOTAGE);

export const loadAnomaliesPilotageSuccess = createAction(SharedActionTypes.LOAD_ANOMALIES_PILOTAGE_SUCCESS, props<{ anomaliesPilotage: AnomaliePilotageDto[] }>());

export const loadAnomaliesPilotageFailure = createAction(SharedActionTypes.LOAD_ANOMALIES_PILOTAGE_FAILURE, props<{ error: any }>());

export const selectIdentiteUtilisateur = createAction(SharedActionTypes.GET_IDENTITE_UTILISATEUR);

// TODO : Changer UserInformation pour IdentiteUtilisateur
export const getIdentiteUtilisateurSuccess = createAction(SharedActionTypes.GET_IDENTITE_UTILISATEUR_SUCCESS, props<{ identiteUtilisateur: UserInformation }>());

export const getIdentiteUtilisateurFailure = createAction(SharedActionTypes.GET_IDENTITE_UTILISATEUR_FAILLURE, props<{ error: any }>());

export const setUserActiveRole = createAction(SharedActionTypes.SET_USER_ACTIVE_ROLE, props<{ role: string }>());

export const setUserActiveRoleSuccess = createAction(SharedActionTypes.SET_USER_ACTIVE_ROLE_SUCCESS, props<{ activeRole: string }>());

export const setUserActiveRoleFailure = createAction(SharedActionTypes.SET_USER_ACTIVE_ROLE_FAILURE, props<{ error: any }>());

export const logoutIdentiteUtilisateur = createAction(SharedActionTypes.LOGOUT_IDENTITE_UTILISATEUR);
