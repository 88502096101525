import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from '../../shared/components/abstract-base-component';
import { Store } from '@ngrx/store';
import { State } from '../../state/app.state';
import * as ProjetsActions from './state/projets.actions';
import { getProjetsAuditList, getProjetsAuditListLoading, getProjetsList, getProjetsListLoading } from './state/projets.selectors';
import { combineLatest, takeUntil } from 'rxjs';
import { ProjetDataType } from './models/projet-data.type';
import { ProjetData } from './models/projet-data.enum';
import { selectCanOpenProjetAuditList, selectCanOpenProjetsList } from '../../state/shared/shared.selectors';

@Component({
    selector: 'app-projets',
    templateUrl: './projets.component.html',
    styleUrls: ['./projets.component.scss']
})
export class ProjetsComponent extends BaseComponent implements OnInit, OnDestroy {
    public projetDataType: ProjetDataType;

    public projetsList$ = this.store.select(getProjetsList);
    public projetsListLoading$ = this.store.select(getProjetsListLoading);
    public projetsAuditList$ = this.store.select(getProjetsAuditList);
    public projetsAuditListLoading$ = this.store.select(getProjetsAuditListLoading);

    private selectCanOpenProjetsList$ = this.store.select(selectCanOpenProjetsList);
    private selectCanOpenProjetAuditList$ = this.store.select(selectCanOpenProjetAuditList);

    constructor(
        private store: Store<State>
    ) {
        super();
    }

    ngOnInit(): void {
        this.getProjetDataListByRole();
    }

    private getProjetDataListByRole(): void {
        combineLatest([this.selectCanOpenProjetsList$, this.selectCanOpenProjetAuditList$]).pipe(
            takeUntil(this.destroyed)
        ).subscribe(([haveAccessInspection, haveAccessAudit]) => {
            if (haveAccessInspection && haveAccessAudit) {
                this.projetDataType = ProjetData.AUDIT_INSPECTION;
                this.store.dispatch(ProjetsActions.loadProjetsList({ inclureProjetAudit: true }));
            } else if (haveAccessInspection && !haveAccessAudit) {
                this.projetDataType = ProjetData.INSPECTION;
                this.store.dispatch(ProjetsActions.loadProjetsList({ inclureProjetAudit: false }));
            } else if (!haveAccessInspection && haveAccessAudit) {
                this.projetDataType = ProjetData.AUDIT;
                this.store.dispatch(ProjetsActions.loadProjetAuditList());
            }
        });
    }
}
