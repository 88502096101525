import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { filter, takeUntil } from 'rxjs';
import { getTaxonomieById } from '../../../../core/store/selectors/taxonomie.selectors';
import { Store } from '@ngrx/store';
import { Taxonomie } from '../../../../features/pilotage/models/taxonomie.model';
import { BaseComponent } from '../../../../shared/components/abstract-base-component';
import * as utils from '../../../../shared/utils';
import { ProjetAuditDto } from '../../../../core/api/client/models';
import { getProjetAuditById } from '../../../../features/audit/state/audit.selectors';
import { ProjetAuditProprietesKey, ProjetAuditProprietesValue } from '../../../../shared/enums/projet-audit-proprietes.enum';
import { PopUpInfoCloseEvent } from '../../../models/pop-up-info-close-event.model';
import { StatutProjetsAuditValue } from '../../../../features/projets/models/statut-projets-audit.enum';

@Component({
    selector: 'app-info-projet-audit',
    templateUrl: './info-projet-audit.component.html',
    styleUrls: ['./info-projet-audit.component.scss']
})
export class InfoProjetAuditComponent extends BaseComponent implements OnChanges, OnDestroy {
    public projetName: string = '';
    public subTitle: string = `Projet d'audit`;
    public data: { [name: string]: any } = {};
    public projetAudit: Partial<ProjetAuditDto | undefined> = undefined;

    private _verticalProperties: string[] = [];
    public set verticalProperties(keys: string[]) {
        this._verticalProperties = keys.map((key: string) => ProjetAuditProprietesValue[key]);
    }
    public get verticalProperties() { return this._verticalProperties; }

    @Input() projetAuditId: string;
    @Output() closed: EventEmitter<PopUpInfoCloseEvent> = new EventEmitter<PopUpInfoCloseEvent>();

    constructor(private readonly store: Store) { super(); }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.projetAuditId?.currentValue) {
            this.subscribeToProjetAudit(changes.projetAuditId.currentValue);
        }
    }

    public close() {
        this.closed.emit({ closed: true });
        this.ngOnDestroy();
    }

    private subscribeToProjetAudit(id: string) {
        this.store.select(getProjetAuditById(id)).pipe(
            filter(projetAudit => !!projetAudit),
            takeUntil(this.destroyed)
        ).subscribe((projetAudit: ProjetAuditDto) => {
            this.projetAudit = projetAudit;
            this.projetName = this.projetAudit.nom;
            this.prepareData();
        });
    }

    private getFirmeById(firmeId: string) {
        this.store.select(getTaxonomieById(firmeId)).pipe(
            takeUntil(this.destroyed),
        ).subscribe((firme: Taxonomie) => {
            if (firme) {
                this.data[ProjetAuditProprietesValue.firme] = firme.code;
            }
        });
    }

    private prepareData() {
        this.data = {};
        this.getProperties().forEach(key => {
            switch (key) {
                case ProjetAuditProprietesKey.firme: this.getFirmeById(this.projetAudit[key]); break;
                case ProjetAuditProprietesKey.statut: this.getProjetAuditStatus(key); break;
                case ProjetAuditProprietesKey.assigneLe: this.parseDate(key); break;
                case ProjetAuditProprietesKey.creeLe: this.parseDate(key); break;
                default: this.data[ProjetAuditProprietesValue[key]] = this.projetAudit[key];
            }
        });
        this.getRemarque();
    }

    private getProjetAuditStatus(key: string) {
        this.data[ProjetAuditProprietesValue[key]] = StatutProjetsAuditValue[this.projetAudit[key]];
    }

    private parseDate(key: string) {
        this.data[ProjetAuditProprietesValue[key]] = utils.dateTimeChange(this.projetAudit[key].toString(), 'dd/MM/YYYY');
    }

    private getRemarque() {
        if (this.projetAudit.remarques !== undefined && this.projetAudit.remarques !== null && this.projetAudit.remarques !== '') {
            this.data[ProjetAuditProprietesValue.remarques] = this.projetAudit.remarques;
        }
    }

    private getProperties(): string[] {
        this.verticalProperties = [
            ProjetAuditProprietesKey.assigneA,
            ProjetAuditProprietesKey.assignePar
        ];

        return [
            ProjetAuditProprietesKey.creeLe,
            ProjetAuditProprietesKey.assigneA,
            ProjetAuditProprietesKey.firme,
            ProjetAuditProprietesKey.assigneLe,
            ProjetAuditProprietesKey.assignePar,
            ProjetAuditProprietesKey.nombreTotalDePoteaux,
            ProjetAuditProprietesKey.statut,
            ProjetAuditProprietesKey.remarques,
        ];
    }
}
