import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuditState } from './audit.state';

const getAuditFeatureState = createFeatureSelector<AuditState>('audit');

export const getProjetAuditList = createSelector(
    getAuditFeatureState,
    state => state.projetAuditList!
);

export const getProjetAuditListLoading = createSelector(
    getAuditFeatureState,
    state => state.projetAuditListLoading
);

export const getLoadProjetAuditListError = createSelector(
    getAuditFeatureState,
    state => state.loadProjetAuditListError
);

export const getActivateProjetAuditSuccess = createSelector(
    getAuditFeatureState,
    state => {
        return {
            success: state.activateProjetAuditSuccess,
            projetAudit: state.activateProjetAudit
        };
    }
);

export const getActivateProjetAuditError = createSelector(
    getAuditFeatureState,
    state => state.activateProjetAuditError
);

export const getCurrentActiveProjetAudit = createSelector(
    getAuditFeatureState,
    state => state.currentActiveProjetAudit
);

export const getSelectedPointAudit = createSelector(
    getAuditFeatureState,
    state => state.selectedPointAudit
);

export const getCompleteProjetAuditSuccess = createSelector(
    getAuditFeatureState,
    state => state.completeProjetAudit
);

export const getCompleteProjetAuditError = createSelector(
    getAuditFeatureState,
    state => state.completeProjetAuditError
);

export const getCompleteProjetAuditLoading = createSelector(
    getAuditFeatureState,
    state => state.completeProjetAuditLoading
);

export const getAddPointAuditSuccess = createSelector(
    getAuditFeatureState,
    state => state.addPointAudit
);

export const getCreatePointAuditSuccess = createSelector(
    getAuditFeatureState,
    state => state.createPointAudit
);

export const getUpdatePointAuditSuccess = createSelector(
    getAuditFeatureState,
    state => state.updatePointAudit
);

export const getUpdatePointAuditError = createSelector(
    getAuditFeatureState,
    state => state.updatePointAuditError
);

export const getPointsAuditBBoxSuccess = createSelector(
    getAuditFeatureState,
    state => state.pointsAudit
);

export const getPointsAuditLoading = createSelector(
    getAuditFeatureState,
    state => state.pointsAuditLoading
);

export const getPointsActiveProjetAuditSuccess = createSelector(
    getAuditFeatureState,
    state => state.pointsAudit
);

export const getProjetAuditById = (projetAuditId: string) => createSelector(
    getAuditFeatureState,
    (state) => state.projetAuditList?.find(projet => projet.id === projetAuditId)
);

export const getPointAuditById = (pointAuditId: string) => createSelector(
    getAuditFeatureState,
    state => state.pointsAudit?.find(point => point.id === pointAuditId)
);

export const getPointAuditByInspectionId = (pointInspectionId: string) => createSelector(
    getAuditFeatureState,
    state => state.pointsAudit?.filter(pointAudit => pointAudit.pointInspectionId === pointInspectionId) || []
);
