/* eslint-disable @typescript-eslint/naming-convention */
import { DatePipe } from '@angular/common';
import { Feature, FeatureCollection, MultiPolygon, Polygon } from '@turf/helpers';
import { isEmpty, merge, mergeWith, flatMap, min, pick } from 'lodash';
import { Photo } from './models/photo.model';
import { Projet } from '../features/projets/models/projet.model';
import { StatutPointInspection, StatutPointInspectionNumberValue } from '../features/inspection/models/statut-point-inspection.enum';
import { PointInspectionProperties } from '../features/inspection/models/point-inspection-properties.enum';
import { AnomalieProperties } from '../features/anomalie/models/anomalie-properties.enum';
import { PointAuditProperties } from '../features/audit/models/point-audit-properties.enum';
import { ProjetProperties } from '../features/projets/models/projet-properties.enum';
import { v4 as uuidv4 } from 'uuid';
import { saveAs } from 'file-saver';
import { PhotoBackend } from './models/photo-backend.model';
import { ObjectType } from './enums/photo-object-type.enum';
import {
    ProjetAuditDto,
    PointAuditDto,
    AnomalieBaseDto,
    AnomalieAuditDto,
    ProjetCompletDto,
    PointInspectionDto,
    AnomaliePilotageDto
} from '../core/api/client/models';

export function polygonsToMultipolygon(polygons: Feature[]): MultiPolygon {
    const multipolygon: MultiPolygon = {
        type: 'MultiPolygon',
        coordinates: []
    };
    polygons.forEach(p => {
        multipolygon.coordinates.push((p.geometry as Polygon).coordinates);
    });
    return multipolygon;
}


// Toutes les fonctions generateFeatures sont utilisés seulement pour l'affichage des informations sur les points
// et projets sur la carte. Ces informations sont mises à jour automatiquement lors de l'update des states.
// Ne pas ajouter de propriétés supplémentaires ici, utiliser les states pour récupérer les informations
// des projets et points.

// TODO: Utilisé dans l'ancien tableau des projets d'inspection. À supprimer lorsque ne sera plus utilisé.
export function generateProjetFeatures(projets: Projet[]): FeatureCollection {
    const projetCollection: FeatureCollection = { features: [], type: 'FeatureCollection' };
    projets.forEach((projet) => {
        if (projet.geometrie) {
            const feature: Feature = {
                type: 'Feature', geometry: JSON.parse(projet.geometrie as string),
                properties: {
                    [ProjetProperties.ID]: projet.id,
                    [ProjetProperties.STATUT]: projet.statut
                }
            };
            projetCollection.features.push(feature);
        };
    });

    return projetCollection;
}

export function generateProjetAuditFeatures(projetsAudit: ProjetAuditDto[]): FeatureCollection {
    const projetCollection: FeatureCollection = { features: [], type: 'FeatureCollection' };
    projetsAudit?.forEach((projet) => {
        if (projet.geometrie) {
            const feature: Feature = {
                type: 'Feature', geometry: JSON.parse(projet.geometrie as string),
                properties: {
                    [ProjetProperties.ID]: projet.id,
                    [ProjetProperties.STATUT]: projet.statut
                }
            };
            projetCollection.features.push(feature);
        };
    });

    return projetCollection;
}

export function generateProjetInspectionFeatures(projets: ProjetCompletDto[]): FeatureCollection {
    const projetCollection: FeatureCollection = { features: [], type: 'FeatureCollection' };
    projets?.forEach((projet) => {
        if (projet.geometrie) {
            const projetPolygone: Feature = {
                type: 'Feature', geometry: JSON.parse(projet.geometrie as string),
                properties: {
                    [ProjetProperties.ID]: projet.id,
                    [ProjetProperties.STATUT]: projet.statut
                }
            };
            projetCollection.features.push(projetPolygone);
        };
    });

    return projetCollection;
}

export function generatePointsAuditFeatures(pointsAudit: Partial<PointAuditDto[]>): FeatureCollection {
    const pointsAuditCollection: FeatureCollection = { features: [], type: 'FeatureCollection' };
    pointsAudit.forEach((point) => {
        if (point?.geometrie) {
            const pointAudit: Feature = {
                type: 'Feature',
                geometry: typeof (point?.geometrie) === 'string' ? JSON.parse(point?.geometrie) : point!.geometrie,
                properties: {
                    [PointAuditProperties.ID]: point.id,
                    [PointAuditProperties.STATUT]: point.statut,
                    [PointAuditProperties.STATUT_GLOBAL]: point.statutGlobal,
                    [PointAuditProperties.ANOMALIES_AUDIT]: point?.anomaliesAudit?.map(anomalie => anomalie.statut) ?? []
                },
            };
            pointsAuditCollection.features.push(pointAudit);
        }
    });

    return pointsAuditCollection;
}

export function generatePointsInspectionFeatures(pointsInspection: PointInspectionDto[]): FeatureCollection {
    const pointsInspectionCollection: FeatureCollection = { features: [], type: 'FeatureCollection' };

    pointsInspection.forEach((point) => {
        if (point?.geometrie) {
            const pointInspection: Feature = {
                type: 'Feature',
                geometry: typeof (point?.geometrie) === 'string' ? JSON.parse(point?.geometrie) : point?.geometrie,
                properties: {
                    [PointInspectionProperties.ID]: point.id,
                    [PointInspectionProperties.STATUT]: point.statut,
                    [PointInspectionProperties.PROPRIETAIRE]: point.poteau?.proprietaire,
                    [PointInspectionProperties.CODE_A_BARRES]: point.poteau?.codeABarres,
                    [PointInspectionProperties.LCLCL_POTEAU]: point.poteau?.lclclPoteau
                },
            };
            pointsInspectionCollection.features.push(pointInspection);
        }
    });

    return pointsInspectionCollection;
}

export function generateAnomaliesFeatures(anomalies: AnomalieBaseDto[]): FeatureCollection {
    const anomaliesCollection: FeatureCollection = { features: [], type: 'FeatureCollection' };
    anomalies?.forEach((anomalie) => {
        const anomalieFeature: Feature = {
            type: 'Feature',
            geometry: typeof anomalie.geometrie === 'string' ? JSON.parse(anomalie.geometrie as string) : anomalie.geometrie,
            properties: {
                [AnomalieProperties.ID]: anomalie.id,
                [AnomalieProperties.ELEMENT]: anomalie.element
            }
        };
        anomaliesCollection.features.push(anomalieFeature);
    });

    return anomaliesCollection;
}
export function dateTimeChange(timeDate: string | undefined, format?: string): string {
    if (!timeDate || timeDate === 'undefined' || timeDate === 'Inconnu' || Number(timeDate) === 0 || isNaN(Number(timeDate))) {
        return '';
    } else {
        const datepipe: DatePipe = new DatePipe('en-US');
        const formattedDate: string | null = datepipe.transform(timeDate, format);
        if (formattedDate) {
            return formattedDate;
        } else {
            return 'Inconnu';
        };
    };
}

export function booleanToOuiNonNull(value?: boolean | null): string {
    if (value) {
        return 'Oui';
    } else if (value === undefined || value === null) {
        return '';
    } else {
        return 'Non';
    };
}

export function generatePhotosAnomalieAudit(anomaliesAudit: AnomalieAuditDto[]) {
    const convertedPhotos = anomaliesAudit.map(anomalieAudit => {
        return generatePhotosFromParameters(anomalieAudit.photos, {
            getParentId: () => `${anomalieAudit.pointAuditId}/anomalies/${anomalieAudit.id}`,
            getObjectType: () => ObjectType.POINTS_AUDIT,
            getPhotoId: photo => photo.id,
            alt: 'AnomaliesAudit'
        });
    });

    return flatMap(convertedPhotos, x => x);
}

export function generatePhotosFromParameters(photos: PhotoBackend[], params: {
    getParentId: (photo: PhotoBackend) => string,
    getObjectType: (photo: PhotoBackend) => string,
    getPhotoId: (photo: PhotoBackend) => string,
    alt: string
}
): Photo[] {
    const { getParentId, getObjectType, getPhotoId, alt } = params;
    const photoCollections = photos.map(aPhoto => {
        const basePath = `/${getObjectType(aPhoto)}/${getParentId(aPhoto)}/photos/${getPhotoId(aPhoto)}`;
        const photo: Photo = {
            id: aPhoto.id,
            previewImageSrc: basePath,
            thumbnailImageSrc: `${basePath}/thumbnails`,
            alt,
            title: `${aPhoto.nom} / ${aPhoto.nomOriginal}`,
            nomOriginal: aPhoto.nomOriginal,
            nom: aPhoto.nom,
        };
        return photo;
    });

    return photoCollections;
}

export function generatePhotos(photos: PhotoBackend[], parentId: string, type: ObjectType, alt?: string): Photo[] {
    return generatePhotosFromParameters(photos, {
        getParentId: () => parentId,
        getObjectType: () => type,
        getPhotoId: photo => photo.nom,
        alt
    });
}

export function dateParse(date: string): number {
    if (date?.includes('/')) {
        const newDate: string = date.split('/').reverse().join('-').concat(`T00:00:00.000-05:00`);
        const epochDate: number = Date.parse(newDate);
        return epochDate;
    } else if (date?.includes('-')) {
        const newDate: string = date.split('-').reverse().join('-').concat(`T00:00:00.000-05:00`);
        const epochDate: number = Date.parse(newDate);
        return epochDate;
    } else {
        return 0;
    };
}

export function generateUuid(): string {
    return uuidv4();
}

export function mergeDeep(original: any, dataToUpdate: any): any { // TODO: DELETE IF UNUSED
    return mergeWith(original, dataToUpdate, customizer);
}

function customizer(objValue: any, srcValue: any) {
    if (isEmpty(srcValue)) {
        return srcValue;
    } else {
        merge(objValue, srcValue);
    }
}

export function arrayBufferToBase64(buffer: ArrayBuffer) {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
}

export function saveFile(buffer: any, fileName: string, fileType: string, extension: string) {
    const data: Blob = new Blob([buffer], { type: fileType });
    saveAs(data, fileName + extension);
}

export function transformToFormData(data: any): FormData {
    const myFormData = new FormData();
    Object.keys(data).forEach((key: string) => {
        if (data[key] !== undefined) {
            myFormData.append(key, data[key]);
        }
    });

    return myFormData;
}

export function convertNumberToLatitude(degrees: string, direction: string) {
    let dd = Number(degrees.substring(0, 2)) + Number(degrees.substring(2)) / 60;
    if (direction === 'S') {
        dd = dd * -1;
    }
    return dd;
}

export function convertNumberToLongitude(degrees: string, direction: string) {
    let dd = Number(degrees.substring(0, 3)) + Number(degrees.substring(3)) / 60;
    if (direction === 'W') {
        dd = dd * -1;
    }
    return dd;
}

export function getAnomaliePriorite(anomalie: AnomalieBaseDto, anomaliesPilotage: AnomaliePilotageDto[]): string {
    const anomaliePilotage = anomaliesPilotage.find(ap => {
        if (ap.type) {
            return ap.element === anomalie.element &&
                ap.type === anomalie.type &&
                ap.cause === anomalie.cause;
        } else {
            return ap.element === anomalie.element &&
                ap.cause === anomalie.cause;
        }
    }

    ) as AnomaliePilotageDto;

    return getPrioriteMapping(anomaliePilotage.prioriteOFRP);
}

export function getPrioriteMapping(priorite: string): StatutPointInspection {
    switch (priorite) {
        case 'C':
            return StatutPointInspection.anomaliePrioriteC;
        case 'E':
            return StatutPointInspection.anomaliePrioriteE;
        case 'G':
            return StatutPointInspection.anomaliePrioriteG;
        case 'K':
            return StatutPointInspection.anomaliePrioriteK;
        case 'M':
            return StatutPointInspection.anomaliePrioriteM;
        case 'N':
            return StatutPointInspection.anomaliePrioriteN;
        default:
            return priorite as any;
    }
}

export function getStatutPointInspection(projet: ProjetCompletDto, index: number): StatutPointInspection {
    const currentAnomalies = projet.pointInspections[index].anomalies;
    const hasUrgence = currentAnomalies.find(anomalie => anomalie.urgence);

    let statutPriorite = min(currentAnomalies.map((anomalie: AnomalieBaseDto) =>
        StatutPointInspectionNumberValue[anomalie.priorite as keyof typeof StatutPointInspectionNumberValue]));
    if (!statutPriorite) {
        statutPriorite = StatutPointInspectionNumberValue.nonInspecte;
    }

    return hasUrgence ? StatutPointInspection.urgence : StatutPointInspectionNumberValue[statutPriorite] as StatutPointInspection;
}

export function valueToSearchInSubObject<T>(selectedValues: (keyof T)[]): { [key: string]: string[]; } {
    const valuesWithDot = selectedValues.filter(value => value.toString().includes('.'));

    return valuesWithDot.reduce((acc, value: any) => {
        const [key, subKey] = value.toString().split('.');
        if (!acc[key]) {
            acc[key] = [subKey];
        } else if (!acc[key].includes(subKey)) {
            acc[key].push(subKey);
        }
        return acc;
    }, {} as { [key: string]: string[] });
}

export function pickDataAndCreateTypeFromArray<T>(object: T[], arrayValue: string[]): Partial<Pick<T, keyof T>>[] {
    // On récupère toutes les "keys" qui compose l'interface
    type TKeys = keyof T;
    // Crée un "type" typescript union ( | ) a partir d'un array de string
    type UnionFromKeys<P extends TKeys[]> = Record<P[number], string>;
    // On récupère les colonnes sélectionnées et on s'assure qu'elle font partie des "keys" de l'interface
    const selectedValues: TKeys[] = arrayValue.map(v => v as TKeys);
    // On crée un type qui correspond au type de l'objet qu'on veut exporter
    type PickType = Pick<T, keyof UnionFromKeys<typeof selectedValues>>;
    // On vérifie si les colonnes sélectionnées contiennent des sous-objets
    const objectToSearch = valueToSearchInSubObject<T>(selectedValues);

    return object.map((ob: any) => ({
        ...pick<PickType>(ob, selectedValues),
        // On récupère les valeurs des sous-objets
        ...Object.entries(objectToSearch).reduce((acc: { [name: string]: any }, [searchKey, searchValue]) => {
            if (ob.hasOwnProperty(searchKey) && Array.isArray(searchValue)) {
                searchValue.forEach(sValue => {
                    acc[`${searchKey}.${sValue}`] = ob[searchKey][sValue];
                });
            }
            return acc;
        }, {})
    }));
}
