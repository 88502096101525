import { createAction, props } from '@ngrx/store';
import { BoundingBoxInfo } from '../../../shared/models/bounding-box-info.model';
import {
    PointInspectionDto,
    ProjetCompletDto,
    PatchProjetInspectionDto,
    CreatedProjetDto,
    AnomalieBaseDto,
    PhotoEntity,
} from '../../../core/api/client/models';

export enum InspectionActionTypes {
    LOAD_PROJET_INSPECTION_LIST = '[Inspection] Load Projet Inspection List',
    LOAD_PROJET_INSPECTION_LIST_SUCCESS = '[Inspection] Load Projet Inspection List Success',
    LOAD_PROJET_INSPECTION_LIST_ERROR = '[Inspection] Load Projet Inspection List Error',
    START_CREATE_PROJET_INSPECTION = '[Inspection] Start Create Projet Inspection',
    START_CREATE_PROJET_INSPECTION_SUCCESS = '[Inspection] Start Create Projet Inspection Success',
    START_CREATE_PROJET_INSPECTION_ERROR = '[Inspection] Start Create Projet Inspection Error',
    CREATE_PROJET_INSPECTION_SUCCESS = '[Inspection] Create Projet Inspection Success',
    CREATE_PROJET_INSPECTION_ERROR = '[Inspection] Create Projet Inspection Error',
    ASSIGN_PROJET_INSPECTION = '[Inspection] Assign Projet Inspection',
    ASSIGN_PROJET_INSPECTION_SUCCESS = '[Inspection] Assign Projet Inspection Success',
    ASSIGN_PROJET_INSPECTION_ERROR = '[Inspection] Assign Projet Inspection Error',
    ACTIVATE_PROJET_INSPECTION = '[Inspection] Activate Projet Inspection',
    ACTIVATE_PROJET_INSPECTION_SUCCESS = '[Inspection] Activate Projet Inspection Success',
    ACTIVATE_PROJET_INSPECTION_ERROR = '[Inspection] Activate Projet Inspection Error',
    SET_CURRENT_ACTIVE_PROJET_INSPECTION = '[Inspection] Set Current Active Projet Inspection',
    SET_CURRENT_ACTIVE_PROJET_INSPECTION_BY_ID = '[Inspection] Set Current Active Projet Inspection By Id',
    SET_SELECTED_POINT_INSPECTION = '[Inspection] Set Selected Point Inspection',
    CANCEL_PROJET_INSPECTION = '[Inspection] Cancel Projet Inspection',
    CANCEL_PROJET_INSPECTION_SUCCESS = '[Inspection] Cancel Projet Inspection Success',
    CANCEL_PROJET_INSPECTION_ERROR = '[Inspection] Cancel Projet Inspection Error',
    REJECT_PROJET_INSPECTION = '[Inspection] Reject Projet Inspection',
    REJECT_PROJET_INSPECTION_SUCCESS = '[Inspection] Reject Projet Inspection Success',
    REJECT_PROJET_INSPECTION_ERROR = '[Inspection] Reject Projet Inspection Error',
    APPROVE_PROJET_INSPECTION = '[Inspection] Approve Projet Inspection',
    APPROVE_PROJET_INSPECTION_SUCCESS = '[Inspection] Approve Projet Inspection Success',
    APPROVE_PROJET_INSPECTION_ERROR = '[Inspection] Approve Projet Inspection Error',
    COMPLETE_PROJET_INSPECTION = '[Inspection] Complete Projet Inspection',
    COMPLETE_PROJET_INSPECTION_SUCCESS = '[Inspection] Complete Projet Inspection Success',
    COMPLETE_PROJET_INSPECTION_ERROR = '[Inspection] Complete Projet Inspection Error',
    CLEAR_PROJET_INSPECTION_DATA = '[Inspection] Clear Projet Inspection Data',
    UPDATE_POINT_INSPECTION = '[Inspection] Update Point Inspection',
    UPDATE_POINT_INSPECTION_SUCCESS = '[Inspection] Update Point Inspection Success',
    UPDATE_POINT_INSPECTION_ERROR = '[Inspection] Update Point Inspection Error',
    CREATE_ANOMALIE_INSPECTION = '[Inspection] Create Anomalie Inspection',
    CREATE_ANOMALIE_INSPECTION_SUCCESS = '[Inspection] Create Anomalie Inspection Success',
    CREATE_ANOMALIE_INSPECTION_ERROR = '[Inspection] Create Anomalie Inspection Error',
    UPDATE_ANOMALIE_INSPECTION = '[Inspection] Update Anomalie Inspection',
    UPDATE_ANOMALIE_INSPECTION_SUCCESS = '[Inspection] Update Anomalie Inspection Success',
    UPDATE_ANOMALIE_INSPECTION_ERROR = '[Inspection] Update Anomalie Inspection Error',
    DELETE_ANOMALIE_INSPECTION = '[Inspection] Delete Anomalie Inspection',
    DELETE_ANOMALIE_INSPECTION_SUCCESS = '[Inspection] Delete Anomalie Inspection Success',
    DELETE_ANOMALIE_INSPECTION_ERROR = '[Inspection] Delete Anomalie Inspection Error',
    UPDATE_PROJET_INSPECTION_TO_INDEXEDDB = '[Inspection] Update Projet Inspection To IndexedDb',
    LOAD_POINTS_INSPECTION_BBOX = '[Inspection] Get Points Inspection Bbox',
    REFRESH_POINTS_INSPECTION_BBOX = '[Inspection] Refresh Points Inspection Bbox',
    LOAD_POINTS_INSPECTION_BBOX_SUCCESS = '[Inspection] Get Points Inspection Bbox Success',
    LOAD_POINTS_INSPECTION_BBOX_ERROR = '[Inspection] Get Points Inspection Bbox Error',
    GET_POINTS_ACTIVE_PROJET_INSPECTION = '[Inspection] Get Points From Active Projet Inspection',
    GET_POINTS_ACTIVE_PROJET_INSPECTION_SUCCESS = '[Inspection] Get Points Active Projet Inspection Success',
    GET_POINTS_ACTIVE_PROJET_INSPECTION_ERROR = '[Inspection] Get Points Active Projet Inspection Error',
    GET_POINTS_BY_PROJET_INSPECTION_ID = '[Inspection] Get Points By Projet Inspection Id',
    GET_POINTS_BY_PROJET_INSPECTION_ID_SUCCESS = '[Inspection] Get Points By Projet Inspection Id Success',
    GET_POINTS_BY_PROJET_INSPECTION_ID_ERROR = '[Inspection] Get Points By Projet Inspection Id Error',
    GET_PROJET_INSPECTION_BY_ID = '[Inspection] Get Projet Inspection By Id',
    GET_PROJET_INSPECTION_BY_ID_SUCCESS = '[Inspection] Get Projet Inspection By Id Success',
    GET_PROJET_INSPECTION_BY_ID_ERROR = '[Inspection] Get Projet Inspection By Id Error',
    ADD_POINT_INSPECTION_PHOTOS = '[Inspection] Add Point Inspection Photos',
    ADD_POINT_INSPECTION_PHOTO = '[Inspection] Add Point Inspection Photo',
    ADD_POINT_INSPECTION_PHOTO_SUCCESS = '[Inspection] Add Point Inspection Photo Success',
    ADD_POINT_INSPECTION_PHOTO_ERROR = '[Inspection] Add Point Inspection Photo Error',
    ADD_ANOMALIE_INSPECTION_PHOTO = '[Inspection] Add Anomalie Inspection Photo',
    ADD_ANOMALIE_INSPECTION_PHOTO_SUCCESS = '[Inspection] Add Anomalie Inspection Photo Success',
    ADD_ANOMALIE_INSPECTION_PHOTO_ERROR = '[Inspection] Add Anomalie Inspection Photo Error',
    DELETE_POINT_INSPECTION_PHOTO = '[Inspection] Delete Point Inspection Photo',
    DELETE_POINT_INSPECTION_PHOTO_SUCCESS = '[Inspection] Delete Point Inspection Photo Success',
    DELETE_POINT_INSPECTION_PHOTO_ERROR = '[Inspection] Delete Point Inspection Photo Error',
    DELETE_ANOMALIE_INSPECTION_PHOTO = '[Inspection] Delete Anomalie Inspection Photo',
    DELETE_ANOMALIE_INSPECTION_PHOTO_SUCCESS = '[Inspection] Delete Anomalie Inspection Photo Success',
    DELETE_ANOMALIE_INSPECTION_PHOTO_ERROR = '[Inspection] Delete Anomalie Inspection Photo Error',
    CREATE_POINT_INSPECTION = `[Inspection] Create Point Inspection`,
    CREATE_POINT_INSPECTION_SUCCESS = `[Inspection] Create Point Inspection Success`,
    CREATE_POINT_INSPECTION_ERROR = `[Inspection] Create Point Inspection Error`,
    CLEAR_POINTS_INSPECTION = '[Inspection] Clear Points Inspection',
}

/*** LOAD PROJET INSPECTION LIST */
export const loadProjetInspectionList = createAction(InspectionActionTypes.LOAD_PROJET_INSPECTION_LIST);

export const loadProjetInspectionListSuccess = createAction(InspectionActionTypes.LOAD_PROJET_INSPECTION_LIST_SUCCESS, props<{ projetList: ProjetCompletDto[] }>());

export const loadProjetInspectionListError = createAction(InspectionActionTypes.LOAD_PROJET_INSPECTION_LIST_ERROR, props<{ error: any }>());

/*** CREATE PROJET INSPECTION ***/
export const startCreateProjetInspection = createAction(InspectionActionTypes.START_CREATE_PROJET_INSPECTION,
    props<{ projetId: string, projetInspection: ProjetCompletDto }>());

export const startCreateProjetInspectionSuccess = createAction(InspectionActionTypes.START_CREATE_PROJET_INSPECTION_SUCCESS,
    props<{ createdProjetInspection: CreatedProjetDto }>());

export const startCreateProjetInspectionError = createAction(InspectionActionTypes.START_CREATE_PROJET_INSPECTION_ERROR, props<{ error: any }>());

export const createProjetInspectionSuccess = createAction(InspectionActionTypes.CREATE_PROJET_INSPECTION_SUCCESS, props<{ createProjetInspection: ProjetCompletDto }>());

export const createProjetInspectionError = createAction(InspectionActionTypes.CREATE_PROJET_INSPECTION_ERROR, props<{ error: any }>());

/*** ASSIGN PROJET INSPECTION ***/
export const assignProjetInspection = createAction(InspectionActionTypes.ASSIGN_PROJET_INSPECTION, props<{ projetId: string, assignData: PatchProjetInspectionDto }>());

export const assignProjetInspectionSuccess = createAction(InspectionActionTypes.ASSIGN_PROJET_INSPECTION_SUCCESS, props<{ assignProjetInspection: ProjetCompletDto }>());

export const assignProjetInspectionError = createAction(InspectionActionTypes.ASSIGN_PROJET_INSPECTION_ERROR, props<{ error: any }>());

/*** ACTIVATE PROJET INSPECTION ***/
export const activateProjetInspection = createAction(InspectionActionTypes.ACTIVATE_PROJET_INSPECTION,
    props<{ projetInspectionId: string, activateData: PatchProjetInspectionDto }>());

export const activateProjetInspectionSuccess = createAction(InspectionActionTypes.ACTIVATE_PROJET_INSPECTION_SUCCESS,
    props<{ activateProjetInspection: ProjetCompletDto }>());

export const activateProjetInspectionError = createAction(InspectionActionTypes.ACTIVATE_PROJET_INSPECTION_ERROR, props<{ error: any }>());

export const setCurrentActiveProjetInspection = createAction(InspectionActionTypes.SET_CURRENT_ACTIVE_PROJET_INSPECTION, props<{ projetInspection: ProjetCompletDto }>());

export const setCurrentActiveProjetInspectionById = createAction(InspectionActionTypes.SET_CURRENT_ACTIVE_PROJET_INSPECTION_BY_ID,
    props<{ projetInspectionId: string }>());

export const setSelectedPointInspection = createAction(InspectionActionTypes.SET_SELECTED_POINT_INSPECTION, props<{ pointInspection: PointInspectionDto }>());

/*** REJECT PROJET INSPECTION ***/
export const rejectProjetInspection = createAction(InspectionActionTypes.REJECT_PROJET_INSPECTION, props<{ projetId: string, rejectData: PatchProjetInspectionDto }>());

export const rejectProjetInspectionSuccess = createAction(InspectionActionTypes.REJECT_PROJET_INSPECTION_SUCCESS, props<{ projetInspection: ProjetCompletDto }>());

export const rejectProjetInspectionError = createAction(InspectionActionTypes.REJECT_PROJET_INSPECTION_ERROR, props<{ error: any }>());

/*** COMPLETE PROJET INSPECTION ***/
export const completeProjetInspection = createAction(InspectionActionTypes.COMPLETE_PROJET_INSPECTION,
    props<{ projetId: string, completeData: PatchProjetInspectionDto }>());

export const completeProjetInspectionSuccess = createAction(InspectionActionTypes.COMPLETE_PROJET_INSPECTION_SUCCESS, props<{ projetInspection: ProjetCompletDto }>());

export const completeProjetInspectionError = createAction(InspectionActionTypes.COMPLETE_PROJET_INSPECTION_ERROR, props<{ error: any }>());

/*** APPROVE PROJET INSPECTION ***/
export const approveProjetInspection = createAction(InspectionActionTypes.APPROVE_PROJET_INSPECTION,
    props<{ projetId: string, approveData: PatchProjetInspectionDto }>());

export const approveProjetInspectionSuccess = createAction(InspectionActionTypes.APPROVE_PROJET_INSPECTION_SUCCESS, props<{ projetInspection: ProjetCompletDto }>());

export const approveProjetInspectionError = createAction(InspectionActionTypes.APPROVE_PROJET_INSPECTION_ERROR, props<{ error: any }>());

/*** CANCEL PROJET INSPECTION ***/
export const cancelProjetInspection = createAction(InspectionActionTypes.CANCEL_PROJET_INSPECTION, props<{ projetId: string, cancelData: PatchProjetInspectionDto }>());

export const cancelProjetInspectionSuccess = createAction(InspectionActionTypes.CANCEL_PROJET_INSPECTION_SUCCESS, props<{ cancelProjetInspection: ProjetCompletDto }>());

export const cancelProjetInspectionError = createAction(InspectionActionTypes.CANCEL_PROJET_INSPECTION_ERROR, props<{ error: any }>());

/*** CLEAR PROJET INSPECTION DATA ***/
export const clearProjetInspectionData = createAction(InspectionActionTypes.CLEAR_PROJET_INSPECTION_DATA);

/*** UPDATE POINT INSPECTION ***/
export const updatePointInspection = createAction(InspectionActionTypes.UPDATE_POINT_INSPECTION, props<{ pointInspection: PointInspectionDto, poteauAction?: string }>());

export const updatePointInspectionSuccess = createAction(InspectionActionTypes.UPDATE_POINT_INSPECTION_SUCCESS, props<{ pointInspection: PointInspectionDto }>());

export const updatePointInspectionError = createAction(InspectionActionTypes.UPDATE_POINT_INSPECTION_ERROR, props<{ error: any }>());

/*** CREATE ANOMALIE INSPECTION ***/

export const createAnomalieInspection = createAction(InspectionActionTypes.CREATE_ANOMALIE_INSPECTION,
    props<{ pointInspection: PointInspectionDto, anomalie: AnomalieBaseDto }>());

export const createAnomalieInspectionSuccess =
    createAction(InspectionActionTypes.CREATE_ANOMALIE_INSPECTION_SUCCESS, props<{ anomalie: AnomalieBaseDto, success: boolean }>());

export const createAnomalieInspectionError = createAction(InspectionActionTypes.CREATE_ANOMALIE_INSPECTION_ERROR, props<{ error: any }>());

/*** UPDATE ANOMALIE INSPECTION ***/
export const updateAnomalieInspection = createAction(InspectionActionTypes.UPDATE_ANOMALIE_INSPECTION,
    props<{ pointInspection: PointInspectionDto, anomalie: AnomalieBaseDto }>());

export const updateAnomalieInspectionSuccess =
    createAction(InspectionActionTypes.UPDATE_ANOMALIE_INSPECTION_SUCCESS, props<{ anomalie: AnomalieBaseDto, success: boolean }>());

export const updateAnomalieInspectionError = createAction(InspectionActionTypes.UPDATE_ANOMALIE_INSPECTION_ERROR, props<{ error: any }>());

/*** DELETE ANOMALIE INSPECTION ***/
export const deleteAnomalieInspection = createAction(InspectionActionTypes.DELETE_ANOMALIE_INSPECTION,
    props<{ pointInspection?: PointInspectionDto, anomalieId: string }>());

export const deleteAnomalieInspectionSuccess = createAction(InspectionActionTypes.DELETE_ANOMALIE_INSPECTION_SUCCESS, props<{ anomalieId: string, success: boolean }>());

export const deleteAnomalieInspectionError = createAction(InspectionActionTypes.DELETE_ANOMALIE_INSPECTION_ERROR, props<{ error: any }>());

/*** UPDATE PROJET INSPECTION TO INDEXEDDB ***/
export const updateProjetInspectionToIndexedDb = createAction(InspectionActionTypes.UPDATE_PROJET_INSPECTION_TO_INDEXEDDB, props<{ projetId: string }>());

/*** ADD POINT INSPECTION PHOTO ***/
export const addPointInspectionPhoto = createAction(InspectionActionTypes.ADD_POINT_INSPECTION_PHOTO,
    props<{ pointInspection: PointInspectionDto, photo: PhotoEntity }>());

export const addPointInspectionPhotoSuccess = createAction(InspectionActionTypes.ADD_POINT_INSPECTION_PHOTO_SUCCESS, props<{ pointInspection: PointInspectionDto }>());

export const addPointInspectionPhotoError = createAction(InspectionActionTypes.ADD_POINT_INSPECTION_PHOTO_ERROR, props<{ error: any }>());

/*** ADD ANOMALIE INSPECTION PHOTO ***/
export const addAnomalieInspectionPhoto = createAction(InspectionActionTypes.ADD_ANOMALIE_INSPECTION_PHOTO,
    props<{ pointInspection: PointInspectionDto, anomalieId: string, photo: PhotoEntity }>());

export const addAnomalieInspectionPhotoSuccess = createAction(InspectionActionTypes.ADD_ANOMALIE_INSPECTION_PHOTO_SUCCESS,
    props<{ pointInspectionId: string, anomalieId: string, photo: PhotoEntity }>());

export const addAnomalieInspectionPhotoError = createAction(InspectionActionTypes.ADD_ANOMALIE_INSPECTION_PHOTO_ERROR, props<{ error: any }>());

/*** DELETE POINT INSPECTION PHOTO ***/
export const deletePointInspectionPhoto = createAction(InspectionActionTypes.DELETE_POINT_INSPECTION_PHOTO,
    props<{ pointInspection: PointInspectionDto, photo: PhotoEntity }>());

export const deletePointInspectionPhotoSuccess = createAction(InspectionActionTypes.DELETE_POINT_INSPECTION_PHOTO_SUCCESS,
    props<{ pointInspection: PointInspectionDto }>());

export const deletePointInspectionPhotoError = createAction(InspectionActionTypes.DELETE_POINT_INSPECTION_PHOTO_ERROR, props<{ error: any }>());

/*** DELETE ANOMALIE INSPECTION PHOTO ***/
export const deleteAnomalieInspectionPhoto = createAction(InspectionActionTypes.DELETE_ANOMALIE_INSPECTION_PHOTO,
    props<{ pointInspection: PointInspectionDto, anomalieId: string, photo: PhotoEntity }>());

export const deleteAnomalieInspectionPhotoSuccess = createAction(InspectionActionTypes.DELETE_ANOMALIE_INSPECTION_PHOTO_SUCCESS);

export const deleteAnomalieInspectionPhotoError = createAction(InspectionActionTypes.DELETE_ANOMALIE_INSPECTION_PHOTO_ERROR, props<{ error: any }>());

/*** GET POINTS ACTIVE PROJET INSPECTION ***/
export const getPointsActiveProjetInspection = createAction(InspectionActionTypes.GET_POINTS_ACTIVE_PROJET_INSPECTION);

export const getPointsActiveProjetInspectionSuccess = createAction(InspectionActionTypes.GET_POINTS_ACTIVE_PROJET_INSPECTION_SUCCESS,
    props<{ pointsInspection: PointInspectionDto[] }>());

export const getPointsActiveProjetInspectionError = createAction(InspectionActionTypes.GET_POINTS_ACTIVE_PROJET_INSPECTION_ERROR, props<{ error: any }>());

/*** GET POINTS BY PROJET INSPECTION ID ***/
export const getPointsByProjetInspectionId = createAction(InspectionActionTypes.GET_POINTS_BY_PROJET_INSPECTION_ID, props<{ projetId: string }>());

export const getPointsByProjetInspectionIdSuccess = createAction(InspectionActionTypes.GET_POINTS_BY_PROJET_INSPECTION_ID_SUCCESS,
    props<{ pointsInspection: PointInspectionDto[] }>());

export const getPointsByProjetInspectionIdError = createAction(InspectionActionTypes.GET_POINTS_BY_PROJET_INSPECTION_ID_ERROR, props<{ error: any }>());

/*** GET PROJET INSPECTION BY ID ***/
export const getProjetInspectionById = createAction(InspectionActionTypes.GET_PROJET_INSPECTION_BY_ID, props<{ projetId: string }>());

export const getProjetInspectionByIdSuccess = createAction(InspectionActionTypes.GET_PROJET_INSPECTION_BY_ID_SUCCESS,
    props<{ projetInspection: ProjetCompletDto }>());

export const getProjetInspectionByIdError = createAction(InspectionActionTypes.GET_PROJET_INSPECTION_BY_ID_ERROR, props<{ error: any }>());

/*** LOAD POINTS INSPECTION BBOX ***/
export const loadPointsInspectionBbox = createAction(InspectionActionTypes.LOAD_POINTS_INSPECTION_BBOX, props<BoundingBoxInfo>());

export const refreshPointsInspectionBbox = createAction(InspectionActionTypes.REFRESH_POINTS_INSPECTION_BBOX);

export const loadPointsInspectionBboxSuccess = createAction(InspectionActionTypes.LOAD_POINTS_INSPECTION_BBOX_SUCCESS,
    props<{ bbox: BoundingBoxInfo, pointsInspection: PointInspectionDto[] }>());

export const loadPointsInspectionBboxError = createAction(InspectionActionTypes.LOAD_POINTS_INSPECTION_BBOX_ERROR, props<{ bbox: BoundingBoxInfo, error: any }>());

/*** CREATE POINT INSPECTION  ***/
export const createPointInspection = createAction(InspectionActionTypes.CREATE_POINT_INSPECTION, props<{ pointInspection: PointInspectionDto }>());

export const createPointInspectionSuccess = createAction(InspectionActionTypes.CREATE_POINT_INSPECTION_SUCCESS,
    props<{ pointInspection: PointInspectionDto }>());

export const createPointInspectionError = createAction(InspectionActionTypes.CREATE_POINT_INSPECTION_ERROR, props<{ error: any }>());

/*** CLEAR POINTS INSPECTION ***/
export const clearPointsInspection = createAction(InspectionActionTypes.CLEAR_POINTS_INSPECTION);

