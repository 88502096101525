import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-header-menu-item',
    templateUrl: './header-menu-item.component.html',
    styleUrls: ['./header-menu-item.component.scss'],
})
export class HeaderMenuItemComponent {
    @Input() routerLink?: string;
    @Input() icon: string;
    @Input() separator: boolean = false;
    @Input() menuWidth?: string;

    @Output() menuClosed = new EventEmitter();
    @Output() menuOpened = new EventEmitter();

    public openingMenu() {
        this.menuOpened.emit();
    }

    public closingMenu() {
        this.menuClosed.emit();
    }
}
