import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { State } from '../../../../state/app.state';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ProjetInspectionActions } from '../../../projets/models/projets-inspection-actions.enum';
import { PatchProjetInspectionDto, ProjetCompletDto } from '../../../../core/api/client/models';
import * as InspectionActions from '../../state/inspection.actions';

@Component({
    selector: 'app-complete-projet-dialog',
    templateUrl: './complete-projet-dialog.component.html',
    styleUrls: ['./complete-projet-dialog.component.scss']
})
export class CompleteProjetDialogComponent implements OnInit {
    public form: FormGroup;

    public get projet(): ProjetCompletDto {
        return this.config.data.projet;
    }

    public get action(): ProjetInspectionActions {
        return this.config.data.action;
    }

    constructor(
        private fb: FormBuilder,
        public ref: DynamicDialogRef,
        private config: DynamicDialogConfig,
        private messageService: MessageService,
        private store: Store<State>
    ) { }

    ngOnInit(): void {
        this.form = this.fb.group({
            justificatif: [null, Validators.required]
        });
    }

    public completeProjet() {
        this.form.markAllAsTouched();
        if (this.form.invalid) {
            return this.messageService.add(
                {
                    severity: 'error',
                    closable: true,
                    summary: 'Compléter un projet',
                    detail: `Vous devez entrer un justificatif`
                });
        } else {
            const data: PatchProjetInspectionDto = { action: this.action, remarque: this.form?.value?.justificatif };
            this.store.dispatch(InspectionActions.completeProjetInspection({ projetId: this.projet.id, completeData: data }));
            this.ref.close(true);
        }
    }
}
