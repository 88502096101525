import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NavigationListItem } from './models/navigation-list-item.model';

@Component({
    selector: 'app-header-navigation-list',
    templateUrl: './header-navigation-list.component.html',
    styleUrls: ['./header-navigation-list.component.scss'],
})
export class HeaderNavigationListComponent {
    @Input() items: NavigationListItem[] = [];
    @Output() selectedMenuItem = new EventEmitter<any>();
}
