<div class="inspection-list">
    <ng-container *ngIf="projetInspectionList">
        <app-projet-list-dialog *ngIf="!isUserMobile"></app-projet-list-dialog>
        <app-projet-list-mobile-dialog *ngIf="isUserMobile" [projetInspectionList]="projetInspectionList"
            [columns]="mobileColumns" [canInspecterProjet]="canInspecterProjet"
            [canInspecterProjetAQ]="canInspecterProjetAQ" [canCompleteProjet]="canCompleteProjet"
            [canCompleteProjetAQ]="canCompleteProjetAQ" [isOffline]="isAppOffline">
        </app-projet-list-mobile-dialog>
    </ng-container>
</div>

<p-confirmDialog #cdDownload header="Téléchargement projet" icon="pi pi-exclamation-triangle" appendTo="body"
    key='downloadProjetInspectionConfirm'>
    <ng-template pTemplate="footer">
        <button type="button" pButton icon="pi pi-times" class="p-button-text p-mr-4" label="Annuler"
            (click)="cdDownload.reject()"></button>
        <button type="button" pButton icon="pi pi-check" class="p-button-text p-ml-4" label="OK"
            (click)="cdDownload.accept()"></button>
    </ng-template>
</p-confirmDialog>

<p-confirmDialog #cdInvalidProjet header="Compléter un projet" key="validateInvalidProjetConfirm" appendTo="body">
    <ng-template pTemplate="footer">
        <button type="button" pButton class="p-button-text" label="Consulter le rapport de validation"
            (click)="cdInvalidProjet.reject()"></button>
        <button type="button" pButton class="p-button-text" label="Compléter le projet"
            (click)="cdInvalidProjet.accept()"></button>
    </ng-template>
</p-confirmDialog>
