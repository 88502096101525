<div *ngIf="projetAuditListLoading$ | async" class="audit__content__loading">
    <app-loading-spinner></app-loading-spinner>
</div>
<ng-container *ngIf="projetAuditList$ | async as projetAuditList">
    <div class="audit__content__list">
        <app-projet-audit-list-mobile-dialog [projetAuditList]="projetAuditList" [columns]="mobileColumns"
            [canCompleteProjetAudit]="canCompleteProjetAudit" [isOffline]="isAppOffline">
        </app-projet-audit-list-mobile-dialog>
    </div>
</ng-container>

<p-confirmDialog #cdDownload header="Téléchargement projet d'audit" icon="pi pi-exclamation-triangle"
    key='downloadProjetAuditConfirm'>
    <ng-template pTemplate="footer">
        <button type="button" pButton icon="pi pi-times" class="p-button-text p-mr-4" label="Annuler"
            (click)="cdDownload.reject()"></button>
        <button type="button" pButton icon="pi pi-check" class="p-button-text p-ml-4" label="OK"
            (click)="cdDownload.accept()"></button>
    </ng-template>
</p-confirmDialog>
