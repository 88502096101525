<div class="complete-projet-audit-dialog" appendTo="body">
    <div class="complete-projet-audit-dialog__body">
        <div class="complete-projet-audit-dialog__body__header">
            <i class="pi pi-exclamation-triangle p-confirm-dialog-icon"></i>
            <div>
                <div>
                    Voulez-vous compléter le projet d'audit?
                </div>
                <div *ngIf="pointsAuditLoading$ | async; else incomplete" class="complete-projet-audit-dialog__loading">
                    <app-loading-spinner [size]="40"></app-loading-spinner>
                </div>
                <ng-template #incomplete>
                    <div *ngIf="form">
                        <div>
                            <b>{{nbPointsAuditAAuditer}}</b> poteaux sur <b>{{nbPointsAuditTotal}}</b> n'ont pas été
                            audités.
                        </div>
                        <div *ngIf="nbNonConformites === 0; else afficherNonConformites">
                            Aucune non-conformité signalée.
                        </div>
                        <ng-template #afficherNonConformites>
                            <div>
                                <b>{{nbNonConformites}}</b> non-conformités signalées.
                            </div>
                        </ng-template>
                    </div>
                </ng-template>
            </div>
        </div>
        <form *ngIf="form" [formGroup]="form">
            <div>
                <app-form-field class="app-form-field app-form-field--column" [control]="form.controls.justificatif"
                    label="Justificatif :">
                    <input pInputText type="text" [required]="true" formControlName="justificatif">
                </app-form-field>
            </div>
        </form>
    </div>
    <div class="complete-projet-audit-dialog__footer">
        <button pButton pRipple label="Annuler" icon="pi pi-times" class="p-button-text projet-button"
            (click)="closeCompleteProjetAuditDialog()"></button>
        <button pButton pRipple label="Compléter" icon="pi pi-flag" class="p-button-text"
            (click)="completeProjetAudit()" [disabled]="form?.valid === false || form?.pristine === true"></button>
    </div>
</div>
