import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { catchError, concatMap, map, mergeMap, of, tap, withLatestFrom } from 'rxjs';
import { AnomaliePilotageDto } from '../../core/api/client/models';
import { Severite } from '../../enums/severite';
import { StoreName } from '../../features/offline/models/indexed-db-store-name.enum';
import { IndexedDbService } from '../../features/offline/services/indexed-db.service';
import * as OfflineActions from '../../features/offline/state/offline.actions';
import { SharedService } from '../../services/shared.service';
import * as SharedActions from './shared.actions';
import { selectIsAppOnline } from './shared.selectors';
import { State } from './shared.state';
import { UtilitaireService } from '../../core/api/client/services';
import { logoutUser } from '../../core/store/actions/user.actions';

@Injectable()
export class SharedEffects {
    selectEsriAccessToken$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(SharedActions.selectEsriAccessToken),
            withLatestFrom(this.store.select(selectIsAppOnline)),
            mergeMap(([_, isAppOnline]) => {
                if (!isAppOnline) {
                    return of(OfflineActions.selectEsriAccessToken());
                } else {
                    return this.sharedService.selectEsriAccessToken()
                        .pipe(
                            map(esriAccessToken => SharedActions.selectEsriAccessTokenSuccess({ esriAccessToken })),
                            catchError((error: any) => {
                                if (error.status !== 404) {
                                    this.messageService.add(
                                        {
                                            severity: Severite.erreur,
                                            summary: 'Erreur Token',
                                            detail: `Une erreur est survenue lors de la récupération du token Esri.`
                                        }
                                    );
                                }

                                return of(SharedActions.selectEsriAccessTokenFailure({ error }));
                            })
                        );
                }
            })
        );
    });

    startDataExtraction$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(SharedActions.startDataExtraction),
            mergeMap((action) => {
                return this.sharedService.startDataExtraction({ type: action.typeExtraction, format: action.format })
                    .pipe(
                        map(response => SharedActions.startDataExtractionSuccess({ id: response, format: action.format })),
                        catchError((error: any) => of(SharedActions.startDataExtractionFailure({ error })))
                    );
            })
        );
    });

    selectDataExtraction$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(SharedActions.selectDataExtraction),
            mergeMap((action) => {
                return this.sharedService.selectDataExtraction(action.key)
                    .pipe(
                        map(dataExtraction => SharedActions.getDataExtractionSuccess({ dataExtraction })),
                        catchError((error: any) => of(SharedActions.getDataExtractionFailure({ error })))
                    );
            })
        );
    });

    loadAnomaliesPilotage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(SharedActions.loadAnomaliesPilotage),
            withLatestFrom(this.store.select(selectIsAppOnline)),
            concatMap(([_, isAppOnline]) => {
                if (!isAppOnline) {
                    return this.dbService.getAll<AnomaliePilotageDto>(StoreName.ANOMALIE_PILOTAGE);
                } else {
                    return this.sharedService.selectAnomaliesPilotage({ pageSize: 5000 }).pipe(
                        tap(anomalies => this.dbService.bulkAdd(StoreName.ANOMALIE_PILOTAGE, anomalies.items).subscribe()),
                        map((anomalies) => anomalies.items)
                    );
                }
            }),
            map(anomaliesPilotage => SharedActions.loadAnomaliesPilotageSuccess({ anomaliesPilotage })),
            catchError((error: any) => {
                if (error.status !== 404) {
                    this.messageService.add(
                        {
                            severity: Severite.erreur,
                            summary: 'Erreur Anomalie Pilotage',
                            detail: `Une erreur est survenue lors de la récupération des anomalies pilotage.`
                        }
                    );
                }

                return of(SharedActions.loadAnomaliesPilotageFailure({ error }));
            })
        );
    });

    public fetchIdentiteUtilisateur$ = createEffect(() => this.actions$.pipe(
        ofType(SharedActions.selectIdentiteUtilisateur),
        mergeMap(() => navigator.onLine
            ? this.utilitaireService.getApiV1UtilitairesMe()
            : of(null)
        ),
        mergeMap(fetchedIdentiteUtilisateur => this.sharedService.fetchIdentiteUtilisateur(fetchedIdentiteUtilisateur).pipe(
            map((identiteUtilisateur) => SharedActions.getIdentiteUtilisateurSuccess({ identiteUtilisateur }))
        )),
        catchError((error: any) => {
            if (error.status !== 404) {
                this.messageService.add(
                    {
                        severity: Severite.erreur,
                        summary: `Erreur à l'identification`,
                        detail: `Une erreur est survenue lors de la récupération des informations de l'utilisateur.`
                    }
                );
            }

            return of(SharedActions.getIdentiteUtilisateurFailure({ error }));
        })
    ));

    public setUserActiveRole$ = createEffect(() => this.actions$.pipe(
        ofType(SharedActions.setUserActiveRole),
        mergeMap(action => this.sharedService.setActiveRole(action.role).pipe(
            map((isSuccess) => {
                if (isSuccess) {
                    return SharedActions.setUserActiveRoleSuccess({ activeRole: action.role });
                } else {
                    return SharedActions.setUserActiveRoleFailure({ error: 'Erreur lors de la sauvegarde du rôle actif' });
                }
            })
        ))
    ));

    public logoutIdentiteUtilisateur$ = createEffect(() => this.actions$.pipe(
        // TODO: refactor
        ofType(SharedActions.logoutIdentiteUtilisateur),
        mergeMap(async () => logoutUser())
    ));

    constructor(
        private actions$: Actions,
        private store: Store<State>,
        private sharedService: SharedService,
        private messageService: MessageService,
        private dbService: IndexedDbService,
        private utilitaireService: UtilitaireService,
    ) { }

}


