import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-main-table-mobile-dialog',
    templateUrl: './main-table-mobile-dialog.component.html',
    styleUrls: ['./main-table-mobile-dialog.component.scss']
})
export class MainTableMobileDialogComponent {
    @Input() public isLoading = false;
    @Input() public numberOfItems: number | null | undefined = null;
    @Input() public selectedProjet: any | null | undefined = null;

    @Input() public name: string | null | undefined;
    @Input() public statut: string | null | undefined;
    @Input() public assignDate: string | null | undefined = '';
    @Input() public propertyQuantityMessage = '';
    @Input() public quantity: number | null | undefined;

    @Output() public selectedProjetItem = new EventEmitter<any>();
}
