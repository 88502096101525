import { AnomalieEffects } from '../../../features/anomalie/state/anomalie/anomalie.effects';
import { AnomaliePilotageEffects } from './anomaliesPilotage.effects';
import { UserInformationEffects } from './userInformation.effects';
import { UserEffects } from './user.effects';
import { TaxonomieEffects } from './taxonomie.effects';

export const coreStoreEffects = [
    AnomalieEffects,
    AnomaliePilotageEffects,
    UserInformationEffects,
    UserEffects,
    TaxonomieEffects,
];
